import * as React from "react";
// import tvDesktopBanner from "/api/file/images/app/tvbanner.svg";
// import tvMobileBanner from "Assets/Images/tv.png";
import "./AvangAd.scss";
import { Link } from "react-router-dom";
export interface AvangAdProps {}

const AvangAd: React.SFC<AvangAdProps> = () => {
  return (
    <div id="avang-ad">
    <Link to="/live">
      <img className="desktop" src="/api/file/images/app/tvbanner.svg" alt="" />
      <img className="mobile" src="/api/file/images/app/appTvbanner.svg" alt=""></img>
    </Link>
    </div>
  );
};

export default AvangAd;

import React, { useEffect, useState, useMemo } from "react";
import Api from "Api";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import Loading from "Components/Loading/Loading";
import { useParams, useHistory, Link } from "react-router-dom";
import "./Musics.scss";

function getName(show) {
  switch (show) {
    case "new":
      return "New Released";

    case "most":
      return "Featured Music";

    case "hot":
      return "Hot Musics";

    case "other":
      return "Avang Music";
    default:
      return "";
  }
}
export default function Musics() {
  const [musics, setmusics] = useState([]);
  const [loading, setloading] = useState(true);
  const [count, setCount] = useState(29);
  // const [allMusics, setAllMusics] = useState([]);
  const [top, setTops] = useState({ tops: [] });
  const [newMusic, setNewMusic] = useState({ neww: [] });
  const [top10, settop10] = useState([]);
  const [topMonth, setTopMonth] = useState([]);
  const [AllLoading, setAllLoading] = useState(false);
  const [type, setType] = useState("new");
  const [rightType, setRightType] = useState("10");
  const { show } = useParams();
  const history = useHistory();
  useEffect(() => {
    setType(show);
  }, [show]);
  useEffect(() => {
    new Api()
      .get("/api/musics/web/musics")
      .then((c) => c.json())
      .then((c) => {
        const w = c.tops.sort(() => 0.5 - Math.random()).slice(0, 4);
        setTops({ ...c, tops: w });
      });
  }, []);
  useMemo(() => {
    setCount(29);
  }, [type]);
  useEffect(() => {
    switch (type) {


      case "new":
        setloading(true);
        setAllLoading(true);

        new Api()
          .get("/api/Musics/newReleasedMusics?take=" + count)
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics(c);
            setloading(false);
            setAllLoading(false);
          });
        break;

      case "most":
        setloading(true);
        setAllLoading(true);

        new Api()
          .get("/api/Musics/mostplayed?take=" + count)
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics(c);
            setloading(false);
            setAllLoading(false);
          });
        break;

      case "hot":
        setloading(true);
        setAllLoading(true);

        new Api()
          .get("/api/Musics/hotMusics?take=" + count)
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics(c);
            setloading(false);
            setAllLoading(false);
          });
        break;

      case "other":
        setloading(true);
        setAllLoading(true);

        new Api()
          .get("/api/Musics/otherMusics?take=" + count)
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics(c);
            setloading(false);
            setAllLoading(false);
          });
        break;

      default:
        break;
    }
  }, [type]);
  useEffect(() => {
    switch (type) {
      case "new":
         if (count > 30 ) {
        new Api()
          .get(
            "/api/Musics/newReleasedMusics?take=" + 30 + "&skip=" + (count - 30)
          )
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics([...musics, ...c]);
          });
        }
        break;

        case "most":
          new Api()
            .get("/api/Musics/mostplayed?take=" + 30 + "&skip=" + (count - 30))
            .then((c) => c.json())
            .then((c) => {
              //setAllMusics(c);
              setmusics([...musics, ...c]);
            });
          break;  

      case "hot":
        new Api()
          .get("/api/Musics/hotMusics?take=" + 30 + "&skip=" + (count - 30))
          .then((c) => c.json())
          .then((c) => {
            // setAllMusics(c);
            setmusics([...musics, ...c]);
          });
        break;
        
      case "other":
        new Api()
          .get("/api/Musics/otherMusics?take=" + 30 + "&skip=" + (count - 30))
          .then((c) => c.json())
          .then((c) => {
            //setAllMusics(c);
            setmusics([...musics, ...c]);
          });
        break;

      default:
        break;
    }
  }, [count]);
  useEffect(() => {
    switch (rightType) {
      case "10":
        new Api()
          .get("/api/Musics/top10Musics")
          .then((c) => c.json())
          .then((c) => {
            settop10(c);
          });
        break;
      case "month":
        new Api()
          .get("/api/Musics/topMonthMusics")
          .then((c) => c.json())
          .then((c) => {
            setTopMonth(c);
          });
        break;

      default:
        break;
    }
  }, [rightType]);
  // useEffect(() => {
  //   window.onscroll = (e) => {
  //     let windowRelativeBottom = window.document.documentElement.getBoundingClientRect()
  //       .bottom;

  //     if (
  //       windowRelativeBottom <
  //       window.document.documentElement.clientHeight + 50
  //     ) {
  //       if (count < allMusics.length) {
  //         setCount((e) => e + 40);
  //       }
  //     }
  //   };
  // }, [allMusics.length]);
  // useEffect(() => {
  //   setmusics(allMusics.slice(0, count));
  // }, [count]);
  //console.log(musics);

  return (
    <div
      style={{ marginTop: 35, color: "white" }}
      className="container"
      id="music-page"
    >
      <div className="tops">
        {top.tops.map((c, index) => (
          <div key={index} className="top">
            <Link
              to={
                "/playMusic/" +
                c?.musicID +
                "/" +
                c.artists[0].replace(/ /g, "-") +
                "/" +
                c.title.replace(/ /g, "-")
              }
            >
              <img
                onError={(e) => {
                  e.target.src = `/api/file/images/placeholder.jpg`;
                }}
                src={`/api/file/images/music/topcover/${c.musicID}.jpg`}
                alt=""
              />{" "}
            </Link>
            <span
              className="overlody"
              onClick={() =>
                history.push(
                  "/playMusic/" +
                    c?.musicID +
                    "/" +
                    c.artists[0].replace(/ /g, "-") +
                    "/" +
                    c.title.replace(/ /g, "-")
                )
              }
            >
              <span className="name">
                {/* <Marquee>
              {(c.musicArtists ?? []).map( (c, index) => (
                      <>
                      <Link key={index} to={ "/artist/" + c.artistID +"/" + c?.artist.name.replace(  / /, "-" )  }>
                          {c?.artist.name}
                      </Link>
                      {index <= 0 && c.artist.length > 1 ? " - " + " " : <span></span> }
                      </>
                    ) )}
              </Marquee> */}
                {(c.musicArtists ?? []).map((c, index) => (
                  <>
                    <Link
                      key={index}
                      to={
                        "/artist/" +
                        c.artistID +
                        "/" +
                        c?.artist.name.replace(/ /, "-")
                      }
                    >
                      {c.artist.name}
                    </Link>
                    {index <= 0 && c.artist.name.length > 1 ? (
                      " - " + " "
                    ) : (
                      <span></span>
                    )}
                  </>
                ))}
              </span>
              <span className="title">
                <Link
                  to={
                    "/playMusic/" +
                    c?.musicID +
                    "/" +
                    c.artists[0].replace(/ /g, "-") +
                    "/" +
                    c.title.replace(/ /g, "-")
                  }
                >
                  {c?.title?.substring(
                    0,
                    c?.title?.length > 15 ? 14 : c.title.length
                  )}
                  {c?.title?.length > 14 ? "..." : ""}
                </Link>
              </span>
            </span>
          </div>
        ))}
      </div>
      <div className="music-page-row">
        <div className="musicpage-column-left">
          <div className="types">
            <span
              onClick={() => {
                setType("new");
              }}
              className={type == "new" && "active"}
            >
              {getName("new")}
            </span>
            <span
              onClick={() => {
                setType("most");
              }}
              className={type == "most" && "active"}
            >
              {getName("most")}
            </span>
            <span
              onClick={() => {
                setType("hot");
              }}
              className={type == "hot" && "active"}
            >
              {getName("hot")}
            </span>
            <span
              onClick={() => {
                setType("other");
              }}
              className={type == "other" && "active"}
            >
              {getName("other")}
            </span>
          </div>

          <div className="music-row">
            {loading ? (
              <div style={{ width: "100%", height: "20%", paddingTop: 100 }}>
                <Loading height={100} />
              </div>
            ) : (
              <div className="music-grid">
                <div className="tracks">
                  {musics.map((c, index) => (
                    <MusicGridItem
                      key={index}
                      width={31.5}
                      data={musics}
                      index={index}
                    />
                  ))}
                  <div
                    onClick={(e) => {
                      setCount((e) => e + 30);
                    }}
                    className="more-musics"
                  >
                    {AllLoading ? (
                      <span>loaidng...</span>
                    ) : (
                      <span>See More</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="musicpage-column-right">
          <div className="right-types">
            <span
              onClick={() => {
                setRightType("10");
              }}
              className={rightType == "10" && "active"}
            >
              Top 10
            </span>
            <span
              onClick={() => {
                setRightType("month");
              }}
              className={rightType == "month" && "active"}
            >
              Top Month
            </span>
          </div>

          <div className="music-row">
            {rightType == "10" && (
              <div className="music-grid">
                <div className="tracks">
                  {top10.map((c, index) => (
                    <MusicGridItem
                      key={index}
                      width={100}
                      data={top10}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            )}
            {rightType == "month" && (
              <div className="music-grid">
                <div className="tracks">
                  {topMonth.map((c, index) => (
                    <MusicGridItem
                      key={index}
                      width={100}
                      data={topMonth}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import { MusicGridItem } from "./MusicGrid";
import { useHistory } from "react-router-dom";

export interface HotMusicProps {
  data: [];
}

const HotMusic: React.SFC<HotMusicProps> = (props: HotMusicProps) => {
  const history = useHistory();

  return (
    <div className="hot-music">
      <div className="music-grid">

        <div className="rowsTitle">
          <p>New Releases</p>
          <span
            onClick={(e) => {
              window.scroll({ top: 0 });
              history.push("musics/hot");
            }}
          >
            See all
          </span>
        </div>
        <div className="tracks">
          {props.data.map((c, index) => (
            <MusicGridItem key={index} width={23.5} data={props.data} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HotMusic;

import * as React from "react";
import { Grid, Avatar } from "@material-ui/core";

export interface ImageUploadProps {
  onChange: any;
  className?: string;
  src?: string;
}

const ImageUpload: React.SFC<ImageUploadProps> = (props: ImageUploadProps) => {
  const avatar = React.useRef<any>(null);
  const [preview, setPreview] = React.useState(props.src);
  return (
    <div style={{position: "relative"}}>
      <i
        onClick={() => {
          avatar.current.click();
        }}
        className="fas fa-photo img-upload-icon"></i>

      <input
        onChange={(e) => {
          if (e.target.files != null) {
            props.onChange(e);
            setPreview(URL.createObjectURL(avatar.current.files[0]));
          }
        }}
        name="file"
        ref={avatar}
        type="file"
        hidden
      />
      <img
        onClick={() => {
          avatar.current.click();
        }}
        onError={(e: any) => {
          e.target.src = "/api/file/images/video.jpg";
        }}
        className={props.className}
        src={preview}
        alt=""
      />
    </div>
  );
};

export default ImageUpload;

/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import "./Podcasts.scss";
import Api from "Api";
import Loading from "Components/Loading/Loading";
import { HorizontalPodcastItem } from "Components/HorizontalPodcasts/HorizontalPodcasts";
import { HorizontalPodcastCollectionItem } from "Components/HorizontalPodcastCollection/HorizontalPodcastCollection";
import { Link } from "react-router-dom";
import { PlayerContainer } from "Context/Contexts";

export default function Podcasts() {
  const [podcasts, setpodcasts] = useState([]);
  const [loading, setloading] = useState(true);
  const [top, setTops] = useState({ tops: [] });
  const [data, setdata] = useState([]);
  const [podcastData, setPodcastdata] = useState([]);


  useEffect(() => {
    window.scroll({ top: 0 });

    new Api()
      .get("/api/podcasts/tops")
      .then((c) => c.json())
      .then((c) => {
        //const w = c.filter((a) => a.showOnTop == true);
        //setTops({ ...c, tops: w });
        const w = c.tops.sort(() => 0.5 - Math.random()).slice(0, 4);
        setTops({ ...c, tops: w });

        setloading(false);
      });
  }, []);
  useEffect(() => {
    new Api()
      .get("/api/podcasts")
      .then((c) => c.json())
      .then((c) => {
        setpodcasts(c);
        setPodcastdata(c);
        setloading(false);
      });
  }, []);
console.log('pdata',podcastData);

  useEffect(() => {
    new Api()
      .get(`/api/PodcastCollections`)
      .then((c) => c.json())
      .then((c) => {
        setloading(false);
        setdata(c);
      });
  }, []);
console.log(top.tops);
const player = PlayerContainer.useContainer();

  return loading ? (
    <Loading />
  ) : (
    <div id="podcasts" className="container">
      <div className="tops">
        {top.tops.map((c,index) => (
          <div key={index} className="top">
            <Link to={"/playpodcast/" + c?.podcastID}>
              <img
                onError={(e) => {
                  e.target.src = `/api/file/images/podcast.jpg`;
                }}
                src={`/api/file/images/podcast/${c.podcastID}.jpg`}
                alt=""
              />{" "}
            </Link>
            {
            //<div className="overloady">
           // <span className="name">
           //   {(c.podcastDjs ?? []).map((c) => (
           //     <Link to={"/djs/" + c.dj.djID}>
           //       {c.dj.name}
           //     </Link>
           //   ))}
           // </span>
           // <span className="title">
           //   <Link to={"/playpodcast/" + c?.podcastID}>
           //     {c?.title?.substring(
           //       0,
           //       c?.title?.length > 32 ? 31 : c.title.length
           //     )}
           //     {c?.title?.length > 31 ? "..." : ""}
           //   </Link>
           //// </span>
          //  </div>
        }
            </div>
        ))}
      </div>
      <div className="shuffle">
        <h1>Podcasts</h1>
        <button
                onClick={(e) => {
                  player.setPodcasts([ ...podcastData.sort(() => 0.5 - Math.random()),]);
                  player.setIndex(0);
                  player.setMode("podcast")
                }}
              >
                <i className="fas fa-random"></i> Shuffle
              </button>
        </div>

      <div className="podcasts">
        {podcasts.map((c, index) => (
          <HorizontalPodcastItem key={index} data={podcasts} index={index} />
        ))}
      </div>
      <div id="podcast-collections" className="container">
      <h1>Podcast Collection</h1>
      <div className="collections">
        {data.reverse().map((w) => (
          <HorizontalPodcastCollectionItem {...w} />
        ))}
      </div>
    </div>

    </div>
  );
}

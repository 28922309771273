/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Slider from "Components/Slider/Slider";
import { useHistory, useParams, Link } from "react-router-dom";
import HorizontalArtist from "Components/HorizontalArtists/HorizontalArtists";
import HorizontalHotVideo from "Components/HorizontalVideo/HorizontalHotVideo";
import AvangAd from "Components/AvangAd/AvangAd";
import MusicList from "Components/MusicList/MusicList";
import MusicGrid from "Components/MusicGrid/MusicGrid";
import HorizontalPlayList from "Components/HorizontalPlayList/HorizontalPlayList";
import CommingSoon from "Components/CommingSoon/CommingSoon";
import HotMusic from "Components/MusicGrid/HotMusic";
import HorizontalAlbum from "Components/HorizontalAlbums/HorizontalAlbum";
import Events from "Components/Events/Events";
import Api from "Api";
import Loading from "Components/Loading/Loading";
import HorizontalPodcast from "Components/HorizontalPodcasts/HorizontalPodcasts";
import "./Main.scss";
import Random from "Components/Random/Random";
import HorizontalPodcastCollection from "Components/HorizontalPodcastCollection/HorizontalPodcastCollection";
import AppDlPopup from "Views/AppDownloadPopUp/AppDlPopup";
import NewsRow from "Components/News/NewsRow";

export interface MainProps {}

const Main: React.FC<MainProps> = () => {
  const musicID = useParams();

  const [data, setData] = React.useState<any>({
    sliders: [],
    Popularartists: [],
    hotMusicVideos: [],
    MostPlayedMusics: [],
    AvangExclusive : [],
    NewReleasedMusics: [],
    NewCollections: [],
    newReleaseMusicVideos: [],
    HotMusics: [],
    CommingSoon: [],
    HotAlbums: [],
    PodcastCollections: [],
    Podcasts: [],
    Events: [],
  });
  const [loading, setLoading] = React.useState(true);
  let treeList = [];

  React.useEffect(() => {
    window.scroll({ top: 0 });

    new Api()
      .get("/api/Main/web/all")
      .then((c) => c.json())
      .then((c) => {
        setData(c);
        setLoading(false);
      });
  }, []);

  const [dataHit, setDatahit] = React.useState<any>({
    hits: [],
  });
  // useEffect(() => {
  //   new Api()
  //     .get("/api/musics/web/videos")
  //     .then((c) => c.json())
  //     .then((c) => {
  //       // const d =  c.hits.sort((a: any,b: any) => a.musicID > b.musicID,1).slice(0,3)
  //       setDatahit(c);
  //     });
  // }, [musicID]);
  console.log('dataHome',data);

  const history = useHistory();
  document.title = "Avang Music - None Stop play music platform";
  return loading ? (
    <Loading />
  ) : (
    <section>
      {/* <AppDlPopup /> */}

      <Slider />

      <div className="container">
        {/* <ReactPWAToHomeScreen /> */}

        <HorizontalArtist
          data={data.popularArtists.sort(() => 0.5 - Math.random()).slice(0, 7)}
        />
          <HorizontalHotVideo 
          title="Hit Videos "
          data={data.hotMusicVideos} />
        <AvangAd />
        {/* <div className={"list-grid"}>
          <div>
            <MusicGrid data={data.NewReleasedMusics} />
          </div>
          <div>
            <MusicList
              to="/musics/most"
              title="Featured Music"
              data={data.MostPlayedMusics}
            />
          </div>
        </div> */}

      <MusicGrid 
        title="Avang Exclusive "
        data={data.avangExclusive.slice(0,6)} />

        <HorizontalPlayList data={data.newCollections} />

        <MusicGrid 
        title="Trending Music "
        data={data.newReleasedMusics.slice(0,9)} 
        />
        {/* <MusicList
              to="/musics/most"
              title="New Songs"
              data={data.MostPlayedMusics.slice(0,4)}
            /> */}

        {/* <YourAd /> */}


        {/* <HorizontalVideo data={data.newReleasedMusicVideos} /> */}
        <div id="horizontal-video">
          <HorizontalHotVideo 
          title="New Music Videos"
          data={data.newReleasedMusicVideos.slice(0,3)} />
        </div>

        <Random />
        
        {data.commingSoon.length > 0 ? (
          <CommingSoon data={data.commingSoon} />
        ) : (
          ""
        )}
        <HotMusic data={data.hotMusics} />
        <HorizontalPodcast data={data.podcasts} />
        <HorizontalPodcastCollection data={data.podcastCollections} />
        <HorizontalAlbum data={data.hotAlbums} />
        {/* <div className="music-upgrade">
              <div>
                <MusicList
                  to="/musics/other"
                  title="Other Music "
                  data={data.otherMusics}
                />
              </div>
              <div>
                <Upgrade />
              </div>
            </div> */}
        {/* <HorizontalBigArtist /> */}
        {data.events.length > 0 ? <Events data={data.events} /> : ""}
        <NewsRow />
      </div>
    </section>
  );
};

export default Main;

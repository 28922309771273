/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useParam } from "react";
import Api from "Api";
import "./PodcastCollections.scss";
import Loading from "Components/Loading/Loading";
import { HorizontalPodcastCollectionItem } from "Components/HorizontalPodcastCollection/HorizontalPodcastCollection";
export default function PodcastCollections() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    new Api()
      .get(`/api/PodcastCollections`)
      .then((c) => c.json())
      .then((c) => {
        setloading(false);
        setdata(c);
      });
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div id="podcast-collections" className="container">
      <h1>Podcast Collection</h1>
      <div className="collections">
        {data.reverse().map((w) => (
          <HorizontalPodcastCollectionItem {...w} />
        ))}
      </div>
    </div>
  );
}

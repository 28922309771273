import React, { useState, useEffect } from "react";
import "./PodcastCollection.scss";
import Api from "Api";
import { useParams, Link } from "react-router-dom";
import { PodcastGridItem } from "Components/PodcastGrid/PodcastGrid";
import PodcastRowItem from "Components/PodcastRow/PodcastRowItem";
import Loading from "Components/Loading/Loading";
import { PlayerContainer } from "Context/Contexts";
export default function PodcastCollection() {
  const { podcastCollectionID } = useParams();
  const [data, setData] = useState({ podcasts: [] });
  const player = PlayerContainer.useContainer();
  const [loading, setloading] = useState(true);
  useEffect(() => {
    new Api()
      .get(`/api/PodcastCollections/${podcastCollectionID}`)
      .then((c) => c.json())
      .then((c) => {
        setloading(false);
        setData(c);
        window.scroll({ top: 0 });
      });
  }, []);
  console.log('pdata',data);
  return loading ? (
    <Loading />
  ) : (
    <div className="container">
      <div id="podcast-collection">
        <div className="header">
          <div className="right">
            <div className="name">
              <h1>{data.name}</h1>
            </div>
            <div className="info">
              <span className="artists">
                <i className="fas fa-microphone"></i>{" "}
                {data.podcastCollectionDjs?.map((c) => (
                  <Link to={"/dj/" + c.dj.djID}>{c.dj.name}</Link>
                ))}
              </span>

              <span className="count">
                <i className="fas fa-music"></i>
                {data.podcasts?.length ?? 0} Podcasts
              </span>
            </div>
          </div>
          <div className="left">
            <img
              onError={(e) => {
                e.target.src = "/api/file/images/collection.jpg";
              }}
              src={
                "/api/file/images/podcastCollection/" +
                podcastCollectionID +
                ".jpg"
              }
              alt=""
            />
          </div>
        </div>
        <hr />
        <div className="title-row">
        <h3>{data.name} Tracks</h3>
        <button
                onClick={(e) => {
                  player.setPodcasts([
                    ...data.podcasts.sort(() => 0.5 - Math.random()),
                  ]);
                  player.setIndex(0);
                }}
              >
                <i className="fas fa-random"></i> Shuffle
              </button>
        </div>
        <div className="podcast-grid">
          <div className="tracks">
            {data.podcasts.map((c, index) => (
              <PodcastRowItem width={25} data={data.podcasts} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from "@material-ui/core";
import {
  Videocam,
  MusicNote,
  CloudUpload,
  Delete,
  Edit,
  Add,
} from "@material-ui/icons";
import Api from "Api";
import { NavLink, useHistory } from "react-router-dom";
import  Waiting from "Components/Loading/Loading";
export interface MusicListProps {}

const MusicList: React.SFC<MusicListProps> = () => {
  const [loading, setLoading] = React.useState(true);
  const [musics, setMusics] = React.useState<any>([]);
  const history = useHistory();
  const [] = React.useState<string>("");
  React.useEffect(() => {
    new Api()
      .get("/api/ArtistPanel/musics")
      .then((c) => c.json())
      .then((c) => {
        setMusics(c);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Waiting />
  ) : (
    <Grid container direction="column">
      <NavLink to="/artistPanel/createmusic">
        <Fab color="primary" aria-label="add">
          <Add />
        </Fab>
      </NavLink>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Upload</TableCell>
            <TableCell>Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {musics.map((c: any, index: number) => (
            <TableRow>
              <TableCell>{c.title}</TableCell>
              <TableCell>
                {c.hasVideo ? (
                  <div>
                    <Videocam />
                  </div>
                ) : (
                  <MusicNote />
                )}
              </TableCell>
              <TableCell>
                {!c.isConfirmed ? (
                  <NavLink
                    to={
                      "/artistPanel/uploadmusic/" + c.musicID + "/" + c.hasVideo
                    }
                  >
                    <CloudUpload />
                  </NavLink>
                ) : (
                  "Unchangable"
                )}
              </TableCell>
              <TableCell>
                {!c.isConfirmed ? (
                  <div>
                    <span
                      onClick={() => {
                        history.push("/artistPanel/editMusic/" + c.musicID);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Edit />
                    </span>
                    <span
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          new Api()
                            .delete("/api/musics/" + c.musicID)
                            .then((c) => c.status == 200)
                            .then((c) => {
                              if (c) {
                                let w = [...musics];
                                w.splice(index, 1);
                                setMusics([...w]);
                              }
                            });
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Delete />
                    </span>
                  </div>
                ) : (
                  "Unchangable"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default MusicList;

import * as React from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Chip,
  Avatar,
} from "@material-ui/core";
import Api from "Api";

export interface SelectArtistProps {
  value?: any;
  onChange: any;
}
let currentArtist:any;
const SelectArtist: React.SFC<SelectArtistProps> = React.memo(
  (props: SelectArtistProps) => {
    const [artists, setArtists] = React.useState<any>([]);
    const [myArtist, setMyArtist] = React.useState<any>([]);
    

    const [filter, setFilter] = React.useState(()=>{return true;});
    // const [fullArtist, setFullArtist] = React.useState<any>([]);
    React.useEffect(() => {
      new Api()
        .get("/api/artists/artistsForCreatMusic")
        .then((c) => c.json())
        .then((c: any) => {
          const w = c.map((z: any) => ({ name: z.name, artistID: z.artistID }));

          new Api()
          .get("/api/artistPanel/me")
          .then((c) => c.json())
          .then((c) => {
        currentArtist = c;
            localStorage.setItem(
              "a",
              JSON.stringify({ artistID: c.artistID, name: c.name })
            );

              setArtists(w.filter((e:any) => e.artistID != c.artistID));
            
          })

         
          // setArtists(w.slice(0, 10));
        });

       
    }, []);
  


    return (
      <Grid id="select-artist" container direction="column" wrap="nowrap">
        <TextField
          onChange={(e) => {
            if (e.target.value == "") {
              setArtists(artists.slice(0, 10));
            } else {
              let s = artists.filter((w: any) =>
                w.name.toLowerCase().includes(e.target.value.toLowerCase())
              );
              setArtists([...s]);
            }
          }}
          className="search"
          variant="outlined"
          placeholder="Type Artist Name"
        />
        {artists && 
        <Grid container direction="row" wrap="wrap" className="chip-container">
          {
            currentArtist &&
          <Chip
                className="chip"
                label={currentArtist.name}
                variant="outlined"
                color="secondary"
                // onDelete={() => {}}
                avatar={
                  <Avatar src={`/api/file/images/artist/${currentArtist.artistID}.jpg`} />
                }
              />
  }
          {artists
            .filter((c: any) => c.selected)
            .map((c: any) => (
              
              <Chip
                className="chip"
                label={c.name}
                variant="outlined"
                color="secondary"
                // onDelete={() => {}}
                avatar={
                  <Avatar src={`/api/file/images/artist/${c.artistID}.jpg`} />
                }
              />
            ))}
        </Grid>
        }
        {
          artists && 
        <Grid container id="list" direction="column" wrap="nowrap">
          {artists.map((c: any, index: number) => (
            <ArtistOption {...{ ...c, index }} onChange={props.onChange} />
          ))}
        </Grid>
        }
      </Grid>
    );

    function ArtistOption(props: any) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              value={props.artistID}
              checked={props.selected}
              onChange={(e) => {
                if (e.target.checked) {
                  artists[props.index].selected = true;
                  //console.log(props.index);
                  
                }
                // setSelected({
                //   ...selected,
                //   [e.target.value]: { selected: true, name: props.name }
                // });
                else {
                  artists[props.index].selected = false;
                }
                props.onChange(artists.filter((w: any) => w.selected));
              }}
              // name="isExlusive"
              //   onChange={handleChange}
              //   value={form.isExlusive}
            />
          }
          label={props.name}
        />
      );
    }
  }
);

export default SelectArtist;

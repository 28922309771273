import * as React from "react";
import { MusicUpload } from "./MusicUpload";
import { VideoUpload } from "./VideoUpload";
import ImageUpload from "./ImageUpload";
import { useParams, useHistory } from "react-router-dom";
import Api from "Api";
import Thief from "Components/Thief/Thief";
import { PlayerContainer } from "Context/Contexts";
import { ArrowBack } from "@material-ui/icons";
import MusicUrlUpload from "./MusicUrlUpload";
import VideoUrlUpload from "./VideoUrlUpload";
import PlayMusic from "Views/PlayMusic/PlayMusic";

export interface UploadProps {
  musicID: string;
  video?: boolean;
}

const Upload: React.FC<UploadProps> = () => {
  const player = PlayerContainer.useContainer();

  let { musicID, video } = useParams<any>();
  const history = useHistory();

  class AudioPlayer extends React.Component {
    render() {
      return (
        <div>
          <audio
            ref="audio_tag"
            src={"/music/music/" + 320 + "/" + musicID + ".mp3"}
            controls
          />
        </div>
      );
    }
  }
  class VideoPlayer extends React.Component {
    render() {
      return (
        <div>
          <video
            ref="video_tag"
            style={{ width: "340px" }}
            src={"/music/video/" + 720 + "/" + musicID + ".mp4"}
            controls
          />
        </div>
      );
    }
  }

  return (
    <div>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBack />
      </span>
      <div className="music-upload-div">
        <span>
          <h3>Tap the image to select the cover file</h3>
          <ImageUpload
            src={`/api/file/images/music/${musicID}.jpg`}
            className="s-image-upload"
            onChange={async (e: any) => {
              // console.log(e.target.files);
              const dt = { file: e.target.files[0] };
              let color = await Thief(e.target.files[0]);
              new Api()
                .postWithFile(
                  "/api/musics/setCover/" +
                    musicID +
                    "/" +
                    color.replace("#", "%23"),
                  dt
                )
                .then((c) => c.text())
                .then((c: any) => {
                  if (c == "true") {
                    alert("Cover Have Been Set");
                  } else {
                    alert("server error");
                  }
                });
            }}
          />
        </span>
        <span>
          <h3>Tap the image to select the top cover file</h3>
          <ImageUpload
            className="s-image-upload"
            src={`/api/file/images/music/topcover/${musicID}.jpg`}
            onChange={async (e: any) => {
              // console.log(e.target.files);
              const dt = { file: e.target.files[0] };
              let color = await Thief(e.target.files[0]);
              new Api()
                .postWithFile(
                  "/api/musics/setTopCover/" +
                    musicID +
                    "/" +
                    color.replace("#", "%23"),
                  dt
                )
                .then((c) => c.text())
                .then((c: any) => {
                  if (c == "true") {
                    alert("Top Cover Have Been Set");
                  } else {
                    alert("server error");
                  }
                });
            }}
          />
        </span>
      </div>

      <span className="music-video-play-upload-span">
        {video == "true" ? (
          <React.Fragment>
            <VideoUpload musicID={musicID} />
            {/* <VideoUrlUpload /> */}
            <VideoPlayer />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MusicUpload musicID={musicID} />
            {/* <MusicUrlUpload /> */}
            <AudioPlayer />
          </React.Fragment>
        )}
      </span>
    </div>
  );
};

export default Upload;

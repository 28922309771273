/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./Setting.scss";
import Api from "Api";
export default function Setting() {
  const [user, setuser] = useState({});
  useEffect(() => {
    new Api()
      .get("/api/users/" + new Api().getUserId())
      .then((c) => c.json())
      .then((c) => setuser(c));
  }, []);
  const ref = useRef();
  const [file, setfile] = useState();
  return (
    <div id="setting">
      <input
        ref={ref}
        onChange={(e) => setfile(e.target.files[0])}
        type="file"
        style={{ display: "none" }}
      />
      <div className="container">
        <div>
          <div className="left">
            <img
              onClick={(e) => ref.current.click()}
              src={`/api/file/images/user/${user.userID}.jpg`}
              alt=""
            />
          </div>
          <div className="right">
            <div className="personal">
              <input
                type="text"
                value={user.name}
                className="field"
                placeholder="Name"
              />
              {/* <input
                type="text"
                value={user.username}
                className="field"
                placeholder="User Name"
              /> */}
              <input
                type="text"
                value={user.email}
                className="field"
                placeholder="Email"
              />
            </div>
            <div className="password">
              <input
                type="text"
                className="field"
                placeholder="Current Password"
              />
              <input type="text" className="field" placeholder="New Password" />
              <input
                type="text"
                className="field"
                placeholder="Confirm New Password"
              />{" "}
              <div className="save">
                <button>Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

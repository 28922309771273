import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import "./RequestArtist.scss";
import { Button } from "@material-ui/core";

const RequestArtist = () => {
	const [user, setUser] = useState({
		name: "",
		email: "",
		artistname: "",
		phone: "",
		artistmessage: "",
	});
	const [apiResponse, setApiResponse] = useState({
		show: false,
		message: "",
	});
	const params = useParams();

	useEffect(() => {
		new Api()
			.get("/api/Users/" + params.user_id, {})
			.then((c) => c.json())
			.then((c) => {
				setUser((prev) => {
					return {
						...prev,
						name: c.name,
						email: c.email,
					};
				});
			});
	}, []);

	const handleChange = (e) => {
		let updatedUser = {
			...user,
			[e.target.name]: e.target.value,
		};

		setUser(updatedUser);

		//console.log(user)
	};

	const handleSubmitForm = () => {
		let formData = {
			id: "0",
			userId: params.user_id,
			artistId: "",
			created: new Date().toISOString(),
			updated: "",
			status: 0,
			name: user.name,
			artistName: user.artistname,
			email: user.email,
			phone: user.phone,
			artistMessage: user.artistmessage,
		};

		new Api()
			.post("/api/MakeArtist", {
				body: JSON.stringify({
					...formData,
				}),
			})
			.then((c) => c.json())
			.then((c) => {
				if (c.status === 1) {
					setApiResponse({
						show: true,
						message:
							"Your request has been received. A confirmation email will be sent to you shortly.",
					});
				} else {
					setApiResponse({
						show: true,
						message:
							"Oops, Your request has encountered an error. Please try again later.",
					});
				}
			});

		//console.log(formData)
	};

	return (
		<div className="container">
			<div className="request-artist-div">
				<h1>Request Artist!</h1>
				{!apiResponse.show ? (
					<div className="request-Artist-form">
						<div className="input">
							<label htmlFor="Name">Your Name:</label>
							<input
								type="text"
								placeholder="name"
								name="name"
								value={user.name}
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div className="input">
							<label htmlFor="Artistame">Your Artist Name:</label>
							<input
								type="text"
								placeholder="Artist Name"
								name="artistname"
								value={user.artistname}
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div className="input">
							<label htmlFor="Email">Your Email:</label>
							<input
								type="email"
								placeholder="email"
								name="email"
								value={user.email}
								disabled
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div className="input">
							<label htmlFor="Phone">Your Phone Number:</label>
							<input
								type="number"
								placeholder="phone"
								name="phone"
								defaultValue={user.phone}
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div className="input">
							<label htmlFor="artistmessage">A brief description of your artistic background :</label>
							<label htmlFor="artistmessage">Links of your social networks and your music or music videos :</label>
							<textarea
                className="request-artist-textarea"
								placeholder="Write your message ..."
								name="artistmessage"
								defaultValue={user.artistmessage}
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<Button
							className="send-request-btn"
							onClick={() => handleSubmitForm()}>
							Send Your Request
						</Button>
					</div>
				) : (
					<span>{apiResponse.message}</span>
				)}
			</div>
		</div>
	);
};

export default RequestArtist;

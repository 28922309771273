import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "Api";
import "./Artist.scss";
import "Assets/animate.css";
import { HorizontalArtistItem } from "Components/HorizontalArtists/HorizontalArtists";
import { TextField } from "@material-ui/core";
import Loading from "Components/Loading/Loading";
import HorizontalDjItem from "Components/HorizontalDjItem/HorizontalDjItem";

export default function AllDjs() {
  const [loading, setLoading] = useState(true);

  const [djs, setDjs] = useState([]);
  const [allDjs, setAllDjs] = useState([]);
  useEffect(() => {
    new Api()
      .get("/api/djs/")
      .then((c) => c.json())
      .then((c) => {
        setDjs(c);
        setAllDjs(c);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    window.onscroll = (e, arg) => {
      //console.log(e);
      //console.log(arg);
    };
  }, []);
  return (
    <div id="all-artists">
      {loading ? (
        <Loading />
      ) : (
        <div className="container">
          <br />
          <br />
          <br />
          <div className="search">
            <input
              onChange={search}
              type="text"
              placeholder="Search For Djs "
            />
          </div>
          <div className="artists">
            {djs.map((c,index) => (
              <div key={index} className="animate__animated animate__fadeInDown">
                <HorizontalDjItem {...c} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  function search(e) {
    setDjs([
      ...allDjs.filter((c) =>
        c.name.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    ]);
  }
}

import React from "react";
import ReactAnime from "react-animejs";
const { Anime, stagger } = ReactAnime;

const About = () => {
  return (
    <Anime
      initial={[
        {
          targets: "#Box",
          translateX: 50,
          easing: "linear",
        },
      ]}
    >
      <div
        id="Box"
        style={{ marginTop: 70, height: 50, width: 50, background: "#d3d" }}
      >
        Hi About Page
        </div>
    </Anime>
  );
};

export default About;

import * as React from "react";
import "./HorizontalVideo.scss";
import Marquee from "react-fast-marquee";

import video from "Assets/Images/video.png";
import { useHistory, Link } from "react-router-dom";
export interface HorizontalVideoProps {
  data: [];
}

const HorizontalVideo: React.FC<HorizontalVideoProps> = (
  props: HorizontalVideoProps
) => {
  return (
    <div id="horizontal-video">
      <div className="title">
        <h2>New Music Videos</h2>
        <Link to="/videos">See All</Link>
      </div>
      <div className="videos">
        {props.data.map((c,index) => (
          <HorizontalVideoItem key={index} {...c} />
        ))}
      </div>
    </div>
  );
};
export function HorizontalVideoItem(props: any) {
  const history = useHistory();

  //console.log('videoprops',props);
  return (
    <div key={props.musicID} className="horizontal-video-item">
      <div className="image">
        <img
          onError={(e: any) =>
            (e.target.src = `/api/file/images/video.jpg`)
          }
          src={"/api/file/images/music/" + props.musicID + ".jpg"}
          alt=""
        />
        <div
          onClick={() => history.push("/playvideo/" + props.musicID)}
          className="play"
        >
          <i className="fas fa-play"></i>
        </div>
      </div>
      <div className="titles">
        <i className="video-play-icon fas fa-play"></i>
        <div>
          <span className="name">
          <i className="fas fa-microphone"></i>
          <Marquee>
                { (props?.musicArtists ?? []).map(
                    (c: any, index: any) => (
                      <>
                      <Link key={index} to={ "/artist/" + c.artistID +"/" + c?.artist.name.replace(  / /, "-" )  }>
                          {c?.artist.name}
                      </Link>
                      {index <= 0 && c.artist.name.length > 1 ? "  " + " " : <span>  </span> }
                      </>
                    )
                  )}
              </Marquee>
            </span>

          <span className="video-title">
          <i className="video-title-icon fas fa-play"></i>
          <Marquee>
            <Link to={"/playVideo/" + props?.musicID}>
              {props.title}
            </Link>
          </Marquee>

            </span>

        </div>
      </div>
    </div>
  );
}
export default HorizontalVideo;

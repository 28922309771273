import * as React from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Api from "Api";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import Social from "Components/ComboBox/Social/Social";
import * as icons from "@material-ui/icons";
import AuthService from "AuthService";

export interface ArtistSocialProps {}

const ArtistSocial: React.SFC<ArtistSocialProps> = () => {
  const artistID = useParams<any>();
  const history = useHistory();

  const [userArtistID, setUserArtistID] = React.useState();
  const [data, setData] = React.useState<any>([]);

  const [form, setForm] = React.useState<any>({});

  const [content, setContent] = React.useState<any>(null);

  const auth = new AuthService();
  React.useEffect(() => {
    if (!auth.loggedIn()) {
      setContent("permition");
      setTimeout(() => {
        history.push("/");
      }, 3000);
    } else if (auth.loggedIn()) {
      new Api()
        .get("/api/users/" + new Api().getUserId())
        .then((c) => c.json())

        .then((c) => {
          new Api()
            .get("/api/socials/all/" + c.artists[0].artistID)
            .then((c) => c.json())
            .then((c) => {
              setData(c);
              //console.log("data C", c);
              setContent("artistDetail");
            });

          //setUserArtistID(c.artists[0].artistID);
          setForm({ ...form, artistID: c.artists[0].artistID });

          let user = c;
          if (user.artists.length <= 0) {
            setContent("permition");
            setTimeout(() => {
              history.push("/requestartist/" + user.userID);
            }, 3000);
          }
          if (user.artists?.artistID != artistID) {
            setContent("permition");
            setTimeout(() => {
              history.push("/requestartist/" + user.userID);
            }, 3000);
          } else {
            setContent("artistDetail");
          }
        });
    } else if (userArtistID != null) {
    }
  }, []);

  return (
    <div>
      {content === "artistDetail" && (
        <div>
          <div className="social-panel-detail">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBack />
            </span>
            <span className="social-adform-span">
              <Social
                value={form.type}
                onChange={(e) => {
                  setForm({ ...form, type: e.target.value });
                  console.log(form);
                }}
              />
              <br />
              <TextField
                onChange={(e: any) => {
                  setForm({ ...form, link: e.target.value });
                  console.log(form);
                }}
                value={form.link}
                label="Full link 'https://instagram.com/avangmusic'"
              />
            </span>

            <br />
            <br />
            <Button style={{margin : "0px 25px 30px"}} onClick={save} color="primary" variant="contained">
              Save
            </Button>
          </div>
          <div className="socials-list">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Social Type</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Operatin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((c: any, index: number) => (
                <TableRow>
                  <TableCell>{c.type}</TableCell>
                  <TableCell>{c.link}</TableCell>
                  <TableCell>
                    <span
                      onClick={() => {
                        if (window.confirm("Are you sure???")) {
                          deleteSocial(c.socailID, index);
                        }
                      }}
                      className="s-close-icon"
                    >
                      <icons.CloseOutlined />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </div>
          
        </div>
      )}
      {content === "permition" && (
        <div className="permition">
          You don't have permition to see this page.
        </div>
      )}
    </div>
  );
  function deleteSocial(socailID: number, index: number) {
    new Api()
      .delete("/api/socials/" + socailID)
      .then((c) => c.ok)
      .then((c) => {
        if (c) {
          data.splice(index, 1);
          setData([...data]);
        }
      });
  }
  function save() {
    new Api()
      .post("/api/socials", { body: JSON.stringify(form) })
      .then((c) => c.json())
      .then((c) => {
        setData([...data, c]);
      });
  }
};

export default ArtistSocial;

/* eslint-disable eqeqeq */
import * as React from "react";
import "./Register.scss";
import Api from "Api";
import AuthService from "AuthService";
import { useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { tr } from "date-fns/locale";
import UserLayout from "Components/Layout/UserLayout";
export interface RegisterProps {
  userLlogin:any
}
var t: NodeJS.Timeout;
const Register: React.SFC<RegisterProps> = ({userLlogin}) => {
  const [form, setForm] = React.useState<any>({});
  const [user, setUser] = React.useState<any>({});
  const [message, setMessage] = React.useState("");
  const [resend, setResend] = React.useState(false);
  const [forgetPassword,setForgetPassword]=React.useState(false)
  const [passwordLoader,setPassworLoader]=React.useState(false)

  const [mode, setMode] = React.useState<"username" | "password" | "register">(
    "username"
  );
  var [timer, setTimer] = React.useState(10);

  const history = useHistory();
  React.useEffect(() => {
    if (timer == 0) {
      clearInterval(t);
      setResend(true);
    }
  }, [timer]);
  return (
    <UserLayout>
    <div className="register">
      {user.userID && (
        <img src={"/api/file/images/user/" + user.userID + ".jpg"} alt="" />
      )}
      <h1>Hello {Object.keys(user).length > 0 ? user.name : "Firend"}</h1>
      <p>
        {
          !forgetPassword ? (
            <>
            Please enter your {Object.keys(user).length > 0 ? "password" : "email"}{" "}
            to continue for Avang
            </>
          ) : (
            <>
           Check your email and enter the password sent by AVANG
            </>
          )
        }
      </p>
      {mode == "username" && (
        <React.Fragment>
          <div className="field-input">
            <i className="fas fa-envelope"></i>
            <input
              value={form.email}
              onChange={(e) => {
                e.target.focus();
                setForm({ ...form, email: e.target.value });
              }}
              type="text"
              placeholder="Enter Your Email Address"
            />
          </div>
          {/* enum Status
                    {
                        New = 0,
                        Exists = 1,
                        Success = 2,
                        Ban = 3,
                        Fail = 4,
                        Created = 5
                    } */}
          <button
            onClick={() => {
              new Api()
                .post("/api/users/login", {
                  body: JSON.stringify({ email: form.email }),
                })
                .then((c) => c.json())
                .then((c) => {
                  if (c.status == 1) {
                    setMode("password");
                    setUser(c);
                  }
                  if (c.status == 0) {
                    setMode("register");
                    setMessage("We have sent a code to your mail address ");
                    t = setInterval(() => {
                      setTimer((e) => e - 1);
                    }, 1000);
                  }
                  if (c.status == 3) {
                    setMode("username");
                    setMessage("Please try a few minutes later !!");
                  }
                });
            }}
            className="register-button"
          >
            Next
          </button>
          <GoogleLogin
            clientId="246472075051-7ots91pjf7l86q2eqoi1nua8f3u3qe7o.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={(e: any) => {
              new Api()
                .get("/api/users/validateGoogle?id_token=" + e.tokenId)
                .then((c) => c.json())
                .then((c) => {
                  if (c.status == 2) {
                    new AuthService().setToken(c.data);
                    userLlogin();
                    history.replace("/");
                  }
                });
            }}
            onFailure={(e) => console.log(e)}
            cookiePolicy={"single_host_origin"}
          />
        </React.Fragment>
      )}
      {mode == "password" && (
        <React.Fragment>
          <div className="field-input">
            <i className="fas fa-key"></i>
            <input
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              type="password"
              placeholder="Enter You Password"
            />
          </div>
          <span onClick={()=>{
            setPassworLoader(true)
              new Api()
              .post(`/api/Users/forgetpassword/${form.email}`)
              .then((c) => c.ok)
              .then((c) => {
               setForgetPassword(true)
               setPassworLoader(false)
              });
          }}>
            {
              !passwordLoader ? (
                <p>Forget My Password</p>
              ) : (
                <p>
                  Please Waite!
                </p>
              )
            }
          </span>
          <button onClick={login} className="register-button">
            Signin
          </button>
        </React.Fragment>
      )}
      {mode == "register" && (
        <React.Fragment>
          <div className="field-input">
            <i className="fas fa-lock"></i>
            <input
              onChange={(e) => setForm({ ...form, code: e.target.value })}
              type="text"
              placeholder="Enter Your Code"
            />
          </div>
          <button
            onClick={() => {
              new Api()
                .post("/api/users/verifyCode", {
                  body: JSON.stringify({ email: form.email, code: form.code }),
                })
                .then((c) => c.json())
                .then((c) => {
                  if (c.status == 5) {
                    localStorage.setItem(
                      "temp",
                      JSON.stringify({
                        data: c.data,
                        userID: c.userID,
                        email: form.email,
                        code: form.code,
                      })
                    );
                    history.replace("/updateInfo");
                  } else {
                    setMessage("The Code Is InCurrect");
                    setMode("register");
                  }
                });
            }}
            className="register-button"
          >
            Signup
          </button>
          <p>{timer}</p>
          {resend && (
            <a
              style={{ color: "orange" }}
              href="#!"
              onClick={() => {
                setResend(false);
                new Api()
                  .post("/api/users/resend", {
                    body: JSON.stringify({ email: user.email }),
                  })
                  .then((c) => c.ok)
                  .then((c) => {
                    if (c) {
                      setTimer(180);
                      t = setInterval(() => {
                        setTimer((e) => e - 1);
                      }, 1000);
                    }
                  });
              }}
            >
              Request New Code
            </a>
          )}
        </React.Fragment>
      )}
      <p>{message}</p>
    </div>
    </UserLayout>
  );

  function login() {
    new AuthService().login(form).then((c: any) => {
      if (c.ok) {
        userLlogin()
        history.push("/");
        
      } else {
        if (c.status == 4) {
          setMessage("Username or password is wrong!!");
        }
        if (c.status == 3) {
          setMessage("Please try a few minutes later !!");
        }
      }
    });
  }
};

export default Register;
import * as React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
  TextField
} from "@material-ui/core";
import Api from "Api";

export interface SelectTypeProps {
  value?: [];
  onChange: any;
}

const SelectType: React.SFC<SelectTypeProps> =React.memo( (props: SelectTypeProps) => {
  const [types, setTypes] = React.useState<any>([]);
  const [fullType, setFullTypes] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    new Api()
      .get("/api/types")
      .then(c => c.json())
      .then((c: []) => {
        let w = c.map((x: any) => {
          if (
            props.value != undefined &&
            props.value.some((u: any) => u.typeID == x.typeID)
          ) {
            return { ...x, selected: true };
          } else {
            return { ...x, selected: false };
          }
        });

        setTypes(w);
        setFullTypes(w);
        setLoading(false);
      });
  }, [props.value]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Grid id="select-type" container direction="column">
      <Typography>Select Music Type</Typography>

      <TextField
        variant="outlined"
        onChange={e => {
          if (e.target.value == "") {
            setTypes(fullType);
          } else {
            let s = fullType.filter((w: any) =>
              w.typeName.toLowerCase().includes(e.target.value)
            );
            setTypes([...s]);
          }
        }}
        label="Search Music Type"
      />
      <Grid id="type-list" container wrap="nowrap" direction="column">
        {types.map((c: any, index: number) => (
          <GenreOption {...{ ...c, index }} onChange={props.onChange} />
        ))}
      </Grid>
    </Grid>
  );

  function GenreOption(props: any) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            value={props.typeID}
            checked={props.selected}
            onChange={e => {
              let s = [...types];
              if (e.target.checked) {
                s[props.index].selected = true;
                setTypes(s);
              } else {
                s[props.index].selected = false;
                setTypes(s);
              }
              props.onChange(s.filter(w => w.selected));
            }}
          />
        }
        label={props.typeName}
      />
    );
  }
});

export default SelectType;

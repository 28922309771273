import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import "./NotificationPopup.scss";
import { Button } from "@material-ui/core";
export interface NotificationPopupProps {
	data: any;
}

const NotificationPopup: React.SFC<NotificationPopupProps> = (
	props: NotificationPopupProps
) => {
	const history = useHistory();
	//console.log(props.data);

	return (
		<div id="noti-popup">
			<div className="noti-popup-content">
				<div className="noti-title">{props.data.title}</div>
				<div className="noti-body">{props.data.body}</div>

				<img className="noti-img" src={props.data.image} />
				<span className="noti-type-link-span">
					<p className="noti-type">{props.data.type}</p>
					<a className="noti-link" href={
						props.data.type == "music" ? window.location.protocol +
						"//" +
						window.location.hostname +
						"/playMusic/" +
						props.data.id :''
						}>Visit Now</a>
					<p className="noti-close"
						onClick={() => {
							// document.getElementById('popup').style.display = 'none';
							(document.getElementById("noti-popup") as HTMLFormElement).style.display = "none";
						}}
					>Close</p>

				</span>

			</div>

			<div className="noti-popup-closer"
				onClick={() => {
					// document.getElementById('popup').style.display = 'none';
					(document.getElementById("noti-popup") as HTMLFormElement).style.display = "none";
				}}
			>
				<i className="fa fa-close"></i>Close And See Web App
			</div>
		</div>
	);
};

export default NotificationPopup;


/* eslint-disable eqeqeq */
import * as React from "react";
import Axios from "axios";
import Dropzone from "Components/Dropzone/Dropzone";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { MusicNote, ChevronRight } from "@material-ui/icons";
export function MusicUpload(musicID : any) {
  const [upload, setUpload] = React.useState<any>({
    component: "p",
    variant: "determinate",
    text: "Select music to upload",
    value: 0,
  });
  function uploadToServer(e: React.ChangeEvent<HTMLInputElement>) {
    const fd = new FormData();
    fd.append("file", e.target.files![0]);
    Axios.post("http://5.75.204.32/music/upload/music/" + musicID, fd, {
      onUploadProgress: (e: ProgressEvent) =>  {
        const uploaded = Math.round((e.loaded / e.total) * 100);
        if (e.loaded == e.total)
          setUpload({
            ...upload,
            text: "working on uploaded Music",
            variant: "indeterminate",
          });
        else
          setUpload({
            value: uploaded,
            text: uploaded + "%",
            component: "h2",
            variant: "determinate",
          });
      },
    })
      .then((c) => {
        if (c.data) {
          setUpload({
            ...upload,
            text: "Music Uploaded!!",
            component: "p",
            variant: "determinate",
          });
        }
      })
      .catch((c) => {
        setUpload({ ...upload, text: "Error Occored", component: "h6" });
      });
  }
  return (
    <Dropzone onChange={uploadToServer}>
      <Grid container direction="row">
        <Grid
          className="animated heartBeat"
          container
          direction="row"
          onMouseOver={(e) => {
            e.currentTarget.classList.add("heartBeat", "infinite");
          }}
          onMouseOut={(e) => {
            e.currentTarget.classList.remove("heartBeat", "infinite");
          }}
          alignItems={"center"}
        >
          <MusicNote id="music-icon" style={{ fontSize: "5rem" }} />
          <Typography variant={upload.component}>{upload.text}</Typography>
          <ChevronRight style={{ fontSize: "5rem" }} />
        </Grid>
        <LinearProgress
          variant={upload.variant}
          value={upload.value}
          color="primary"
        />
      </Grid>
    </Dropzone>
  );
}

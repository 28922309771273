import * as React from "react";
import ReactWaves from "@dschoon/react-waves";
import Api from "Api";
import { useParams, useHistory, Link } from "react-router-dom";
import "./PlayPodcast.scss";
import "Components/MusicGrid/MusicGrid.scss";
import Comment from "Components/Comment/Comment";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { PlayerContainer } from "Context/Contexts";
import Wave from "Components/Wave/Wave";
import PodcastRowItem from "Components/PodcastRow/PodcastRowItem";
import { PodcastGridItem } from "Components/PodcastGrid/PodcastGrid";
import Loader from "react-loader-spinner";
import Loading from "Components/Loading/Loading";
import AuthService from "AuthService";
import { setQuarter } from "date-fns/esm";
import Marquee from "react-fast-marquee";


// import Wavesurfer from "react-wavesurfer";

const PlayPodcast = () => {
	const ref = React.useRef();
	const canvas = React.useRef();
	const [podcast, setPodcast] = React.useState({});
	const [related, setRelated] = React.useState([]);
	const { podcastID } = useParams();
	const history = useHistory();
	const [eq, setEq] = React.useState(false);
	const [liked, setLiked] = React.useState(false);
	const [likeLoading, setLikeLoading] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [loading, setloading] = React.useState(true);

	const player = PlayerContainer.useContainer();
	React.useEffect(() => {
		//console.log(player.play);
	}, [player.play]);
	React.useEffect(() => {
		player.setMusics([]);
		player.setPlay("play");
		window.scrollTo({ left: 0, top: 0 });
		player.setPodcasts([player]);
		new Api()
			.get("/api/podcasts/" + podcastID)
			.then((c) => c.json())
			.then((c) => setPodcast(c));
	}, [podcastID]);
	React.useEffect(() => {
		new Api()
			.get("/api/Podcasts/isLiked/" + podcastID)
			.then((c) => c.text())
			.then((c) => {
				setLiked(c == "true");
			})
			.catch((e) => console.log(e));
	}, [podcastID]);
	React.useEffect(() => {
		new Api()
			.get("/api/Podcasts/isSaved/" + podcastID)
			.then((c) => c.text())
			.then((c) => {
				setSaved(c == "true");
			})
			.catch((w) => console.log(w));
	}, [podcastID]);
	React.useEffect(() => {
		window.document.body.style.background =
			"linear-gradient(to bottom," +
			(podcast?.coverColor ?? "#000") +
			" 0%, #000 99vh) ";
		if (Object.keys(podcast).length > 0) {
		//	console.log("load realated");
			new Api()
				.get("/api/podcasts/related/" + podcastID)
				.then((c) => c.json())
				.then((c) => {
					//console.log("p", c);
					const p = c.filter((w) => w.podcastID != podcastID);
					player.setMode("podcast");
					setRelated(p);
					player.setPodcasts([podcast, ...p]);
					player.setIndex(0);
					setEq(true);
					setloading(false);
				});
		}
	}, [podcast]);
	/* React.useEffect(() => {
    if (player.podcasts.length > 0) {
      player.setIndex(0);
    }
  }, [player.podcasts]);*/
	React.useEffect(() => {
		//  player.audio.oncanplay = async () => {
		if (Object.keys(podcast).length > 0) {
			// player.setLoading([0, false]);
			new Promise((res, rej) => {
				res();
			})
				.then(() => {
					if (
						// new Api().getBroweserName().browserName != "Safari" ||
						window.unlock &&
						player.audioContext &&
						player.source
					) {
						var w = new Wave();
						w.fromElement(
							player.audio,
							canvas.current,
							{
								shine: true,
								colors: ["white"],
								stroke: 1,
							},
							player.audioContext,
							player.source
						);
					}
				})
				.catch((c) => {});
		}
	}, [eq]);
	//console.log(player);
	//document.title = "🎧" + player.podcasts[player.index]?.title + " - " + player.podcasts[player.index]?.djs?.join(" , ") + " - Avang Music";

	return loading ? (
		<Loading />
	) : (
		<div
			className="play-podcast-background-image"
			style={{
				background:
					"url(/api/file/images/podcast/" +
					player.podcasts[player.index]?.podcastID +
					".jpg) no-repeat center",
			}}>
			<div className="play-podcast-background">
				<div className="container" id="play-podcast">
					<div className="left">
						<div className="sticky-music-info">
							{/* <div className="info">
            <div className="image">
              <img src={"/api/file/images/music/" + musicID + ".jpg"} alt="" />
            </div>

          </div> */}
							<div className="wave">
								<img
									onError={(e) => {
										e.target.src = "/api/file/images/placeholder.jpg";
									}}
									src={
										"/api/file/images/podcast/" +
										player.podcasts[player.index]?.podcastID +
										".jpg"
									}
									alt=""
								/>
								<i
									onClick={() => {
										player.setPlay((c) => (c == "pause" ? "play" : "pause"));
									}}
									className={
										"big-play fas fa-" +
										player.play +
										" " +
										(player.play == "pause" ? " big-play-hover" : "")
									}></i>
								<i
									onClick={() => {
										if (player.play == "pause") {
											player.setPlay("play");
										}
										player.audio.load();
										if (player.index == 0) {
											player.setIndex(0);
										} else {
											player.setIndex(player.index - 1);
										}
										setTimeout(() => {
											player.setPlay("pause");
										}, 400);
									}}
									className="big-backward fas fa-step-backward"></i>

								<i
									onClick={() => {
										player.setPlay((c) => (c == "pause" ? "play" : "pause"));
									}}
									className={
										"big-play fas fa-" +
										player.play +
										" " +
										(player.play == "pause" ? " big-play-hover" : "")
									}></i>
								<i
									onClick={() => {
										if (player.play == "pause") {
											player.setPlay("play");
										}
										player.audio.load();
										if (player.mode == "podcast") {
											if (player.podcasts.length - 1 == player.index) {
												player.setIndex(0);
											} else {
												player.setIndex(player.index + 1);
											}
										} else {
											if (player.podcasts.length - 1 == player.index) {
												player.setIndex(0);
											} else {
												player.setIndex(player.index + 1);
											}
										}

										setTimeout(() => {
											player.setPlay("pause");
										}, 400);
									}}
									className="big-forward fas fa-step-forward"></i>

								<canvas height={640} width={640} ref={canvas}></canvas>
								<div className="like-share">
									<div>
										{likeLoading ? (
											<Loader
												type="TailSpin"
												color="white"
												height={14}
												width={14}
											/>
										) : (
											<i
												onClick={() => {
													if (!new AuthService().loggedIn()) {
														player.setLogin(true);
														return;
													}
													setLikeLoading(true);
													new Api()
														.post("/api/main/like", {
															body: JSON.stringify({
																podcastID: podcastID,
															}),
														})
														.then((c) => c.text())
														.then((c) => {
															setLikeLoading(false);
															c == "1" ? setLiked(true) : setLiked(false);
														});
												}}
												className={
													liked == true ? "fas fa-heart red " : "far fa-heart"
												}></i>
										)}
										{saveLoading ? (
											<Loader
												type="TailSpin"
												color="white"
												height={14}
												width={14}
											/>
										) : (
											<i
												onClick={() => {
													if (!new AuthService().loggedIn()) {
														player.setLogin(true);
														return;
													}
													setSaveLoading(true);
													new Api()
														.post("/api/savedMusics", {
															body: JSON.stringify({
																podcastID: podcastID,
															}),
														})
														.then((c) => c.text())
														.then((c) => {
															setSaveLoading(false);
															c == "true" ? setSaved(true) : setSaved(false);
														});
												}}
												className={
													(saved == true ? "fas orange " : "far") +
													" fa-bookmark"
												}></i>
										)}
										<i
											onClick={() =>
												player.setShare({
													url:
														window.location.protocol +
														"//" +
														window.location.hostname +
														"/playpodcast/" +
														player.podcasts[player.index]?.podcastID +
														"/",
													show: true,
												})
											}
											className="fas fa-share-alt "></i>
										<i className="fas fa-info"></i>
									</div>
									<div>
										<i
											onClick={(e) => {
												document.getElementById("related-grid").style.display =
													"unset";
												document.getElementById("comment-grid").style.display =
													"none";
												document.getElementById("comment-btn").style.display =
													"none";
												document.getElementById("djs-grid").style.display =
													"none";
												document.getElementById("djs-btn").style.display =
													"none";
												document.getElementById("related-btn").style.display =
													"unset";
											}}
											id="related-i"
											className="fas fa-related">
											Related
										</i>
										<i
											onClick={(e) => {
												document.getElementById("related-grid").style.display =
													"none";
												document.getElementById("comment-grid").style.display =
													"none";
												document.getElementById("comment-btn").style.display =
													"none";
												document.getElementById("djs-grid").style.display =
													"unset";
												document.getElementById("djs-btn").style.display =
													"unset";
												document.getElementById("related-btn").style.display =
													"none";
											}}
											id="djs-i"
											className="fas fa-related">
											DJs
										</i>
										<i
											onClick={(e) => {
												document.getElementById("related-grid").style.display =
													"none";
												document.getElementById("comment-grid").style.display =
													"unset";
												document.getElementById("comment-btn").style.display =
													"unset";
												document.getElementById("djs-grid").style.display =
													"none";
												document.getElementById("djs-btn").style.display =
													"none";
												document.getElementById("related-btn").style.display =
													"none";
											}}
											id="comment-i"
											className="fas fa-related">
											Comment
										</i>
									</div>
								</div>
							</div>
						</div>
						<div className="info">
							<span>
								<h3>
									<span className="music-name">
										<i className="fas fa-play"></i>{" "}
                    <Marquee>
										  {player.podcasts[player.index]?.title}
                    </Marquee>
									</span>
								</h3>
							</span>
							<hr style={{ width: "100%" }} />
							<div className="statistics">
								<i className="fas fa-play"></i>
								<span>{podcast.playCount ?? 0}</span>
								<i style={{ color: "red" }} className="fas fa-heart"></i>
								<span>{podcast?.likeCount ?? 0}</span>
								<span>Release Date</span>
								<span>
									{new Date(
										player.podcasts[player.index]?.releaseDate
									).toDateString()}
								</span>
							</div>
						</div>
					</div>

					<div className="right">
						<div className="music-grid">
							<div className="buttons">
								<span className="button" id="related-btn">
									Related
								</span>
								<span className="button" id="djs-btn">
									DJS
								</span>
								<span className="button" id="comment-btn">
									Comments
								</span>
							</div>
							<div
								className="tracks animate__animated animate__flipInY"
								id="related-grid">
								{(related ?? []).map((c, index) => (
									<PodcastGridItem
										key={"ma" + index}
										width={100}
										index={index}
										data={related}
										onPlay={() => {
											window.scroll({ top: 0 });
											history.push("/playpodcast/" + c.podcastID);
										}}
									/>
								))}
							</div>
							<div
								className="comments animate__animated animate__flipInY"
								id="djs-grid">
								<span className="artist-name">
									<i className="fas fa-user"></i>
										{podcast.podcastDjs?.map((c) => (
											<Link to={"/dj/" + c.dj.djID}>{podcast?.djs}</Link>
										))}
								</span>
							</div>
							<div
								className="comments animate__animated animate__flipInY"
								id="comment-grid">
								<Comment podcastID={podcastID} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	// function Wave({ url, ref }) {
	//   return (
	//     <div ref={ref}>
	//       {/* <Wavesurfer
	//         audioFile={"path/to/audio/file.mp3"}
	//         // pos={this.state.pos}
	//         // onPosChange={this.handlePosChange}
	//         playing={true}
	//       /> */}
	//     </div>
	//     // <ReactWaves
	//     //   audioFile={url}
	//     //   className={"react-waves"}
	//     //   options={{
	//     //     barWidth: 2,
	//     //     barHeight: 1,
	//     //     cursorWidth: 0,
	//     //     height: 60,
	//     //     hideScrollbar: true,
	//     //     progressColor: "orange",
	//     //     responsive: true,
	//     //     waveColor: "white",
	//     //     barRadius: 3,
	//     //     barGap: 3,
	//     //   }}
	//     //   volume={1}
	//     //   zoom={1}
	//     //   onLoading={(e) => {
	//     //     console.log(e);
	//     //   }}
	//     //   playing={state.playing}
	//     // />
	//   );
	// }
};

export default PlayPodcast;

import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAut2z0ckOCp5xaFKayHRt8jlqJ3L3hFBY",
  authDomain: "avang-music.firebaseapp.com",
  databaseURL: "https://avang-music.firebaseio.com",
  projectId: "avang-music",
  storageBucket: "avang-music.appspot.com",
  messagingSenderId: "246472075051",
  appId: "1:246472075051:web:6ce6ed36b8b44effaa476e",
  measurementId: "G-Q50S9VL0VT",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
import * as React from "react";
import "./MusicRowItem.scss";
import { PlayerContainer } from "Context/Contexts";
import { Link, useHistory } from "react-router-dom";
export interface MusicRowItemProps {
  data: any;
  index: number;
}

const MusicRowItem: React.SFC<MusicRowItemProps> = (
  props: MusicRowItemProps
) => {
  const player = PlayerContainer.useContainer();

  return (
    <div className="music-row-item"
    style={{
      backgroundColor:
        player.musics[player.index]?.musicID == props.data[props.index].musicID
          ? props.data[props.index].coverColor
          : "",
    }}

    >
      <img
      onError={(e:any)=>{e.target.src="/api/file/images/placeholder.jpg"}}
        src={"/api/file/images/music/" + props.data[props.index]?.musicID + ".jpg"}
        alt=""
      />
      <div>
      <span className="name">
        {(props.data[props.index]?.musicArtists ?? []).map((c: any,index:any) => (
          <Link key={index} to={"/artist/" + c.artistID}>{c.name}</Link>
        ))}
      </span>
      <span className="title">
        <Link to={"/playmusic/" + props.data[props.index]?.musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-')}>
          {props.data[props.index]?.title}
        </Link>
      </span>
      </div>
      <i
        onClick={() => {
          if (
            player.play == "pause" &&
            player.musics[player.index]?.musicID ==
              props.data[props.index].musicID
          ) {
            player.setPlay("play");
          } else {
            player.setMode('music')
            player.setMusics(props.data);
            player.setIndex(props.index);
            setTimeout(() => {
              player.setPlay("pause");
            }, 100);
          }
        }}
        className={
          "fas fa-" +
          (player.musics[player.index]?.musicID ==
          props.data[props.index]?.musicID
            ? player.play
            : "play")
        }
      ></i>
    </div>
  );
};

export default MusicRowItem;

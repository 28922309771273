import React, { useEffect, useState } from "react";
import Api from "Api";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import Loading from "Components/Loading/Loading";

export default function HotMusics() {
  const [musics, setmusics] = useState([]);
  const [loading, setloading] = useState(true);
  const [count, setCount] = useState(40);
  const [allMusics, setAllMusics] = useState([]);
  useEffect(() => {
    new Api()
      .get("/api/Musics/")
      .then((c) => c.json())
      .then((c) => {
        setAllMusics(c);
        setmusics(c.slice(0, count));
        setloading(false);
      });
  }, []);
  useEffect(() => {
    window.onscroll = (e) => {
      let windowRelativeBottom = window.document.documentElement.getBoundingClientRect()
        .bottom;

      if (
        windowRelativeBottom <
        window.document.documentElement.clientHeight + 50
      ) {
        if (count < allMusics.length) {
          setCount((e) => e + 40);
        }
      }
    };
  }, [allMusics.length]);
  useEffect(() => {
    setmusics(allMusics.slice(0, count));
  }, [count]);
  return (
    <div style={{ marginTop: 50, color: "white" }} className="container">
      <h2>Hot Musics</h2>
      {loading ? (
        <Loading />
      ) : (
        <div className="music-grid">
          <div className="tracks">
            {musics.map((c, index) => (
              <MusicGridItem
                width={23.5}
                data={musics}
                index={index}
                //   onPlay={() => {
                //     window.scroll({ top: 0 });
                //     history.push("/playmusic/" + c.musicID);
                //   }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

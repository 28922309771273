import * as React from "react";

export interface DropzoneProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Dropzone: React.SFC<DropzoneProps> = (props: any) => {
  const file = React.useRef<any>(null);
  return (
    <div> 
      <input
        onChange={props.onChange}
        style={{ display: "none" }}
        type="file"
        ref={file}
        id=""
      />
      <div
        className="s-dropzone"
        onClick={() => {
          file.current.click();
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Dropzone;

import React, { useState, useEffect } from "react";
import "./AddToPlayList.scss";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Fab,
  TextField,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { PlayerContainer } from "Context/Contexts";
import Api from "Api";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddToPlayList() {
  const player = PlayerContainer.useContainer();
  const [playlists, setPlayLists] = useState([]);
  const [name, setname] = useState("");
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    new Api()
      .post("/api/playlists/GetPlayList")
      .then((c) => c.json())
      .then((c) => setPlayLists(c));
  }, []);
  return (
    <div id="add-to-playlist">
      <div>
        <Dialog
          open={player.addToPlayList.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setMessage("");
            player.setAddToPlayList({ musicID: 0, show: false });
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Save Music To your PlayList
            <hr />
          </DialogTitle>
          <div style={{margin: "35px" , marginTop: "-20px" }}>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <h3>{message}</h3>

            <Grid container justify="space-between">
              <TextField
                value={name}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    if (name.length > 0)
                      new Api()
                        .post("/api/playLists", {
                          body: JSON.stringify({ name }),
                        })
                        .then((c) => c.json())
                        .then((c) => {
                          setname("");
                          setPlayLists([...playlists, c]);
                        });
                  }
                }}
                placeholder="playListName"
                onChange={(e) => setname(e.target.value)}
              />
              <Button
                variant="outlined"
                color="#000fff"
                onClick={(e) => {
                  if (name.length > 0)
                    new Api()
                      .post("/api/playLists", {
                        body: JSON.stringify({ name }),
                      })
                      .then((c) => c.json())
                      .then((c) => {
                        setname("");

                        setPlayLists([...playlists, c]);
                      });
                }}
                style={{ marginLeft: 10 }}
              >
                Save
              </Button>
            </Grid>
            <Grid container wrap direction="column" justify="flex-start">
              {playlists.map((c, index) => (
                <Button
                key={index}
                  onDoubleClick={(e) => {}}
                  onClick={(e) => {
                    new Api()
                      .post("/api/playlistmusics", {
                        body: JSON.stringify({
                          musicID: player.addToPlayList.musicID,
                          playListID: c.playListID,
                        }),
                      })
                      .then((c) => c.statusText == "Created")
                      .then((c) => {
                        if (c) {
                          //   setMessage("Music Added To PlayList");
                          player.setAddToPlayList({ musicID: 0, show: false });
                        } else {
                          setMessage("Music Already Is In Your PlayList");
                        }
                      })
                      .catch((c) =>
                        setMessage("Error While Adding To PlayList")
                      );
                  }}
                  style={{ margin: 5 }}
                  variant="outlined"
                  color="secondary"
                >
                  {c.name}
                </Button>
              ))}
            </Grid>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

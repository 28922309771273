import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "Api";
import "./Artist.scss";
import "Assets/animate.css";
import { HorizontalArtistItem } from "Components/HorizontalArtists/HorizontalArtists";
import { TextField } from "@material-ui/core";
import Loading from "Components/Loading/Loading";

export default function AllArtists() {
  const [loading, setLoading] = useState(true);

  const [artists, setArtists] = useState([]);
  const [allArtists, setAllArtists] = useState([]);
  const [count, setCount] = useState(35);
  useEffect(() => {
    new Api()
      .get("/api/artists/")
      .then((c) => c.json())
      .then((c) => {
        let w = c.sort(
          (a, b) => {
            // console.log(a.name.slice(0, 1) < b.name.slice(0, 1));
            if ((a.showOnMain ?? false) == true) return -1;
            if ((b.showOnMain ?? false) == true) return 1;
            if ((a.isVerified ?? false) == true) return -1;
            if ((b.isVerified ?? false) == true) return 1;
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
          // if (a.showOnMain ?? false) {
          //   return -2;
          // } else if (a.name.slice(0, 1) < b.name.slice(0, 1)) {
          //   return 0;
          // } else {
          //   return 1
          // }
        );
       // console.log(w);
        setArtists(w.slice(0, count));
        setAllArtists(w);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    window.onscroll = (e) => {
      let windowRelativeBottom = window.document.documentElement.getBoundingClientRect()
        .bottom;

      if (
        windowRelativeBottom <
        window.document.documentElement.clientHeight + 50
      ) {
        if (count < allArtists.length) {
          setCount((e) => e + 35);
        }
      }
    };
  }, [allArtists.length]);
  useEffect(() => {
    setArtists(allArtists.slice(0, count));
  }, [count]);
  return (
    <div id="all-artists">
      {loading ? (
        <Loading />
      ) : (
        <div className="container">
          <br />
          <br />
          <br />
          <div className="search">
            <input
              onChange={search}
              type="text"
              placeholder="Search For Artists "
            />
          </div>
          <div className="artists">
            {artists.map((c,index) => (
              <div key={index} className="animate__animated animate__fadeInDown">
                <HorizontalArtistItem {...c} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  function search(e) {
    setArtists([
      ...allArtists.filter((c) =>
        c.name.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    ]);
  }
}

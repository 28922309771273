export function toFormatedTime(seconds) {
  var date = new Date(1970, 0, 1);
  if (isNaN(seconds)) {
    return "00:00";
  }
  date.setSeconds(seconds);
  return (
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
}

import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
export interface SocialProps {
  onChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  value?: string;
}

const Social: React.FC<SocialProps> = (props: SocialProps) => {
  const s = ["instagram", "youtube", "facebook", "telegram","twitter","spotify"];
  return (
    <FormControl style={{minWidth:150}}  >
      <InputLabel id="socialID">Select Social Type</InputLabel>
      <Select
        name="socialID"
        id="socialID"
        value={props.value}
        onChange={(e,c) => {
          if (props.onChange != undefined) {
            props.onChange(e,c);
          }
        }}
      >
        {s.map((c: any) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Social;

import React, { useEffect, useState } from "react";
import "./NewsPost.scss";
import "Assets/Yekan-Font/stylesheet.css";
import Loading from "Components/Loading/Loading";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useParams, useHistory, Link } from "react-router-dom";
import Api from "Api";

export default function NewsPost() {
    const [post, setPost] = useState({});
    const { newsID } = useParams();
    const [loading, setloading] = React.useState(true);



    useEffect(() => {
        new Api()
          .get(`https://news.avang.live/wp-json/wp/v2/posts/${newsID}`)
          .then((c) => c.json())
          .then((c) => setPost(c));
          setloading(false);

      }, [newsID]);
      //console.log(post);


      return loading ? (
        <Loading />
      ) : (
            <div className="container">
              <div className="news-post">
                    <div className="right">
                        <img src={`${post.featured_media_src_url}`} alt="" width="100%" />
                    </div>
                    <div className="left">
                        <div className="title">
                        <h2>{post.title?.rendered}</h2>
                        </div>
                        <div className="content">
                        <p dangerouslySetInnerHTML={{ __html: post.content?.rendered}}></p>
                        </div>
                    </div>
                </div>
            </div>
        
    );
  }
  

  
import React, { useState, useEffect } from "react";
import Api from "Api";
import "./MusicVideos.scss";
import { HorizontalVideoItem } from "Components/HorizontalVideo/HorizontalVideo";
import { useParams } from "react-router-dom";
import Loading from "Components/Loading/Loading";
export default function MusicVideos() {
  const [videos, setVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [loading, setloading] = useState(true);

  const { artistID } = useParams();
  useEffect(() => {
    if (artistID == undefined)
      new Api()
        .get("/api/Musics/vidoes")
        .then((c) => c.json())
        .then((c) => {
          setVideos(c);
          setAllVideos(c);
          setloading(false);
        });
    else
      new Api()
        .get("/api/artists/videos/" + artistID)
        .then((c) => c.json())
        .then((c) => setVideos(c));
  }, []);
  return (
    <div id="music-videos">
      <div className="container">
        <h2>New Music Videos</h2>

        {loading ? (
          <Loading />
        ) : (
          <div className="videos">
            {videos.length > 0 ? (
              videos.map((c) => <HorizontalVideoItem {...c} />)
            ) : (
              <h1>No Videos</h1>
            )}{" "}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./MyPlayLists.scss";
import Api from "Api";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { PlayerContainer } from "Context/Contexts";
import Loader from "react-loader-spinner";
export default function MyPlayLists() {
  const player = PlayerContainer.useContainer();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [musics, setMusics] = useState([]);
  const [current, setCurrent] = useState({});
  useEffect(() => {
    new Api()
      .post("/api/playlists/GetPlayList")
      .then((c) => c.json())
      .then((c) => {
        setData(c);
      });
  }, []);
  return (
    <div id="my-playlists">
      <div className="container">
        <div className="left">
          <div className="my-playlists">
            {data.length <= 0 && <div>play list is empty</div>}
            {data.map((c, index) => (
              <div
                style={{
                  backgroundColor:
                    current.playListID == c.playListID
                      ? "orange"
                      : "darkslategray",
                }}
                onClick={(e, elm) => {
                  load(c);
                }}
                className="my-playlist-item"
              >
                <i className="fas fa-list"></i>
                {c.name}
                <i
                  onClick={() => remove(c.playListID, index)}
                  className="fas fa-window-close"
                ></i>
              </div>
            ))}
          </div>
        </div>
        <div className="right">

        <div className="title-row">
        {musics.length > 0 && (
          <>
        <h3>{current.name} Tracks</h3>
          <button
          onClick={(e) => {
            player.setMusics([...musics.sort(() => 0.5 - Math.random())]);
            player.setIndex(0);

          }}>
          shuffle
        </button>
        </>        
              )}
        </div>

          {!loading ? (
            <div className="music-grid">
              <div className="tracks">
                {musics.length == 0 && Object.keys(current).length > 0 ? (
                  <h1>No Music</h1>
                ) : (
                  ""
                )}
                {musics.map((c, index) => (
                  <MusicGridItem
                    removeFromPlaylist={() => {
                      return new Api()
                        .post("/api/playlistmusics/DeletePlaylistMusic", {
                          body: JSON.stringify({
                            musicID: c.musicID,
                            playListID: current.playListID,
                          }),
                        })
                        .then((c) => c.ok)
                        .then((c) => {
                          musics.splice(index, 1);
                          setMusics([...musics]);
                        });
                    }}
                    playlist
                    width={46}
                    data={musics}
                    index={index}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="loader">
              <Loader type="Bars" color="orange" height={50} width={50} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  function load(c) {
    setCurrent(c);
    setLoading(true);
    new Api()
      .get("/api/playlists/getmusics/" + c.playListID)
      .then((c) => c.json())
      .then((c) => {
        setMusics(c);
        setLoading(false);
      });
  }
  function remove(id, index) {
    if (window.confirm("Are You Sure ???"))
      new Api()
        .delete("/api/playlists/" + id)
        .then((c) => c.ok)
        .then((c) => {
          data.splice(index, 1);
          setData([...data]);
        });
  }
}

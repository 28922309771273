import * as React from "react";
import bg from "Assets/Images/PlayListBackGround.png";
import "./HorizontalPodcasts.scss";
import { useHistory } from "react-router-dom";
import PodcastRowItem from "Components/PodcastRow/PodcastRowItem";
export interface HorizontalPodcastProps {
  data: [];
}

const HorizontalPodcast: React.SFC<HorizontalPodcastProps> = (
  props: HorizontalPodcastProps
) => {
  const history = useHistory();
  return (
    <div
      style={{ backgroundImage: "url(" + bg + ")" }}
      className="horizontal-podcast"
    >
        <div className="rowsTitle">
          <p>New Podcasts</p>
          <span
            onClick={(e) => {
              window.scroll({ top: 0 });
              history.push("/podcasts");
            }}
            
          >
            See all
          </span>
        </div>
      <div className="podcasts">
        {props.data.map((c, index) => (
          <HorizontalPodcastItem key={index} data={props.data} index={index} />
        ))}
      </div>
    </div>
  );
};

export default HorizontalPodcast;

export function HorizontalPodcastItem(props: any) {
  return <PodcastRowItem data={props.data} index={props.index} />;
}

import * as React from "react";
import "./Artist.scss";
import MusicRowItem from "Components/MusicRow/MusicRowItem";
import MusicGrid, { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import Api from "Api";
import HotMusic from "Components/MusicGrid/HotMusic";
import HorizontalVideo, {
  HorizontalVideoItem,
} from "Components/HorizontalVideo/HorizontalVideo";
import HorizontalAlbum, {
  HorizontalAlbumItem,
} from "Components/HorizontalAlbums/HorizontalAlbum";
import { useParams } from "react-router-dom";
import { PlayerContainer } from "Context/Contexts";
import Loading from "Components/Loading/Loading";
import AuthService from "AuthService";
import { red } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

export interface ArtistProps {}
// Define the type for the params object
type RouteParams = {
  artistID: string;
  // other params if any
};

const Artist: React.FC<ArtistProps> = () => {
  const { artistID } = useParams<RouteParams>();

  const [isFollowed, setIsFollowed] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({
    tracks: [],
    artist: {},
    albums: [],
    topSongs: [],
    musicVideos: [],
  });
  console.log(data);
  
  const player = PlayerContainer.useContainer();
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
 
    setLoading(true);
    new Api()
      .get("/api/artists/webmain/" + artistID)
      .then((c) => c.json())
      .then((c) => {
        if (c.status == 404) {
          history.push("/");
        }
        setData(c);
        setLoading(false);
      });
  }, [artistID]);
 // console.log(data);
  React.useEffect(() => {
    new Api()
      .get("/api/artists/isFollowed/" + artistID)
      .then((c) => c.text())
      .then((c: any) => {
        setIsFollowed(c == "true");
      });
  }, [artistID]);
  //console.log('artstdata',data);

  return loading ? (
    <Loading />
  ) : (
    <div className="container" id="artist">
      <ArtistHeader />
      {data.topSongs.length > 0 ? <TopSongs /> : ""}
      {data.tracks.length > 0 ? <Tracks /> : ""}
      {data.musicVideos.length > 0 ? <MusicVideos /> : ""}
      {data.albums.length > 0 ? <Albums /> : ""}
    </div>
  );
  function ArtistHeader() {
    return (
      <div id="header">
        <div
        className="artist-profile-header"
          style={{
            backgroundImage:
              'url("/api/file/images/artist/header/' + artistID + '.jpg")',
          }}
          id="image"
        >
          <div className="info">
            <div className="name">
              <h1>
                {data.artist.name}{" "}
                {(data.artist.isVerified ?? false) == true && (
                  <i className="fa fa-check-circle"></i>
                )}
              </h1>
            </div>
            <div className="statistics">
              <div>
                <span className="number">
                  {" "}
                  {data.artist.followerCount ?? 0}
                </span>
                <span className="title"> Followers </span>
              </div>
              <div>
                <span className="number">{data.artist.playCount ?? 0} </span>
                <span className="title">Plays</span>
              </div>
            </div>
            <div className="shuffle">
              {isFollowed == true ? (
                <button
                  onClick={() => {
                    if (!new AuthService().loggedIn()) {
                      player.setLogin(true);
                      return;
                    }
                    new Api()
                      .post("/api/follows", {
                        body: JSON.stringify({
                          artistID: data.artist.artistID,
                        }),
                      })
                      .then((c) => c.text())
                      .then((c) => {
                        if (c == "2") {
                          data.artist.followerCount--;
                          setData(data);
                          setIsFollowed(false);
                        }
                      });
                  }}
                  className="follow unfollow"
                >
                  UnFollow
                </button>
              ) : (
                <button
                  onClick={() => {
                    if (!new AuthService().loggedIn()) {
                      player.setLogin(true);
                      return;
                    }
                    new Api()
                      .post("/api/follows", {
                        body: JSON.stringify({ artistID: artistID }),
                      })
                      .then((c) => c.text())
                      .then((c) => {
                        if (c == "1") {
                          data.artist.followerCount++;
                          setData(data);
                          setIsFollowed(true);
                        }
                      });
                  }}
                  className="follow"
                >
                  Follow
                </button>
              )}
              <button
                onClick={(e) => {
                  player.setMusics([...data.tracks.sort(() => 0.5 - Math.random())]);
                  player.setIndex(0);
    
                }}
              >
                <span className="fa fa-random"></span> Play Shuffle
              </button>
            </div>
            <div className="social">
              {(data?.artist?.socials ?? []).map((c: any,index:any) => (
                <a key={index} href={c.link} target="blank">
                  <i className={"fab fa-" + c.type}></i>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  function TopSongs() {
    return (
      <div id="top-songs">
        <div className="title">
          <h2>Top Songs</h2>
          {/*<a href="#">See All</a>*/}
        </div>
        <div className="musics">
          {data.topSongs.slice(0, 7).map((c: any, index: number) => (
            <MusicRowItem key={index} data={data.topSongs} index={index} />
          ))}
        </div>
      </div>
    );
  }

  function Tracks() {
    return (
      <div className="music-grid">
        <div className="info">
          <h2>Tracks </h2>
          {/*<a href="javascript:">See all</a>*/}
        </div>
        <div className="tracks">
          {data.tracks.map((c: any, index: number) => (
            <MusicGridItem key={index} width={23.5} data={data.tracks} index={index} />
          ))}
        </div>
      </div>
    );
  }
  function MusicVideos() {
    return (
      <div id="music-videos">
        <div id="horizontal-video">
          <div className="title">
            <h2>Music Videos</h2>
            {/*<a href="#">See All</a>*/}
          </div>
          <div style={{display: "flex", flexWrap: "wrap" }} className="row">
            {data.musicVideos.map((c: any,index:any) => (
              <HorizontalVideoItem key={index} {...c} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  function Albums() {
    return (
      <div id="albums">
        <div className="horizontal-albums">
          <h2 className="title">Albums</h2>
          <div className="album-items">
            {data.albums.map((c: any,index:any) => (
              <HorizontalAlbumItem key={index} {...c} />
            ))}
          </div>
        </div>{" "}
      </div>
    );
  }
};

export default Artist;

import * as React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Api from "Api";

export interface LanguageProps {
  value: number;
  onChange: any;
}

const SelectLanguage: React.FC<LanguageProps> = React.memo(
  (props: LanguageProps) => {
    const [langs, setLangs] = React.useState([]);

    React.useEffect(() => {
      new Api()
        .get("/api/languages")
        .then((c) => c.json())
        .then((c) => {
          setLangs(c);
        });
    }, []);

    return (
      <FormControl>
        <InputLabel id="languageID">Select Language</InputLabel>
        <Select
          name="languageID"
          id="languageID"
          value={props.value}
          onChange={props.onChange}
        >
          {langs.map((c: any) => (
            <MenuItem key={c.languageID} value={c.languageID}>
              {c.lang}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default SelectLanguage;

import * as React from "react";
import "./BottomPlayer.scss";
import { PlayerContainer } from "Context/Contexts";
import Api from "Api";
import { useParams, Link, useHistory } from "react-router-dom";
import { toFormatedTime } from "Common";
import Loader from "react-loader-spinner";
import AuthService from "AuthService";
import Loading from "Components/Loading/Loading";
import { Palette } from "@material-ui/icons";
import { pl } from "date-fns/esm/locale";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";

export interface BottomPlayerProps {}
const BottomPlayer: React.FC<BottomPlayerProps> = (
  props: BottomPlayerProps
) => {
  const player = PlayerContainer.useContainer();
  const [progress, setProgress] = React.useState(0);
  const [time, setTime] = React.useState("");
  const [top5, settop5] = React.useState();
  const [mute, setMute] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const [relation, setRelation] = React.useState(false);
  const [likeLoading, setLikeLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  // const [id, loading] = player.loading;
  //get liked  state
  React.useEffect(() => {
    if (player.musics[player.index]?.musicID != undefined) {
      new Api()
        .get("/api/musics/isLiked/" + player.musics[player.index]?.musicID)
        .then((c) => c.text())
        .then((c) => {
          setLiked(c == "true");
        });
          new Api()
            .get("/api/musics/top5music/")
            .then((c) => c.json())
            .then((c) => settop5(c));
      console.table(top5);
        //sets play count
    new Api().post("/api/musics/play/" + player.musics[player.index]?.musicID);

    }
      if (player.podcasts[player.index]?.podcastID != undefined) {
      new Api()
        .get(
          "/api/podcasts/isLiked/" + player.podcasts[player.index]?.podcastID
        )
        .then((c) => c.text())
        .then((c) => {
          setLiked(c == "true");
        });
    }
  }, [player.index, player.podcasts, player.musics]);
  
  //loading show
  // React.useEffect(() => {
  //   player.audio.onloadstart = (e) => {
  //     if (player.mode == "podcast") {
  //       player.setLoading([player.podcasts[player.index]?.podcastID, true]);
  //     } else {
  //       player.setLoading([player.musics[player.index]?.musicID, true]);
  //     }
  //   };

  //   player.audio.onplay = () => {
  //     if (player.mode == "podcast") {
  //       player.setLoading([player.podcasts[player.index]?.podcastID, false]);
  //     } else {
  //       player.setLoading([player.musics[player.index]?.musicID, false]);
  //     }
  //   };
  // }, [player.index, player.mode, player.musics, player.podcasts]);
  //saved state

  React.useMemo(() => {
    if (player.musics[player.index]?.musicID != undefined) {
      new Api()
        .get("/api/musics/isSaved/" + player.musics[player.index]?.musicID)
        .then((c) => c.text())
        .then((c) => {
          setSaved(c == "true");
        });
    }
    if (player.podcasts[player.index]?.podcastID != undefined) {
      new Api()
        .get(
          "/api/podcasts/isSaved/" + player.podcasts[player.index]?.podcastID
        )
        .then((c) => c.text())
        .then((c) => {
          setSaved(c == "true");
        });
    }
  }, [player.index, player.podcasts, player.musics]);

  // React.useEffect(() => {
  //   if (player.audio.paused) {
  //     player.setPlay("play");
  //   } else {
  //     player.setPlay("pause");
  //   }
  // }, [player.audio.paused]);

  // play pause


  React.useEffect(() => {
    // console.log(
    //   player.play,
    //   (window as any).unlock,
    //   new Api().getBroweserName().browserName
    // );
    if (player.play === "pause") {
      if (
        // new Api().getBroweserName().browserName != "Safari" ||
        (window as any).unlock
      )
        player.audio.play().catch((w) => {
          player.setPlay("play");
        });
      // player.audio.addEventListener(
      //   "canplay",
      //   () => {
      //     if (
      //       // new Api().getBroweserName().browserName != "Safari" ||
      //       (window as any).unlock &&
      //       player.play == "pause"
      //     )
      //       player.audio.play().catch((w) => {
      //         player.setPlay("play");
      //       });
      //     // if (player.mode == "music") {
      //     //   player.setLoading([player.musics[player.index]?.musicID, false]);
      //     // } else {
      //     //   player.setLoading([
      //     //     player.podcasts[player.index]?.podcastID,
      //     //     false,
      //     //   ]);
      //     // }
      //     // if (
      //     //   player.audio.paused &&
      //     //   ((window as any).unlock == true ||
      //     //     new Api().getBroweserName().browserName != "Safari")
      //     // ) {
      //     //   console.log("play pause effect");

      //     //   player.audio.play();
      //     // }
      //   },
      //   false
      // );
    } else {
      player.audio.pause();
    }
  }, [player.play]);

  //repeat
  player.audio.onended = () => {
    player.setPlay("play");
    let idx
    if(player.retry){
      idx = player.index
    }else{

     idx = player.index != player.musics.length - 1 ? player.index + 1 : 0;
    }
    player.setIndex(idx);
    setProgress(0);
    player.setPlay("pause");
  };
  // player.audio.addEventListener("ended", () => {
  //   player.setPlay("play");
  //   const idx = player.index != player.musics.length - 1 ? player.index + 1 : 0;
  //   player.setIndex(idx);
  //   setProgress(0);
  //   player.setPlay("pause");
  // });
  React.useEffect(() => {
    player.audio.muted = mute;
  }, [mute]);
  //volume
  React.useEffect(() => {
    player.audio.volume = player.volume;
    new Api().setSettings({ volume: player.volume });
  }, [player.volume]);
  // React.useEffect(() => {
  //   player.audio.play();
  // }, [player.audio]);

  React.useEffect(() => {
    if (player.musics.length > 0 || player.podcasts.length > 0) {
      // if (!player.audio.paused) {
      // player.audio.pause();
      // }
      if (player.musics[player.index] != undefined && player.mode == "music") {
        //document.title = "🎵" + player.musics[player.index]?.title + " - " + player.musics[player.index]?.artists?.join(" , ")  + " - Avang Music" ;

        player.setPlay("play");
        // player.audio = new Audio();
        player.audio.src =
          "/music/music/" +
          player.quality +
          "/" +
          player.musics[player.index].musicID +
          ".mp3";
        player.audio.load();
        player.setAudio(player.audio);
        setTimeout(() => {
          if (
            (window as any).unlock
            // ||
            // new Api().getBroweserName().browserName != "Safari"
          ) {
            // player.audio.play().catch((w) => {
            //   player.setPlay("play");
            // });
            player.setPlay("pause");
          }
        }, 300);
        // if (new Api().getBroweserName().browserName!='Safari'||   (window as any).unlock) player.audio.play();
        // (window as any).music.load(
        //   "/api/main/mpd/music/" + player.musics[player.index].musicID + ".mpd"
        // );
      } else if (
        player.podcasts[player.index] != undefined &&
        player.mode == "podcast"
      ) {
        //document.title = "🎧" + player.podcasts[player.index]?.title + " - " + player.podcasts[player.index]?.djs?.join(" , ") + " - Avang Music";

        player.audio.src =
          "/music/podcast/" +
          player.quality +
          "/" +
          player.podcasts[player.index]?.podcastID +
          ".mp3";
        new Api().setSettings({
          musics: player.musics,
          index: player.index,
        });
        player.audio.load();
        player.setAudio(player.audio);
        setTimeout(() => {
          if (
            (window as any).unlock
            //|| new Api().getBroweserName().browserName != "Safari"
          ) {
            // player.audio.play().catch((w) => {});
            player.setPlay("pause");
          }
        }, 300);
      }
    }

    if (
      player.musics.length > 0 || player.podcasts.length > 0
    ) {
      
    new Api()
      .get("/api/Musics/musicRelation/" + player.musics[player.index]?.musicID)
      .then((c) => c.json())
      .then((c) => {
        //console.log("rel", c);
        setRelation(c?.relations?.musicVideoId);
      });
  }
}
  
  , [

    player.index,
    player.musics,
    player.podcasts,
    player.quality,
    player.mode,
    player.musics.musicID,
  ]);

  //console.log("player", player.musics[player.index]?.musicID);

  //console.log("rel", relation);

  // React.useEffect(() => {
  //   if (player.audio.paused) {
  //     player.setPlay("pause");
  //   }
  // }, [player.audio.src]);
  // React.useEffect(()=>{
  //   // setProgress(player.audio.currentTime/ player.audio.duration*100);
  // },[player.audio.currentTime])
  player.audio.ontimeupdate = (e) => {
    let pr = (player.audio.currentTime / player.audio.duration) * 100;
    setTime(
      toFormatedTime(player.audio.currentTime) +
        "/" +
        toFormatedTime(isNaN(player.audio.duration) ? 0 : player.audio.duration)
    );
    if (!isNaN(pr)) {
      setProgress(pr);
    }
  };
  const history = useHistory();

  React.useEffect(() => {
    if (
      window.location.pathname.split("/")[1] == "playvideo" ||
      window.location.pathname.split("/")[1] == "live"
    ) {
      player.setPlay("play");
      player.audio.pause();
    }
  }, [window.location.pathname]);
  const route = window.location.pathname.split("/")[1];
  if (route == "playvideo" || route == "live") {
    return null;
  }

  const ArtistNameForShare = player.musics[player.index]?.artists[0].replace(
    / /g,
    "-"
  );
  const MusicTitleForShare = player.musics[player.index]?.title.replace(
    / /g,
    "-"
  );


  
  return player.musics[player.index]?.musicID != undefined ||
    player.podcasts[player.index]?.podcastID != undefined ? (
    <div id="s-bottom-player"
    style={{
      backgroundColor:
        1 == player.podcasts[player.index]?.musicID
          ? "red"
          : ""
    }}

    >
      <Helmet>
        <title>
          {"🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"}
        </title>
        <meta charSet="utf-8" />

        <meta
          name="description"
          content={
            "Listen to " +
            player.musics[player.index]?.title +
            " of " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta property="google" content="notranslate" />
        <meta property="fb:app_id" content="000000" />
        <meta
          property="og:title"
          content={
            "🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta
          property="og:description"
          content={player.musics[player.index]?.lyrics}
        />
        <meta
          property="og:url"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/playmusic/" +
            player.musics[player.index]?.musicID +
            "/" +
            player.musics[player.index]?.artists[0] +
            "/" +
            player.musics[player.index]?.title
          }
        />
        <meta
          property="og:image"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/api/file/images/music/" +
            player.musics[player.index]?.musicID +
            ".jpg"
          }
        />
        <meta property="og:type" content="music.song" />
        <meta property="og:site_name" content="Avang Music" />
        <meta
          property="music:musician"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/artist/" +
            player.musics[player.index]?.artistID +
            "/" +
            player.musics[player.index]?.artists[0]
          }
        />
        <meta
          property="music:release_date"
          content={player.musics[player.index]?.releaseDate}
        />
        <meta property="music:song:disc" content="1" />
        <meta property="music:song:track" content="1" />
        <meta property="al:android:app_name" content="Avang Music" />
        <meta property="al:android:package" content="com.parnaco.avangmusic" />
        <meta property="al:ios:app_name" content="Avang Music" />
        <meta property="al:ios:app_store_id" content="00000" />
        <meta property="twitter:site" content="@avangmusic" />
        <meta
          property="twitter:title"
          content={
            "🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta
          property="twitter:description"
          content={
            "Listen to " +
            player.musics[player.index]?.title +
            " of " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music --- " +
            player.musics[player.index]?.lyrics
          }
        />
        <meta
          property="twitter:image"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/api/file/images/music/" +
            player.musics[player.index]?.musicID +
            ".jpg"
          }
        />
        <meta property="twitter:card" content="summary" />
      </Helmet>

      <input
        onChange={(e) => {
          try {
            if (player.audio.seekable) {
              player.audio.currentTime =
                (e.target.valueAsNumber / 100) * player.audio.duration;
            }
          } catch (error) {}
        }}
        type="range"
        value={progress}
        className="seekbar"
      />
      <div id="s-bottom-panel">
        <div className="artist-info">
          <img
            onClick={() => {
              if (player.mode == "music") {
                history.push(
                  "/playMusic/" +
                    player.musics[player.index]?.musicID +
                    "/" +
                    player.musics[player.index]?.artists[0].replace(/ /g, "-") +
                    "/" +
                    player.musics[player.index]?.title.replace(/ /g, "-")
                );
              } else {
                history.push(
                  "/playPodcast/" +
                    player.podcasts[player.index]?.podcastID +
                    "/" +
                    player.podcasts[player.index]?.artists[0].replace(
                      / /g,
                      "-"
                    ) +
                    "/" +
                    player.podcasts[player.index]?.title.replace(/ /g, "-")
                );
              }
            }}
            src={
              player.mode == "music"
                ? "/api/file/images/music/" +
                  player.musics[player.index]?.musicID +
                  ".jpg"
                : "/api/file/images/podcast/" +
                  player.podcasts[player.index]?.podcastID +
                  ".jpg"
            }
            alt=""
          />
          <div className="info">
            <span className="artist-name">
            <Marquee>
              {player.mode == "music"
                ? (player.musics[player.index]?.musicArtists ?? []).map(
                    (c: any, index: any) => (
                      <>
                      <Link key={index} to={ "/artist/" + c.artistID +"/" + c?.artist.name.replace(  / /, "-" )  }>
                          {c?.artist.name}
                      </Link>
                      {/* {index <= 0 && c.artist.name.length > 1 ? " - " + " " : <span></span> } */}
                      </>
                    )
                  )
                : (player.podcasts[player.index]?.podcastDjs ?? []).map(
                    (c: any, index: any) => (
                      <Link key={index} to={"/dj/" + c.dj.djID}>
                        <Marquee>{c.dj.name}</Marquee>
                      </Link>
                    )
                  )}
              </Marquee>

            </span>
            <span
              className="track-name text_animated"
              onClick={() => {
                if (player.mode == "music") {
                  history.push(
                    "/playMusic/" +
                      player.musics[player.index]?.musicID +
                      "/" +
                      player.musics[player.index]?.artists[0].replace(
                        / /g,
                        "-"
                      ) +
                      "/" +
                      player.musics[player.index]?.title.replace(/ /g, "-")
                  );
                } else {
                  history.push(
                    "/playPodcast/" +
                      player.podcasts[player.index]?.podcastID +
                      "/" +
                      player.podcasts[player.index]?.artists[0].replace(
                        / /g,
                        "-"
                      ) +
                      "/" +
                      player.podcasts[player.index]?.title.replace(/ /g, "-")
                  );
                }
              }}
            >
              <Marquee>
                {player.mode == "music"
                  ? player.musics[player.index]?.title
                  : player.podcasts[player.index]?.title}
              </Marquee>
            </span>
          </div>
        </div>
        <div className="operation">
          {likeLoading ? (
            <Loader type="TailSpin" color="white" height={14} width={14} />
          ) : (
            <i
              onClick={() => {
                if (!new AuthService().loggedIn()) {
                  player.setLogin(true);
                  return;
                }
                setLikeLoading(true);
                //setLiked((e) => !e);
                new Api()
                  .post("/api/main/like", {
                    body: JSON.stringify(
                      player.mode == "music"
                        ? {
                            musicID: player.musics[player.index]?.musicID,
                          }
                        : {
                            podcastID: player.podcasts[player.index]?.podcastID,
                          }
                    ),
                  })
                  .then((c) => c.text())
                  .then((c) => {
                    setLikeLoading(false);
                    c == "1" ? setLiked(true) : setLiked(false);
                  });
              }}
              className={
                liked == true
                  ? "fas fa-heart red scale-animation"
                  : "far fa-heart"
              }
            ></i>
          )}
          {saveLoading ? (
            <Loader type="TailSpin" color="white" height={14} width={14} />
          ) : (
            <i
              onClick={() => {
                if (!new AuthService().loggedIn()) {
                  player.setLogin(true);
                  return;
                }
                setSaveLoading(true);
                new Api()
                  .post("/api/savedMusics", {
                    body: JSON.stringify(
                      player.mode == "music"
                        ? {
                            musicID: player.musics[player.index]?.musicID,
                          }
                        : {
                            podcastID: player.podcasts[player.index]?.podcastID,
                          }
                    ),
                  })
                  .then((c) => c.text())
                  .then((c) => {
                    setSaveLoading(false);
                    c == "true" ? setSaved(true) : setSaved(false);
                  });
              }}
              className={
                (saved == true ? "fas orange " : "far") + " fa-bookmark"
              }
            ></i>
          )}

          {relation == undefined ? (
            ""
          ) : (
            <a href={"/playvideo/" + relation}>
              <i className="fa fa-video-camera"></i>
            </a>
          )}

          <i
            onClick={() =>
              player.setShare(
                player.mode == "music"
                  ? {
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        "/playmusic/" +
                        player.musics[player.index]?.musicID +
                        "/" +
                        ArtistNameForShare +
                        "/" +
                        MusicTitleForShare,
                      show: true,
                    }
                  : {
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.hostname +
                        "/playPodcast/" +
                        player.musics[player.index]?.musicID,
                      show: true,
                    }
              )
            }
            className="fas fa-share-alt"
          ></i>
        </div>
        <div className="controlls">
          <i
            style={
              player.retry ? { color: "e57833" } : {}
            }
            onClick={() => {
              player.setRetry(!player.retry);
            }}
            className={
              (player.retry ? "orange " : "") + "fas fa-repeat" }
          >
          </i>
          <i
            onClick={() => {
              if (player.mode === "music") {
                player.setMusics([
                  ...player.musics.sort(() => 0.5 - Math.random()),
                ]);
              }
              if (player.mode === "podcast") {
                player.setPodcasts([
                  ...player.podcasts.sort(() => 0.5 - Math.random()),
                ]);
              }
              player.setIndex(0);
            }}
            className={ "fas fa-random" }
          >
          </i>
          <i
            onClick={() => {
              if (player.play == "pause") {
                player.setPlay("play");
              }
              player.audio.load();
              if (player.index == 0) {
                player.setIndex(0);
              } else {
                player.setIndex(player.index - 1);
              }
              setTimeout(() => {
                player.setPlay("pause");
              }, 400);
            }}
            className="fas fa-step-backward"
          ></i>
          {false ? (
            <i className="loader">
              <Loader type="Bars" color="white" height={30} width={30} />
            </i>
          ) : (
            <i
              onClick={() => {
                player.setPlay((c) => (c == "pause" ? "play" : "pause"));
              }}
              className={"fas fa-" + player.play}
            ></i>
          )}
          <i
            onClick={() => {
              if (player.play == "pause") {
                player.setPlay("play");
              }
              player.audio.load();
              if (player.mode == "music") {
                if (player.musics.length - 1 == player.index) {
                  player.setIndex(0);
                } else {
                  player.setIndex(player.index + 1);
                }
              } else {
                if (player.podcasts.length - 1 == player.index) {
                  player.setIndex(0);
                } else {
                  player.setIndex(player.index + 1);
                }
              }

              setTimeout(() => {
                player.setPlay("pause");
              }, 400);
            }}
            className="fas fa-step-forward"
          ></i>
        </div>{" "}
        <span className="time">{time}</span>
        <div className="options">
          <span
            className={"quality"}
            // onClick={() => setQuality(64)}
            // className={"quality " + (player.quality == "64" ? "active" : "")}
          >
            64
          </span>
          <span
            onClick={() => setQuality(128)}
            className={"quality " + (player.quality == "128" ? "active" : "")}
          >
            128
          </span>
          <span
            onClick={() => setQuality(320)}
            className={"quality " + (player.quality == "320" ? "active" : "")}
          >
            320
          </span>
          {/*<i className="fas fa-ellipsis-v"></i>*/}
          <i
            onClick={() => {
              setMute(!mute);
            }}
            className={"fas mobile fa-volume-" + (mute ? "mute" : "up")}
          ></i>
          <input
            onChange={(e) => player.setVolume(e.target.valueAsNumber / 100)}
            type="range"
            value={player.volume * 100}
            className="slider-volume mobile"
          />
          <div className="playlist">
            <i
              onClick={() => {
                player.setShowCurrenPlayList(!player.showCurrenPlayList);
              }}
              id="open-playlist"
              className="fas fa-list"
            ></i>
          </div>
          <div id="close-btn" className="close">
            <i
              onClick={() => {
                player.setPlay("play");
                player.audio.pause();
                document.title = "Avang Music - None Stop play music platform";

                //(document.getElementById("s-bottom-player") as HTMLFormElement).style.display = "none";
                // player.musics[player.index]?.musicID;
                player.setMusics([]);
                player.setPodcasts([]);
                //player.setIndex();
              }}
              className={"fas fa-close"}
            ></i>
          </div>
          <div id="hide-btn" className="">
            <i
              onClick={() => {
                (
                  document.getElementById("s-bottom-player") as HTMLFormElement
                ).style.marginBottom = "-50px";
                (
                  document.getElementById("hide-btn") as HTMLFormElement
                ).classList.add("hide-btn");
                (
                  document.getElementById("hide-btn") as HTMLFormElement
                ).style.marginBottom = "95px";
                (
                  document.getElementById("hide-icon-up") as HTMLFormElement
                ).classList.add("hide-icon-none");
                (
                  document.getElementById("hide-icon-down") as HTMLFormElement
                ).classList.remove("hide-icon-none");
                //(document.getElementById("hide-icon") as HTMLFormElement).remove();
              }}
              id="hide-icon-up"
              className={"fas fa-chevron-down"}
            ></i>
            <i
              onClick={() => {
                (
                  document.getElementById("s-bottom-player") as HTMLFormElement
                ).style.marginBottom = "0px";
                (
                  document.getElementById("hide-btn") as HTMLFormElement
                ).classList.remove("hide-btn");
                (
                  document.getElementById("hide-btn") as HTMLFormElement
                ).style.marginBottom = "0px";
                (
                  document.getElementById("hide-icon-up") as HTMLFormElement
                ).classList.remove("hide-icon-none");
                (
                  document.getElementById("hide-icon-down") as HTMLFormElement
                ).classList.add("hide-icon-none");
              }}
              id="hide-icon-down"
              className={"fas fa-chevron-up hide-icon-none"}
            ></i>
          </div>
        </div>
      </div>
    </div>
  ) : null;
  function setQuality(quality: number) {
    new Api().setSettings({ quality });
    player.setQuality(quality);
  }
};

export default BottomPlayer;

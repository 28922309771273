import * as React from "react";
import "./HorizontalArtist.scss";
import artist from "Assets/Images/artist.png";
import { useHistory, Link } from "react-router-dom";
export interface HorizontalArtistProps {
  data: [];
}

const HorizontalArtist: React.SFC<HorizontalArtistProps> = (
  props: HorizontalArtistProps
) => {
  const history = useHistory();

  return (
    <div className="horizontal-artist">
            <div className="rowsTitle">
        <p>Popular Artists</p>
        <span
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push("allartists");
          }}
        >
          See all
        </span>
      </div>
      <div className="artists">
        {props.data.map((c:any) => (
          <HorizontalArtistItem key={c.artistID} {...c} />
        ))}
      </div>
    </div>
  );
};
export function HorizontalArtistItem(props: any) {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        history.push("/artist/" + props.artistID + "/" + props.name.replace(/ /g, '-'));
      }}
      className="horizontal-artist-item"
    >
          <span className="image">
            <img
            onError={(e:any)=>{e.target.src="/api/file/images/artist.jpg"}}
              src={"/api/file/images/artist/" + props.artistID + ".jpg"}
              alt=""
            />
              <span
                onClick={() => {
                  history.push("/artist/" + props.artistID + "/" + props.name.replace(/ /g, '-'));
                }}
              >
                Profile
              </span>
          </span>
          <span className="artistName">
          {props.name}
          {(props.isVerified ?? false) == true && (
            <i className="fa fa-check-circle"></i>
          )}
        </span>
        </div>
  );
}
export default HorizontalArtist;

import React from 'react';
import { useHistory } from 'react-router-dom';

export default function HorizontalDjItem(props) {
    const history = useHistory();
    return (
      <div className="horizontal-artist-item">
        <div id="horizontal-artist">
          <div className="artist">
            <div className="image">
              <img
              onError={e=>{e.target.src="/api/file/images/dj.jpg"}}
              src={"/api/file/images/dj/" + props.djID + ".jpg"} alt="" />
              <div>
                <span
                  onClick={() => {
                    history.push("/dj/" + props.djID);
                  }}
                >
                  Profile
                </span>
              </div>
            </div>
          </div>
          <span>{props.name}</span>
        </div>
      </div>
    );
  }
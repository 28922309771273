export default class Queue {
  // Array is used to implement a Queue
  constructor() {
    this.items = [];
  }
  enqueue(element) {
    this.items.push(element);
  }
  dequeue() {
    if (this.isEmpty()) return "Underflow";
    return this.items.shift();
  }
  front() {
    if (this.isEmpty()) return "No elements in Queue";
    return this.items[0];
  }
  isEmpty() {
    // eslint-disable-next-line eqeqeq
    return this.items.length == 0;
  }
  printQueue() {
     JSON.stringify(this.items);
  }
}

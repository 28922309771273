import React, { useState, useEffect } from "react";
import "./PlayList.scss";
import Api from "Api";
import { useParams } from "react-router-dom";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { PlayerContainer } from "Context/Contexts";
export default function PlayList() {
  const { collectionID } = useParams();
  const [collection, setCollection] = useState({});
  const [musics, setMusics] = useState([]);
  const player = PlayerContainer.useContainer();
  useEffect(() => {
    new Api()
      .get(`/api/musics/collection/${collectionID}`)
      .then((c) => c.json())
      .then((c) => setMusics(c));
  }, [collectionID]);
  useEffect(() => {
    new Api()
      .get(`/api/collections/${collectionID}`)
      .then((c) => c.json())
      .then((c) => setCollection(c));
  }, [collectionID]);

  return (
    <div className="container">
      <div id="album">
        <div className="name">
          <h1>{collection.name}</h1>
          <button
            onClick={(e) => {
              player.setMusics([...musics.sort(() => 0.5 - Math.random())]);
              player.setIndex(0);
            }}
          >
            <i className="fas fa-random"></i> Shuffle
          </button>
        </div>
        <div className="info">
          <span>
            <i className="fas fa-microphone"></i>{" "}
            {collection.artists?.map((c) => c + " , ")}
          </span>

          <span className="count">
            <i className="fas fa-music"></i>
            {musics?.length} tracks
          </span>
        </div>
        <hr />
        <h3>PlayList Tracks</h3>
        <div className="music-grid">
          <div className="tracks">
            {musics.map((c, index) => (
              <MusicGridItem key={index} width={23.5} data={musics} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import "./SideMenu.scss";
import logo from "Assets/Images/Logo.png";
import { OpenMenuContainer } from "Context/Contexts";

import { Link } from "react-router-dom";
import InstallOnDesktop from "Components/InstallOnDesktop/InstallOnDesktop";
export interface SideMenuProps {}

const SideMenu: React.SFC<SideMenuProps> = () => {
  let openMenu = OpenMenuContainer.useContainer();

  React.useEffect(() => {
    for (
      let index = 0;
      index < window.document.getElementsByClassName("side-link").length;
      index++
    ) {
      window.document
        .getElementsByClassName("side-link")
        [index].addEventListener("click", () => {
          openMenu.setShow(false);
        });
    }
  }, []);
  return (
    <div>
      <div style={{ right: openMenu.show ? 0 : -303 }} className="side-menu">
        <div className="menu-container">
          <div className="close">
            <i
              onClick={() => {
                openMenu.setShow(false);
              }}
              className="fas fa-times-circle"
            ></i>

            {/* <span>Close Menu</span> */}
          </div>
          <div className="icons">
                <a href="https://facebook.com/avangmusic">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/avangmusic">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://t.me/avangrecords">
                  <i className="fab fa-telegram"></i>
                </a>
                <a href="https://youtube.com/avangmusic">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
          <Link to="/live" className="avang-tv side-link">
            <div className="title">
              <span className="white">AVANG</span>
              <span className="black">TV</span>
            </div>
            <img src={logo} alt="" />
          </Link>
          <div className="menu">
            <div className="library">
              <h3>LIBRARY</h3>
              <ul>
                <li>
                  <i className="fas fa-play"></i>
                  <Link className="side-link" to="/videos">
                    Music Videos
                  </Link>
                </li>
                <li>
                  <i className="fas fa-music"></i>
                  <Link className="side-link" to="/musics/new">
                    Music
                  </Link>
                </li>
                <li>
                  <i className="fas fa-list"></i>
                  <Link className="side-link" to="/playlists">
                    Playlis
                  </Link>
                </li>
                <li>
                  <i className="fas fa-list"></i>
                  <Link className="side-link" to="/podcasts">
                    PodCast
                  </Link>
                </li>
                <li>
                  <i className="fas fa-microphone-alt"></i>{" "}
                  <Link className="side-link" to="/allartists">
                    Artist
                  </Link>{" "}
                </li>
                <li>
                  <i className="fas fa-microphone-alt"></i>{" "}
                  <Link className="side-link" to="/alldjs">
                    Dj
                  </Link>{" "}
                </li>
                {/*<li>
                  <i className="fas fa-image"></i>
                  <a className="side-link" href="#">
                    Photo
                  </a>{" "}
                </li>*/}
                <li>
                  <i className="fas fa-edit"></i>
                  <Link className="side-link" to="/events">
                    Event
                  </Link>{" "}
                </li>
                <li>
                  <i className="fas fa-book"></i>
                  <Link className="side-link" to="/news">
                    News
                  </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="library">
              <br />
              <h3>MORE FEATURED</h3>
              <ul>
                {/*<li>
                  <i className="fas fa-download"></i>
                  <a href="https://avang.com/app/avangmusic-1.05.apk">Mobile Apps</a>{" "}
                </li>*/}
                <li>
                  <i className="fas fa-envelope"></i> <a href="/contact-us">Contact Us</a>
                </li>
                {/*<li>
                  <i className="fas fa-ad"></i>
                  <a href="#">Advertise</a>
                </li>*/}
                <li>
                  <i className="fas fa-user-shield"></i>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <i className="fas fa-info"></i>
                  <a href="/terms-of-service">Terms Of Service</a>
                </li>
              </ul>
            </div>
            {/*<div className="social">
              <h6>Folow AVANG</h6>
              <div className="icons">
                <a href="">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="">
                  <i className="fab fa-telegram"></i>
                </a>
                <a href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="">
                  <i className="fab fa-google-plus-g"></i>
                </a>
              </div>
            </div>*/}

            <div className="blank"></div>
          </div>
          <div className="appDownloadDiv-side-menu">
              <img
                    className="app-dl-appstore-footer"
                    src="/api/file/images/app-store.png"
                    width="220"
                    height="70"
                  />
              <img
                className="app-dl-playstore-footer"
                src="/api/file/images/play-store.png"
                width="250"
                height="100"
              />
            </div>

        </div>
      </div>
      <div
        onClick={(e) => openMenu.setShow(false)}
        style={{ left: openMenu.show ? 0 : "-100%" }}
        className="side-menu-closer"
      ></div>
    </div>
  );
};

export default SideMenu;

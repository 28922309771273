import * as React from "react";
import "./CommingSoon.scss";
import image from "Assets/Images/CommingSoon.png";
export interface CommingSoonProps {
  data: [];
}

const CommingSoon: React.SFC<CommingSoonProps> = (props: CommingSoonProps) => {

  return (
    <div className="comming-soon">
      <h2>Coming Soon</h2>
      <div className="comming-soon-items">
        {props.data.map((c) => (
          <CommingSoonItem {...c} />
        ))}
      </div>
    </div>
  );
};

export default CommingSoon;

export function CommingSoonItem(props: any) {

  return (
    <div className="comming-soon-item">
      <div className="image">
        <img src={"/api/file/images/music/" + props.musicID + ".jpg"} alt="" />
      </div>
      <div className="info">
        <p>
        {props.hasVideo  == true ? <i className="fas fa-play "></i> : <i className="fas fa-music "></i>}
        </p>
        <div className="music-info">
          <p>{props.title}</p>
          <h4>{props.artists}</h4>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable eqeqeq */
import React, { useRef } from "react";
import "./CurrentPlayList.scss";
import { Link } from "react-router-dom";
import { PlayerContainer } from "Context/Contexts";
export default function CurrentPlayList() {
  const player = PlayerContainer.useContainer();
  const ref = useRef(null);
  React.useEffect(() => {
    window.document.addEventListener("click", (e) => {
      if (
        !ref.current?.contains(e.target) &&
        e.target != window.document.getElementById("open-playlist")
      ) {
        player.setShowCurrenPlayList(false);
      }
    });
  }, []);
  return (
    <div  ref={ref}>
    {player.showCurrenPlayList ? (
        <div id="current-playlist">
          {player.mode == "music"
            ? player.musics.map((c, index) => (
                <div
                  onClick={() => {
                    if (
                      player.play == "pause" &&
                      player.musics[player.index]?.musicID == c.musicID
                    ) {
                      player.setPlay("play");
                    } else {
                      player.setMode("music");
                      player.setIndex(index);
                      setTimeout(() => {
                        player.setPlay("pause");
                      }, 100);
                    }
                  }}
                  style={{
                    backgroundColor:
                      player.musics[player.index]?.musicID == c.musicID
                        ? c.coverColor
                        : "",
                  }}
                >
                  <img onError={e=>{e.target.src="/api/file/images/placeholder.jpg"}}  src={"/api/file/images/music/" + c.musicID + ".jpg"} alt="" />
                  <Link to="">
                    {c.title?.substring(
                      0,
                      c?.title?.length > 18 ? 18 : c.title.length
                    )}{" "}
                    {c.title?.length > 18 ? "..." : ""}
                  </Link>
                  <i
                    onClick={() => {
                      if (
                        player.play == "pause" &&
                        player.musics[player.index]?.musicID == c.musicID
                      ) {
                        player.setPlay("play");
                      } else {
                        player.setMode("music");
                        player.setIndex(index);
                        setTimeout(() => {
                          player.setPlay("pause");
                        }, 100);
                      }
                    }}
                    className={
                      "fas fa-" +
                      (player.musics[player.index]?.musicID == c.musicID
                        ? player.play
                        : "play")
                    }
                  ></i>
                </div>
              ))
            : ""}
          {player.mode == "podcast"
            ? player.podcasts.map((c, index) => (
                <div
                  onClick={() => {
                    if (
                      player.play == "pause" &&
                      player.podcasts[player.index]?.podcastID == c.podcastID
                    ) {
                      player.setPlay("play");
                    } else {
                      player.setMode("podcast");
                      player.setIndex(index);
                      setTimeout(() => {
                        player.setPlay("pause");
                      }, 100);
                    }
                  }}
                  style={{
                    backgroundColor:
                      player.podcasts[player.index]?.podcastID == c.podcastID
                        ? c.coverColor
                        : "",
                  }}
                >
                  <img
                    src={"/api/file/images/podcast/" + c.podcastID + ".jpg"}
                    alt=""
                  />
                  <Link to="">
                    {c.title?.substring(
                      0,
                      c?.title?.length > 18 ? 18 : c.title.length
                    )}{" "}
                    {c.title?.length > 18 ? "..." : ""}
                  </Link>
                  <i
                    onClick={() => {
                      if (
                        player.play == "pause" &&
                        player.podcasts[player.index]?.podcastID == c.podcastID
                      ) {
                        player.setPlay("play");
                      } else {
                        player.setMode("podcast");
                        player.setIndex(index);
                        setTimeout(() => {
                          player.setPlay("pause");
                        }, 100);
                      }
                    }}
                    className={
                      "fas fa-" +
                      (player.podcasts[player.index]?.podcastID == c.podcastID
                        ? player.play
                        : "play")
                    }
                  ></i>
                </div>
              ))
            : ""}
        </div>
      ) : (
        null
      )}
      </div>
  );
}

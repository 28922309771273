import { rgbToHex } from "@material-ui/core/styles";
import ColorThief from "colorthief";

export default async function Thief(uri) {
  return await new Promise((resolve, reject) => {
    var imageUrl = URL.createObjectURL(uri);
    var image = new Image(360, 360);
    image.onload = () => {
      var colorThief = new ColorThief();
      var color = colorThief.getColor(image);
      let res = rgbToHex("rgb(" + color + ")");
      resolve(res);
    };

    image.src = imageUrl;
  });

}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import "./ContactUs.scss";
import { Button } from "@material-ui/core";

const ContactUs = () => {
	
	return (
		<div className="container">
			<div className="contact-container">
				<h1>Contact Us</h1>
				<p class="">To Promote your music and music video on the Avangmusic, contact the following emails.</p>
                <a href="mailto:music@avang.com">
 				    <p class="">music@avang.com</p>
                </a>
                <a href="mailto:ad@avang.com">
 				    <p class="">ad@avang.com</p>
                </a>
                <a href="mailto:app@avang.com">
 				    <p class="">app@avang.com</p>
                </a>
                <a href="mailto:info@avang.com">
 				    <p class="">info@avang.com</p>
                </a>
				
			</div>
		</div>
	);
};

export default ContactUs;

import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import Api from "Api";
import { Grid, TextField, Button } from "@material-ui/core";
import "./ArtistDetails.scss";

import { useForm } from "userForm";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "Components/DatePicker/DatePicker";
import ava from "assets/img/faces/marc.jpg";
import { ArrowBack } from "@material-ui/icons";
import AuthService from "AuthService";

export interface ArtistDetailsProps {}

const ArtistDetails: React.SFC<ArtistDetailsProps> = () => {
  const artistID = useParams<any>();
  const history = useHistory();
  const [state, setState] = React.useState<any>({});
  const avatar = React.useRef<any>(null);
  const header = React.useRef<any>(null);
  const preview = React.useRef<any>(null);
  const [user, setUser] = React.useState(null);
  const [file, setFile] = React.useState<any>({
    avatar: null,
    header: null,
  });

  const [content, setContent] = React.useState<any>(null);
  const [userArtistID, setUserArtistID] = React.useState();

  const auth = new AuthService();

  React.useEffect(() => {
    if (!auth.loggedIn()) {
      setContent("permition");
      setTimeout(() => {
        history.push("/");
      }, 3000);
    } else if (auth.loggedIn()) {
      new Api()
        .get("/api/users/" + new Api().getUserId())
        .then((c) => c.json())
        .then((c) => {
          setUserArtistID(c);
          new Api()
          .get("/api/artists/" + c.artists[0].artistID)
          .then((c) => c.json())
          .then((c) => {
          setState(c)
        });
    console.log(userArtistID);
    

          //console.log(c)
          let user = c;
          if (user.artists.length <= 0) {
            setContent("permition");
            setTimeout(() => {
              history.push("/requestartist/" + user.userID);
            }, 3000);
          }
          if (user.artists?.artistID != artistID) {
            //setContent("permition")
            setTimeout(() => {
              history.push("/requestartist/" + user.userID);
            }, 3000);
          } else {
            setContent("artistDetail");

          }
        });
    }
  }, []);

  // React.useEffect(() => {
  // }, [artistID]);
  console.log(state);
  
  return (
    <div className="">
      {content === "artistDetail" && (
        <div className="artist-panel-edit">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBack />
          </span>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item md={9}>
                <TextField
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  fullWidth
                  name="name"
                />
                <br />
                <br />
                <TextField
                  value={state.biography}
                  onChange={(e) =>
                    setState({ ...state, biography: e.target.value })
                  }
                  name="biography"
                  rows={7}
                  fullWidth
                  placeholder="Biography"
                  multiline
                />
                <DateTimePicker
                  value={state.releaseDate}
                  name="releaseDate"
                  lable="Birth Date"
                  onChange={(e: any) =>
                    setState({ ...state, releaseDate: e.target.value })
                  }
                  KeyboardButtonProps="Select Date"
                />
              </Grid>
              <Grid item md={3} style={{ padding: 30, textAlign: "center" }}>
                <input
                  onChange={(e) => {
                    if (e.target.files != null) {
                      const dt = { file: e.target.files[0] };
                      new Api()
                        .postWithFile("/api/artists/setAvatar/" + state.artistID, dt)
                        .then((c) => c.text())
                        .then((c: any) => {
                          if (c == "true") {
                            alert("Avatar Have Been Set");
                          }
                        });
                    }
                  }}
                  name="avatar"
                  ref={avatar}
                  type="file"
                  hidden
                />
                <img
                  onClick={() => {
                    avatar.current.click();
                  }}
                  className="s-create-artist-preview"
                  ref={preview}
                  src={"/api/file/images/artist/" + state.artistID + ".jpg"}
                  alt=""
                />
              <span
                onClick={() => {
                  avatar.current.click();
                }}
                className="change-cover-text artist-dateils-edit-text"
              >
                Change Profile image 
              </span>
              <span
                onClick={() => {
                  header.current.click();
                }}
                className="change-cover-text artist-dateils-edit-text"
              >
                Change Header image 
              </span>
                <input
                 hidden
                  type="file"
                  ref={header}
                  name="header"
                  onChange={(e) => {
                    if (e.target.files != null) {
                      const dt = { file: e.target.files[0] };
                      new Api()
                        .postWithFile("/api/artists/setHeader/" + state.artistID, dt)
                        .then((c) => c.text())
                        .then((c: any) => {
                          if (c == "true") {
                            alert("Header Have Been Set");
                          }
                        });
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </form>
        </div>
      )}
      {content === "permition" && (
        <div className="permition">
          You don't have permition to see this page.
        </div>
      )}
    </div>
  );
  function handleSubmit(e: any) {
    e.preventDefault();
    new Api()
      .put("/api/artists/" + state.artistID, { body: JSON.stringify(state) })
      .then((c) => c.ok)
      .then((c) => {
        if (c) alert("Done");
      });
  }
};

export default ArtistDetails;

import * as React from "react";
import "./Artist.scss";
import MusicRowItem from "Components/MusicRow/MusicRowItem";
import MusicGrid, { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import Api from "Api";
import HotMusic from "Components/MusicGrid/HotMusic";
import HorizontalVideo, {
  HorizontalVideoItem,
} from "Components/HorizontalVideo/HorizontalVideo";
import HorizontalAlbum, {
  HorizontalAlbumItem,
} from "Components/HorizontalAlbums/HorizontalAlbum";
import { useParams } from "react-router-dom";
import { PodcastGridItem } from "Components/PodcastGrid/PodcastGrid";
import PodcastRowItem from "Components/PodcastRow/PodcastRowItem";
import Loading from "Components/Loading/Loading";
import { PlayerContainer } from "Context/Contexts";
import AuthService from "AuthService";
export interface DjProps {}

const Dj: React.SFC<DjProps> = () => {
  const [isFollowed, setIsFollowed] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>({
    tracks: [],
    dj: {},
  });
  const player = PlayerContainer.useContainer();
  const djID = useParams();
  React.useEffect(() => {
    new Api()
      .get("/api/djs/main/" + djID)
      .then((c) => c.json())
      .then((c) => {
        setData(c);
        setLoading(false);
      });
  }, [djID]);
  React.useEffect(() => {
    new Api()
      .get("/api/djs/isFollowed/" + djID)
      .then((c) => c.text())
      .then((c: any) => {
        setIsFollowed(c == "true");
      });
  }, [djID]);
  return (
    <div className="container" id="artist">
      <DjHeader />
      {data.tracks.length > 0 ? <Tracks /> : ""}
    </div>
  );
  function DjHeader() {
    return loading ? (
      <Loading />
    ) : (
      <div id="header">
        <div
          style={{
            backgroundImage: 'url("/api/file/images/dj/header/' + djID + '.jpg")',
          }}
          id="image"
        >
          <div className="info">
            <div className="name">
              <h1>{data.dj.name}</h1>
            </div>
            <div className="statistics">
              <div>
                <span className="number">{data.dj?.followerCount ?? 0}</span>
                <span className="title"> Followers </span>
              </div>
              <div>
                <span className="number">{data.dj?.playCount ?? 0} </span>
                <span className="title">Plays</span>
              </div>
            </div>
            <div className="shuffle">
              {isFollowed == true ? (
                <button
                  onClick={() => {
                    if (!new AuthService().loggedIn()) {
                      player.setLogin(true);
                      return;
                    }
                    new Api()
                      .post("/api/follows", {
                        body: JSON.stringify({
                          djID: djID,
                        }),
                      })
                      .then((c) => c.text())
                      .then((c) => {
                        if (c == "2") {
                          data.dj.followerCount = data.dj?.followerCount ?? -1;
                          setData(data);
                          setIsFollowed(false);
                        }
                      });
                  }}
                  className="follow unfollow"
                >
                  UnFollow
                </button>
              ) : (
                <button
                  onClick={() => {
                    if (!new AuthService().loggedIn()) {
                      player.setLogin(true);
                      return;
                    }
                    new Api()
                      .post("/api/follows", {
                        body: JSON.stringify({ djID: djID }),
                      })
                      .then((c) => c.text())
                      .then((c) => {
                        if (c == "1") {
                          data.dj.followerCount =
                            data.dj?.followerCount ?? 0 + 1;
                          setData(data);
                          setIsFollowed(true);
                        }
                      });
                  }}
                  className="follow"
                >
                  Follow
                </button>
              )}
              <button
                onClick={(e) => {
                  player.setPodcasts([
                    ...data.tracks.sort(() => 0.5 - Math.random()),
                  ]);
                  player.setMode("podcast")
                  player.setIndex(0);
                }}
              >
                <span className="fa fa-random"></span> Play Shuffle
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Tracks() {
    return (
      <div className="music-grid">
        <div className="info">
          <h2>Tracks </h2>
          <a href="javascript:">See all</a>
        </div>
        <div className="tracks">
          {data.tracks.map((c: any, index: number) => (
            <PodcastRowItem key={index} data={data.tracks} index={index} />
          ))}
        </div>
      </div>
    );
  }
};

export default Dj;

import * as React from "react";
import "./PodcastRowItem.scss";
import { PlayerContainer } from "Context/Contexts";
import { Link, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import Podcasts from "Views/Podcasts/Podcasts";
export interface PodcastRowItemProps {
  data: any;
  index: number;
}

const PodcastRowItem: React.SFC<PodcastRowItemProps> = (
  props: PodcastRowItemProps
) => {
  const player = PlayerContainer.useContainer();
  // const [id, loading] = player.loading;
  // React.useEffect(() => {
  //   player.audio.onloadstart = (e) => {
  //     if (
  //       player.podcasts[player.index]?.podcastID ==
  //       props.data[props.index]?.podcastID
  //     ) {
  //       setLoading(true);
  //     }
  //   };
  //   player.audio.onplay = () => {
  //     if (
  //       player.podcasts[player.index]?.podcastID ==
  //       props.data[props.index]?.podcastID
  //     ) {
  //       setLoading(false);
  //     }
  //   };
  // }, [
  //   player.podcasts[player.index]?.podcastID ==
  //     props.data[props.index]?.podcastID,
  // ]);
  return (
    <div className="podcast-row-item">
    <Link to={"/playpodcast/" + props.data[props.index]?.podcastID}>
      <img
        onError={(e: any) => {
          e.target.src = "/api/file/images/placeholder.jpg";
        }}
        src={
          "/api/file/images/podcast/" +
          props.data[props.index]?.podcastID +
          ".jpg"
        }
        alt=""
      />
    </Link>
      <span className="name">
        {(props.data[props.index]?.podcastDjs ?? []).map((c: any,index:any) => (
          <Link key={index} to={"/dj/" }>{} </Link> 
        ))}
      </span>
      <span className="title">
        <Link to={"/playpodcast/" + props.data[props.index]?.podcastID}>
          {props.data[props.index]?.title?.substring(
            0,
            props.data[props.index]?.title?.length > 27
              ? 14
              : props.data[props.index].title.length
          )}
          {props.data[props.index]?.title?.length > 30 ? "..." : ""}
        </Link>
      </span>
      {/* {loading && id == props.data[props.index]?.podcastID ? ( */}
      {false == props.data[props.index]?.podcastID ? (
        <i className="loader">
          <Loader type="Bars" color="orange" height={50} width={50} />
        </i>
      ) : (
        <i
          onClick={() => {
            player.setMode("podcast");

            if (
              player.play == "pause" &&
              player.podcasts[player.index]?.podcastID ==
                props.data[props.index].podcastID
            ) {
              player.setPlay("play");
            } else {
              player.setMusics([]);
              player.setPodcasts(props.data);
              player.setIndex(props.index);
              setTimeout(() => {
                player.setPlay("pause");
              }, 100);
            }
          }}
          className={
            "fas fa-" +
            (player.podcasts[player.index]?.podcastID ==
            props.data[props.index]?.podcastID
              ? player.play
              : "play")
          }
        ></i>
      )}
    </div>
  );
};

export default PodcastRowItem;

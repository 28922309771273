import * as React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
  TextField,
} from "@material-ui/core";
import Api from "Api";

export interface SelectGenreProps {
  value?: any;
  onChange: any;
}

const SelectGenre: React.SFC<SelectGenreProps> = React.memo(
  (props: SelectGenreProps) => {
    const [genres, setGenres] = React.useState<any>([]);
    const [fullGenres, setFullGenres] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
      new Api()
        .get("/api/genres")
        .then((c) => c.json())
        .then((c) => {
          let w = c.map((x: any) => {
            if (
              props.value != undefined &&
              props.value.some((w: any) => {
                return x.genreID == w.genreID;
              })
            )
              return { ...x, selected: true };
            else return { ...x, selected: false };
          });
          setGenres(w);
          setFullGenres(w);
          setLoading(false);
        });
    }, [props.value]);

    return loading ? (
      <CircularProgress />
    ) : (
      <Grid id="select-genre" container direction="column">
        <Typography>Select Genre</Typography>

        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value == "") {
              setGenres(fullGenres);
            } else {
              let s = genres.filter((w: any) =>
                w.name.toLowerCase().includes(e.target.value)
              );
              setGenres([...s]);
            }
          }}
          label="Search Genre"
        />
        <Grid id="genre-list" container wrap="nowrap" direction="column">
          {genres.map((c: any, index: number) => (
            <GenreOption {...{ ...c, index }} onChange={props.onChange} />
          ))}
        </Grid>
      </Grid>
    );

    function GenreOption(props: any) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              value={props.genreID}
              checked={props.selected}
              onChange={(e) => {
                let s = [...genres];
                if (e.target.checked) {
                  s[props.index].selected = true;
                  setGenres(s);
                } else {
                  s[props.index].selected = false;
                  setGenres(s);
                }
                props.onChange(s.filter((w) => w.selected));
              }}
            />
          }
          label={props.name}
        />
      );
    }
  }
);

export default SelectGenre;

import React from 'react';
import { Link } from 'react-router-dom';
import "./NotFound.scss";
import NotFoundImage from "Assets/Images/404-25-music.png";



const NotFound = () => (
  <div className="container">
  <div className="page-notfound-div">
  <img src={NotFoundImage} alt="" />
  <p>
  The page you requested does not exist
  </p>
  <p>
  You can refer to the following sections to continue.
  </p>
    <span>
    <Link to="/">Go Home</Link>
    <Link to="/musics">Go Musics</Link>
    <Link to="/musicvideos">Go Videos</Link>
    <Link to="/podcasts">Go Podcasts</Link>
    <Link to="/news">Go News</Link>
    <Link to="/dashbord">Go Dashbord</Link>
    </span>
  </div>
  </div>
);
export default NotFound;

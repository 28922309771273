import React, { useEffect, useState } from "react";
import Api from "Api";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import Loading from "Components/Loading/Loading";

export default function MyMusics() {
  const [musics, setmusics] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    new Api()
      .get("/api/savedMusics/musics")
      .then((c) => c.json())
      .then((c) => {
        setmusics(c);
        setloading(false);
      });
  }, []);
  document.getElementById("close-btn").style.display = "none";

  return (
    <div style={{ marginTop: 50, color: "white" }} className="container">
      <h1>My Saved Musics</h1>
      {loading ? (
        <Loading />
      ) : (
        <div className="music-grid">
          <div className="tracks">
            {musics.map((c, index) => (
              <MusicGridItem
                width={23.5}
                data={musics}
                index={index}
                //   onPlay={() => {
                //     window.scroll({ top: 0 });
                //     history.push("/playmusic/" + c.musicID);
                //   }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";

export interface DatePickerProps {
  value: string;
  onChange: any;
  name: String;
  lable: String;
  KeyboardButtonProps?: string;
}

export const DatePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        onChange={(e: any) => {
          e = { ...e, target: { name: props.name, value: e } };
          props.onChange(e);
        }}
        name={props.onChange}
        fullWidth
        value={props.value}
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        label={props.lable}
        KeyboardButtonProps={{
          "aria-label": props.KeyboardButtonProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export const DateTimePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        onChange={(e: any) => {
          e = { ...e, target: { name: props.name, value: e } };
          props.onChange(e);
        }}
        name={props.onChange}
        fullWidth
        value={props.value}
        variant="inline"
        format="MM/dd/yyyy  HH:mm"
        margin="normal"
        label={props.lable}
        KeyboardButtonProps={{
          "aria-label": props.KeyboardButtonProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export const TimePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        onChange={(e: any) => {
          e = { ...e, target: { name: props.name, value: e } };
          props.onChange(e);
        }}
        name={props.onChange}
        fullWidth
        value={props.value}
        variant="inline"
        format="HH:mm"
        margin="normal"
        label={props.lable}
        KeyboardButtonProps={{
          "aria-label": props.KeyboardButtonProps
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

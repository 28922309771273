import React, { useEffect, useState } from "react";
import "./ArtistPanel.scss";
import MusicList from "Views/ArtistPanel/MusicList";
import { Route, useHistory } from "react-router-dom";

import CreateMusic from "./CreateMusic";
import Api from "Api";
import EditMusic from "./EditMusic";
import Upload from "Components/Upload/Upload";
import ArtistSocial from "./ArtistSocial";
import ArtistDetails from "./ArtistDetails";
const ArtistPanel = () => {
  const [artist, setArtist] = useState({});
  const [artistSocials, setArtistSocials] = useState({});
  const history = useHistory();
  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });

    new Api()
      .get("/api/artistPanel/me")
      .then((c) => c.json())
      .then((c) => {
        setArtist(c);
        localStorage.setItem(
          "a",
          JSON.stringify({ artistID: c.artistID, name: c.name })
        );
      })
      .catch(() => history.replace("/"));
  }, []);

  // useEffect(() => {
  //   new Api()
  //     .get("api/Socials/all/" + artist.artistID)
  //     .then((c) => c.json())
  //     .then((c) => {
  //       setArtistSocials(c);
  //       console.log(artistSocials);
  //     });
  // });

  useEffect(() => {
    window.document.body.style.background =
      "linear-gradient(to bottom," + "#a95926 " + " 0%, #000 99vh) ";
  }, []);
  return (
    <div className="container">
      <script src="https://use.fontawesome.com/c1b6d99215.js"></script>

      <div className="artist-panel">
        <div className="artist-detail-row">
          <div className="left">
            <div className="artist-info-div">
              <img
                onError={(e) => {
                  e.target.src = "/api/file/images/placeholder.jpg";
                }}
                src={"/api/file/images/artist/" + artist.artistID + ".jpg"}
                alt=""
              />
              <div>
                <span
                  onClick={() => {
                    history.push("/artistPanel/");
                  }}
                  className="s-close-icon artist-dateils-edit-text"
                >
                  Add Music or Video
                  <i className="fas fa-edit"></i>
                </span>
                <span
                  onClick={() => {
                    history.push(
                      "/artistPanel/artistDetails/" + artist.artistID
                    );
                  }}
                  className="s-close-icon artist-dateils-edit-text"
                >
                  Edit Artist Details
                  <i className="fas fa-edit"></i>
                </span>
                <span
                  onClick={() => {
                    history.push(
                      "/artistPanel/artistsocials/" + artist.artistID
                    );
                  }}
                  className="s-close-icon artist-dateils-edit-text"
                >
                  Edit Artist Socials
                  <i className="fas fa-edit"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="middle">
            <div id="ArtistPanelHeader">
              <div
                className="artist-profile-header"
                style={{
                  backgroundImage:
                    'url("/api/file/images/artist/header/' +
                    artist.artistID +
                    '.jpg")',
                }}
                id="image"
              >
                <div className="info">
                  <div className="name">
                    <h1>
                      {artist.name}{" "}
                      {(artist.isVerified ?? false) == true && (
                        <i className="fa fa-check-circle"></i>
                      )}
                    </h1>
                  </div>
                  <div className="statistics">
                    <div>
                      <span className="number">
                        {" "}
                        {artist.followerCount ?? 0}
                      </span>
                      <span className="title"> Followers </span>
                    </div>
                    <div>
                      <span className="number">{artist.playCount ?? 0} </span>
                      <span className="title">Plays</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right">
          </div> */}
        </div>
        <div className="artist-info-div">
          <span>
            <h2>Welcome To {artist.name}'s Artist Panel </h2>
            <h5>Upload Your Music And MusicVideo </h5>
          </span>
        </div>

        <Route path="/artistPanel/createmusic" exact>
          <CreateMusic artist={artist.artistID} />
        </Route>
        <Route
          path="/artistPanel/editMusic/:musicID"
          exact
          component={EditMusic}
        />
        <Route
          path="/artistPanel/error"
          component={() => {
            return (
              <div>
                <h3>404 Not Found</h3>
              </div>
            );
          }}
        />
        <Route path="/artistPanel" exact component={MusicList} />
        <Route
          path="/artistPanel/uploadmusic/:musicID/:video"
          exact
          component={Upload}
        />
        <Route
          path={"/artistPanel/artistsocials/" + artist.artistID}
          exact
          component={ArtistSocial}
        />
        <Route
          path={"/artistPanel/artistDetails/" + artist.artistID}
          exact
          component={ArtistDetails}
        />
      </div>
    </div>
  );
};

export default ArtistPanel;

import React, { useState, useCallback } from "react";
import Carousel from "nuka-carousel";
import { useHistory } from "react-router-dom";
import Queue from "Components/Queue/Queue";
import Api from "Api";
import "./Slider.scss";
var qu = new Queue();

const colors = ["7732bb", "047cc0", "00884b", "e3bc13", "db7c00"];
// eslint-disable-next-line complexity
const Slider = () => {
	const history = useHistory();

	const [items, setItems] = React.useState([]);
	const ref = React.useRef(null);
	React.useEffect(() => {
		window.scroll({ top: 0 });

		new Api()
			.get("/api/sliders")
			.then((c) => c.json())
			.then((c) => {
				setItems(c);
			});

		// new Api()
		// 	.get("/api/musics/" + items[0]?.refID)
		// 	.then((c) => c.json())
		// 	.then((c) => {
		// 		setTypeInfo(c);
		// 	});
	}, []);
	//console.log(items);
	React.useEffect(() => {
		setInterval(() => {
			if (!qu.isEmpty()) {
				qu.enqueue(qu.dequeue());

				setItems([...qu.items]);
				void ref.current?.offsetWidth;
				// ref.current?.classList.remove("animate");
			}
		}, 9000);
	}, []);

	const [animation, setAnimation] = useState(undefined);
	const [heightMode, setHeightMode] = useState("max");
	const [length, setLength] = useState(colors.length);
	const [scrollMode, setScrollMode] = useState("remainder");
	const [slideIndex, setSlideIndex] = useState(0);
	const [slidesToScroll, setSlidesToScroll] = useState(1);
	const [slidesToShow, setSlidesToShow] = useState(1);
	const [transitionMode, setTransitionMode] = useState("scroll");
	const [underlineHeader, setUnderlineHeader] = useState(false);
	const [withoutControls, setWithoutControls] = useState(false);
	const [wrapAround, setWrapAround] = useState(true);
	const [zoomScale, setZoomScale] = useState(0.5);

	const handleImageClick = useCallback(() => {
		setUnderlineHeader((prevUnderlineHeader) => !prevUnderlineHeader);
	}, []);

	const handleZoomScaleChange = useCallback((event) => {
		setZoomScale(event.target.value);
	}, []);

	const renderTopControls = (currentSlide) => {
		return (
			<div
				key={items.sliderID}
				style={{
					fontFamily: "Helvetica",
					color: "#fff",
					textDecoration: underlineHeader ? "underline" : "none",
				}}></div>
		);
	};

	const [typeInfo, setTypeInfo] = React.useState([]);

	const slides = items.slice(0, length).map(
		(item, index) => (
			(window.document.body.style.background =
				"linear-gradient(to bottom," +
				(items[0].dominantColor ?? "#e57833") +
				" 0%, #000 90vh) "),
			(
				<div className="slider" key={index}>

					<img
						ref={ref}
            			id={"slider-img"}
						className={"slide-img"}
						src={"/api/file/images/slider/" + item.sliderID + ".jpg"}
						alt={`Slide ${index + 1}`}
						key={item}
						onClick={() => {
							if (item.type) {
								switch (item.type.toLowerCase()) {
									case "music":
										history.push("/playMusic/" + item.refID);
										break;
									case "video":
										history.push("/playVideo/" + item.refID);
										break;
									case "album":
										history.push("/album/" + item.refID);
										break;
									case "collection":
										history.push("/collection/" + item.refID);

										break;
									case "ad":
										history.push("/add/" + item?.refID);
										break;
									case "artist":
										history.push("/artist/" + items[0]?.refID);
										break;
									case "podcast":
										history.push("/playpodcast/" + item?.refID);

										break;

									case "podcastCollection":
										history.push("/podcastCollection/" + item?.refID);

										break;

									case "dj":
										history.push("/dj/" + item?.refID);
										break;
								}
							}
						}}
						style={{
							borderRadius: "12px",
							height: heightMode === "current" ? 100 * (index + 1) : '80%',
						}}
					/>
					<div className="slider-titles">

          				<span>
							<p>{item.titleOne}</p>
							<p>{item.titleTwo}</p>
						</span>
						<span
							style={{
								backgroundColor: item.dominantColor,
							}}
							className="type">
							{item.type}
						</span>

					</div>
				</div>
			)
		)
	);
	return (
		<>
			<div className="slider slider-top" style={{ width: "100%", margin: "auto" }}>
				<Carousel
					animation={"zoom"}
					speed={"920"}
					autoplayInterval={"4500"}
					autoplay
					wrapAround={true}
					afterSlide={(data) => {
						window.document.body.style.transition = "background 0.5s ease all";
						window.document.body.style.backgroundAttachment = "fixed";
						window.document.body.style.background =
							"linear-gradient(to bottom," +
							(items.slice(0, length)[data].dominantColor ?? "#e57833") +
							" 0%, #000 90vh) ";
					}}
					transition
					
					cellAlign={"center"}
					pauseOnHover={false}
					renderAnnounceSlideMessage={({ currentSlide, slideCount }) => {
						return `Showing slide ${currentSlide + 1} of ${slideCount}`;
					}}
					renderTopCenterControls={({ currentSlide }) =>
						renderTopControls(currentSlide)
					}>
					{slides}
				</Carousel>
			</div>
		</>
	);
};
export default Slider;

import React, { useState, useEffect } from "react";
import "./PlayLists.scss";
import { HorizontalPlayListItem } from "Components/HorizontalPlayList/HorizontalPlayList";
import Api from "Api";
export default function PlayLists() {
  const [collections, setCollections] = useState([]);
  useEffect(() => {
    new Api()
      .get("/api/collections")
      .then((c) => c.json())
      .then((c) => setCollections(c));
  }, []);
  return (
    <div id='playlists' className="container horizontal-playlist">
      <div className="title">
        <h1>PlayLists</h1>
      </div>
      <div className="playlists">
        {collections.map((c,index) => (
          <HorizontalPlayListItem key={index} {...c} />
        ))}
      </div>
    </div>
  );
}

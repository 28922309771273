/* eslint-disable eqeqeq */
import * as React from "react";
import { TextField, Grid, Chip } from "@material-ui/core";

export interface SelectKeywordProps {
  value?: any;
  onChange: any;
}

const SelectKeyword: React.SFC<SelectKeywordProps> = (
  props: SelectKeywordProps
) => {
  const [keywords, setKeywords] = React.useState<any>([]);
  React.useMemo(() => {
    if (props.value != undefined) {
      let s = props.value.map((c: any) => c.keywordText);
      setKeywords(s);
    }
  }, [props.value]);
  return (
    <Grid container direction="row">
      <Grid item md={4} sm={12} xs={12}>
        <TextField
          onKeyUp={(e: any) => {
            e.preventDefault();
            if (e.key == "Enter") {
              setKeywords([...keywords, e.target["value"]]);
              props.onChange([...keywords, e.target["value"]]);
              e.target.value = "";
            }
          }}
          variant="outlined"
          label="Keyword"
        />
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Grid direction="row" wrap="wrap">
          {keywords.map((c: any, index: number) => (
            <Chip
              variant="outlined"
              color="primary"
              key={index}
              label={c}
              onDelete={() => {
                keywords.splice(index, 1);
                setKeywords([...keywords]);
                props.onChange([...keywords]);
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectKeyword;

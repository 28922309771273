/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import "Assets/pep.scss";
import { useForm } from "userForm";
import SelectLanguage from "Components/ComboBox/Language/SelectLanguage";
import { DateTimePicker } from "Components/DatePicker/DatePicker";
import Api from "Api";
import SelectArtist from "Components/ComboBox/Artist/SelectArtist";
import { Divider } from "@material-ui/core";
import SelectGenre from "Components/ComboBox/genre/SelectGenre";
import SelectType from "Components/ComboBox/Type/SelectType";
import SelectKeyword from "Components/ComboBox/Keyword/SelectKeyword";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import Notification from "Components/Notification/Notification";
export interface MusicProps {}

const CreateMusic: React.SFC<MusicProps> = React.memo(() => {
  let history = useHistory();
  const { albumID } = useParams<any>();
  const [notify, setNotify] = React.useState(false);
  const [me, setme] = React.useState<any>();
  const [myArtist, setMyArtist] = React.useState<any>([]);
  const [music, setMusic] = React.useState({}); 

  let [form, handleChange] = useForm({
    languageID: "",
    releaseDate: new Date(),
    showArchive : true,
    isConfirmed : false,
   //MusicArtists : ""
  });
 function getMe() {
  new Api()
  .get("/api/artistPanel/me")
  .then((c) => c.json())
  .then((c) => {
    setMusic({...music,MusicArtists : [{ artistID:c.artistID, musicID: 0 }]});
  });

 }

 
  
  React.useEffect(() => {
    if (albumID != undefined) setMusic({ ...music, albumID });
    
  }, [albumID]);

  React.useEffect(() => {
    getMe();
  },[]);
  
  console.log("form",{ ...music, ...form });
  console.log("music",music);

  return (
    <div id="music">
      <Grid container direction="column" md={6}>
      <span
        style={{width: "100%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between"}}
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBack />
        <p style={{marginLeft: "20px"}}>
        Add your new Music OR Music Video details
        </p>
      </span>
        <br />
        <TextField
          autoComplete="Off"
          label="title"
          name="title"
          onBlur={(e) => {
            setMusic({ ...music, title: e.target.value });
            if (me) {
            let f: any = [];
            let s: any = [];
            f.map((w: any) => s.push({ artistID: me.artistID, musicID: 0 }));
             setMusic({ ...music, MusicArtists: s });
            }

          }}
        />
        <br />
        <TextField
          multiline
          label="lyrics"
          name="lyrics"
          variant="outlined"
          onBlur={(e) => {
            setMusic({ ...music, lyrics: e.target.value });
          }}
        />
        <br />
        <SelectLanguage value={form.languageID} onChange={handleChange} />

        <br />
        <SelectGenre
          onChange={(c: any) => {
            let s: any = [];
            c.map((w: any) => s.push({ genreID: w.genreID, musicID: 0 }));
            setMusic({ ...music, GenreMusics: s });
          }}
        />
        <br />
        <SelectArtist
        value={[{artistID : 3009}]}
          onChange={(e: any) => {
            let s: any = [];
            e.map((w: any) => s.push({ artistID: w.artistID, musicID: 0 }));
             setMusic({ ...music, MusicArtists: s });
             
          }}
        />
        <br />
        <SelectType
          onChange={(c: any) => {
            let s: any = [];
            c.map((w: any) => s.push({ typeID: w.typeID, musicID: 0 }));
            setMusic({ ...music, MusicTypes: s });
                        if (me) {
            let f: any = [];
            let s: any = [];
            f.map((w: any) => s.push({ artistID: me.artistID, musicID: 0 }));
             //setMusic({ ...music, MusicArtists: s });
            }
          }}
        />
        <br />
        <Divider />
        <DateTimePicker
          value={form.releaseDate}
          name="releaseDate"
          lable="Release Date"
          onChange={handleChange}
          KeyboardButtonProps="Select Date"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              name="isCommingSoon"
              onChange={handleChange}
              value={form.isCommingSoon}
            />
          }
          label="Is Comming Soon??"
        />
        <br />

        <FormControlLabel
          control={
            <Checkbox
              name="hasVideo"
              onChange={handleChange}
              value={form.isExlusive}
            />
          }
          label="Is it music Video??"
        />
        <br />
        <Notification
          text="Error Occured"
          show={notify}
          closeNotification={() => {
            setNotify(false);
          }}
        />
        <SelectKeyword
          onChange={(e: any) => {
            let w: any = [];
            e.map((c: any) => {
              w.push({ keywordText: c });
            });

            setMusic({ ...music, keywords: w });
          }}
        />
        <br />
        <br />
        <Button
          style={{ maxWidth: 150 }}
          type="button"
          variant="contained"
          color="primary"
          onClick={createMusic}
        >
          Create
        </Button>
      </Grid>
    </div>
  );
  function createMusic() {
    new Api()
      .post("/api/Musics", {
        body: JSON.stringify({
          ...form,
          ...music,
          releaseDate: form.releaseDate.toJSON(),
        }),
      })
      .then((c) => c.ok)
      .then((c) => {
        if (c) {
          history.goBack();
        } else {
          showErrorNotification();
        }
      });
  }
  function showErrorNotification() {
    setNotify(true);
    setTimeout(function () {
      setNotify(false);
    }, 1500);
  }
});

export default CreateMusic;

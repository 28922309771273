import decode from "jwt-decode";
import Api from "Api";
export default class AuthService {
  // Initializing important variables
  constructor() {
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getUsername = this.getUsername.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.setToken = this.setToken.bind(this);
  }
  tokenName = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9";
  login(user) {
    return new Api()
      .post("/api/users/login", {
        body: JSON.stringify(user),
      })
      .then((c) => c.json())
      .then((res) => {
        // var w = decode(res.data);
        if (res?.status == 2) {
          this.setToken(res.data);
          // Setting the token in localStorage
          return { ok: true, status: res.status };
        } else {
          return { ok: false, status: res.status };
        }
        //return Promise.resolve(res.data);
      })
      .catch((c) => alert("Error"));
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return token && !this.isTokenExpired(token); // handwaiving here
  }
  isAdmin() {
    const token = this.getToken(); // GEtting token from localstorage
    const decoded = decode(token);
    if (decoded.groupID == "1") return true;
    else return false;
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (
        decoded.exp < Date.now() / 1000 &&
        decoded.hasOwnProperty(
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        )
      ) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      this.logout();
      return true;
    }
  }
  getUsername(decoded) {
    return decoded[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    ];
  }
  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem(this.tokenName, idToken);
  }
  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem(this.tokenName);
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem(this.tokenName);
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    // if (this.loggedIn()) {
    //   headers["Authorization"] = "Bearer " + this.getToken();
    // }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => {
        return response.json();
      });
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}

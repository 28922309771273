import * as React from "react";
import "./UserLayout.scss";
import logo from "Assets/Images/LogoWithName.png";

export interface UserLayoutProps {}

const UserLayout: React.SFC<UserLayoutProps> = (props: any) => {
  return (
    <div className="user-layout">
      {props.children}
    </div>
  );
};

export default UserLayout;

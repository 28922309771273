import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { PlayerContainer } from "Context/Contexts";
import PromptLoginLayout from "./PromptLoginLayout";
import Register from "Views/Register/Register";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Share(props) {
  const player = PlayerContainer.useContainer();

  const size = 50;
  return (
    <div id="share">
      <div>
        <Dialog
          open={player.login}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => player.setLogin(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
      <PromptLoginLayout />
          {/* <DialogTitle id="alert-dialog-slide-title">
            You Need To
            <a style={{ color: "orange" }} href="/register">
              Login
            </a>
            Or
            <a style={{ color: "orange" }} href="/register">
              Register
            </a>{" "}
            To Perform This Action!!
          </DialogTitle>
          <DialogContent>
         

          </DialogContent> */}
        </Dialog>
      </div>
    </div>
  );
}

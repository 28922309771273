import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import Loader from "react-loader-spinner";
import "./Loading.scss";

export interface LoadingProps {
  height?: number;
}

const Loading: React.SFC<LoadingProps> = (props: LoadingProps) => {


  return (
    <div
      style={{
        textAlign: "center",
        height: props.height ? props.height + "px" : "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader type="Bars" color="orange" height={120} width={120} />

      {/* <CircularProgress size={85} style={{  color: "orange" }} /> */}
    </div>
  );
};

export default Loading;

import { useState } from "react";

export const useForm = (initialValues: any) => {
  let [values, setValues] = useState(initialValues);
  return [
    values,
    (e: any) => {
      if (e == true) {
        for (const key in values) {
          if (values.hasOwnProperty(key)) {
            values[key] = "";
          }
        }
        setValues({ ...values });
      } else {
        setValues({
          ...values,
          [e.target.name]:
            e.target.type != "checkbox" ? e.target.value : e.target.checked
        }
        );
      }
    }
  ];
};

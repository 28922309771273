import * as React from "react";
import "./PlayVideo.scss";
import VideoPlayer from "Components/VideoPlayer/VideoPlayer";
import Comment from "Components/Comment/Comment";
import { useParams, useHistory, Link } from "react-router-dom";
import Api from "Api";
import { Fab } from "@material-ui/core";
import { ReactPlayerProps } from "react-player";
import Loader from "react-loader-spinner";
import { PlayerContainer } from "Context/Contexts";
import AuthService from "AuthService";
import { set } from "animejs";
import tvicon from "Assets/Images/tv.png";
import Marquee from "react-fast-marquee";

const PlayVideo = () => {
	let { musicID } = useParams();
	const [quality, setQuality] = React.useState(720);
	const [music, setMusic] = React.useState({});
	const [related, setRelated] = React.useState([]);
	const [liked, setLiked] = React.useState(false);
	const [likeLoading, setLikeLoading] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [relation, setRelation] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const player = PlayerContainer.useContainer();
	React.useEffect(() => {
		window.scrollTo({ left: 0, top: 0 });
		new Api()
			.get("/api/musics/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				//  console.log("cc",c) ;
				setMusic(c);
			});

		// new Api()
		// 	.post("/api/musics/play/" + musicID)
		// 	.then((c) => c.json())
		// 	.then((c) => {
		// 		//  console.log('play' , c);
		// 	});

		new Api()
			.post("/api/Musics/GetlikeState/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				setLiked(c.liked);
			});

		new Api()
			.get("/api/Musics/isSaved/" + musicID, {})
			.then((c) => c.json())
			.then((c) => {
				//   console.log('saved' , c);
				setSaved(c);
			});


			new Api()
			.get("/api/Musics/videoRelation/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				   console.log('rell' , c);
				setRelation(c);
			});
		


	}, [musicID]);

	//console.log(relation?.relations?.musicId);


	const ref = React.useRef(null);
	React.useEffect(() => {
		// console.log(music)
		window.document.body.style.background =
			"linear-gradient(to bottom," +
			(music.coverColor ?? "#000") +
			" 0%, #000 99vh) ";
		new Api()
			.get("/api/musics/related/video/" + musicID)
			.then((c) => c.json())
			.then((c) => setRelated(c));
	}, [music]);

	React.useEffect(() => {});

	const history = useHistory();

	const playNextVideo = () => {
		if (related.length > 0) {
			let musicIndex = Math.floor(Math.random() * related.length);
			if (related[musicIndex].musicID !== musicID) {
				history.push("/playVideo/" + related[musicIndex].musicID);
			} else {
				playNextVideo();
			}
		}
	};
	//document.title = "📽️" + music.title + " - " + music.artists?.join(", ") + " - Avang Music" ;
	return (
		<div id="play-video" className="container">
			<div id="left">
				<p className="prmoted-text">
					<img className="live-tv" src={tvicon} alt=""></img>
					Video Promoted By Avang TV
				</p>
				<VideoPlayer
					playNextVideo={playNextVideo}
					className={"videoplayer"}
					style={{
						border: "5px solid" + music.coverColor,
						borderTop: "45px solid" + music.coverColor,
					}}
					playing={true}
					ref={ref}
					onProgress={(e) => {}}
					height="480px"
					light={"/api/file/images/music/" + musicID + ".jpg"}
					url={"/music/video/" + quality + "/" + musicID + ".mp4"}
					config={{
						file: {
							attributes: {
								controlsList: "nodownload",
								onContextMenu: (e) => e.preventDefault(),
							},
						},
					}}
				/>
				<div className="info">
					<div className="title">
						<div className="name">
							<span>
								<h3>
									<span className="artist-name">
									<i className="fas fa-microphone"></i>
										<Marquee>
										{(music.musicArtists ?? []).map((d, index) => (
											<>
												<Link key={index} to={ "/artist/" + d.artistID +"/" + d?.artist.name.replace(  / /, "-" )  }>
													{d.artist.name}
												</Link>
												{index <= 0 && music.musicArtists.length > 1 ? " - " + " " : <span></span> }
											</>
											))}
										</Marquee>

									</span>
									<span className="music-name">
										<i className="fas fa-play"></i>
										<Marquee>{music?.title}</Marquee>
									</span>
								</h3>
							</span>
						</div>
						<div className="like-share">
							{
                        		relation?.relations?.musicVideoId > 1 ?

							<a href={
								window.location.protocol +
								"//" +
								window.location.hostname +
								"/playmusic/" + relation?.relations?.musicId
							}>
									<svg className="musicrelicon" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
										<g>
											<path fill="none" d="M0 0h24v24H0z"/>
											<path d="M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z"/>
										</g>
									</svg>
							</a>
                     		 : "" }

							{likeLoading ? (
								<Loader type="TailSpin" color="white" height={14} width={14} />
							) : (
								<i
									onClick={() => {
										if (!new AuthService().loggedIn()) {
											player.setLogin(true);
											return;
										}
										setLikeLoading(true);
										new Api()
											.post("/api/main/like", {
												body: JSON.stringify({
													musicID: musicID,
													value: 1,
												}),
											})
											.then((c) => c.text())
											.then((c) => {
												setLikeLoading(false);

												c == "1" ? setLiked(true) : setLiked(false);
											});
									}}
									className={
										liked == true ? "fas fa-heart red " : "far fa-heart"
									}></i>
							)}
							{saveLoading ? (
								<Loader type="TailSpin" color="white" height={14} width={14} />
							) : (
								<i
									onClick={() => {
										if (!new AuthService().loggedIn()) {
											player.setLogin(true);
											return;
										}
										setSaveLoading(true);
										new Api()
											.post("/api/savedMusics", {
												body: JSON.stringify({
													musicID: musicID,
												}),
											})
											.then((c) => c.text())
											.then((c) => {
												setSaveLoading(false);
												c == "true" ? setSaved(true) : setSaved(false);
											});
									}}
									className={
										(saved == true ? "fas orange " : "far") + " fa-bookmark"
									}></i>
							)}
							<i
								onClick={() =>
									player.setShare({
										url:
											window.location.protocol +
											"//" +
											window.location.hostname +
											"/playvideo/" +
											music?.musicID,
										show: true,
									})
								}
								className="fas fa-share-alt "></i>
							<i className="fas fa-info" i />
						</div>
						<div className="quality">
							<span
								className={"quality"}
								onClick={() => setQuality(1080)}
								className={"quality " + (quality == "1080" ? "active" : "")}>
								1080
							</span>
							<span
								className={"quality"}
								onClick={() => setQuality(720)}
								className={"quality " + (quality == "720" ? "active" : "")}>
								720
							</span>
							<span
								className={"quality"}
								onClick={() => setQuality(480)}
								className={"quality " + (quality == "480" ? "active" : "")}>
								480
							</span>
							<span
								className={"quality"}
								onClick={() => setQuality(360)}
								className={"quality " + (quality == "360" ? "active" : "")}>
								360
							</span>

							{/* <Fab
                onClick={() => setQuality(1080)}
                size="small"
                color="secondary"
              >
                1080
              </Fab>
              <Fab
                onClick={() => setQuality(720)}
                size="small"
                color="secondary"
              >
                720
              </Fab>
              <Fab
                onClick={() => setQuality(480)}
                size="small"
                color="secondary"
              >
                480
              </Fab>
              <Fab
                onClick={() => setQuality(360)}
                size="small"
                color="secondary"
              >
                360
              </Fab> */}
						</div>
					</div>
					<hr style={{ width: "100%" }} />
					<div className="statistics">
						<i className="fas fa-play"></i>
						<span>{music.playCount ?? 0}</span>
						<i style={{ color: "red" }} className="fas fa-heart"></i>
						<span>{music?.likeCount ?? 0}</span>
						<span>Release Date</span>
						<span>{new Date(music.releaseDate).toDateString()}</span>
					</div>
				</div>
			</div>

			<div className="right">
				<div className="video-grid">
					<div className="buttons">
						<span className="button" id="related-btn">
							Related
						</span>
						<span className="button" id="comment-btn">
							Comments
						</span>
					</div>
					<div
						className="tracks animate__animated animate__flipInY"
						id="related-grid">
						{/*<h1>Related Video</h1>*/}
						{related.map((c, index) => (
							<VideoItem key={index} data={c} />
						))}
					</div>
					<div
						className="show-comments animate__animated animate__flipInY"
						id="comment-grid">
						<Comment musicID={player.musics[player.index]?.musicID} />
					</div>
				</div>

				<div className="related-comment-btn">
					<i
						onClick={(e) => {
							document.getElementById("related-grid").style.display = "unset";
							document.getElementById("comment-grid").style.display = "none";
							document.getElementById("comment-btn").style.display = "none";
							document.getElementById("related-btn").style.display = "unset";
						}}
						id="related-i"
						className="fas fa-related">
						Related
					</i>
					<i
						onClick={(e) => {
							document.getElementById("comment-grid").style.display = "unset";
							document.getElementById("related-grid").style.display = "none";
							document.getElementById("related-btn").style.display = "none";
							document.getElementById("comment-btn").style.display = "unset";
						}}
						id="comment-i"
						className="fas fa-related">
						Comment
					</i>
				</div>
			</div>
		</div>
	);
};
function VideoItem(props) {
	const history = useHistory();
	//console.log(props.data.musicArtists?.artistID);
	return (
		<div>
			<div className="related-video-item">
				<div
					onClick={() => history.push("/playVideo/" + props.data.musicID)}
					className="image">
					<img
						onError={(e) => {
							e.target.src = "/api/file/images/video.jpg";
						}}
						src={`/api/file/images/music/${props.data.musicID}.jpg`}
						alt=""
					/>
				</div>
				<div className="info">
						<span className="name">
							<i className="fas fa-microphone"></i>{" "}
							<Marquee>{props.data.artists.join(", ")}</Marquee>
						</span>
					<span className="title">
						<i className="fas fa-play"></i>{" "}
						<Marquee>{props.data.title}</Marquee>
					</span>
					<span className="releaseDate">
						<i className="fas fa-calendar-check"></i>
						{new Date(props.data.releaseDate).toDateString()}
					</span>
				</div>
			</div>
		</div>
	);
}

export default PlayVideo;

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useParam } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import Marquee from "react-fast-marquee";
import Api from "Api";
import "./Videos.scss";
import Loading from "Components/Loading/Loading";
import { spawn } from "child_process";
export default function Videos() {
	const history = useHistory();
	const { show } = useParams();
	const [newVideos, setNewVideos] = useState([]);
	const [allVideos, setAllVideos] = useState([]);
	const [AllLoading, setAllLoading] = useState(false);
	const [dataLoading, setdataLoading] = useState(false);
	const [rightType, setRightType] = useState("10");
	const [leftType, setLeftType] = useState("newReleased");
	const [avangVideos, setavangVideos] = useState([]);
	const [top10, settop10] = useState([]);
	const [topMonth, setTopMonth] = useState([]);
	const [count, setCount] = useState(19);
	const [apiCount, setApiCount] = useState({
		take: 19,
		skip: 0,
	});

	const [data, setdata] = useState({ tops: [], hits: [] });
	useEffect(() => {
		window.scrollTo({ left: 0, top: 0 });

		setdataLoading(true);

		new Api()
			.get("/api/musics/web/videos")
			.then((c) => c.json())
			.then((c) => {
				setdataLoading(false);
				setdata(c);
			});
	}, []);
	useEffect(() => {
		setAllLoading(true);
		new Api()
			.get(
				`/api/Musics/newReleasedVideos?take=${apiCount.take}&skip=${apiCount.skip}`
			)
			.then((c) => c.json())
			.then((c) => {
				setAllVideos((prev) => {
					return [...prev, ...c];
				});
				setAllLoading(false);
			});
	}, [apiCount.take, apiCount.skip]);
	useEffect(() => {
		setAllLoading(true);
		new Api()
			.get(
				`/api/Musics/avangMusicVideos?take=${apiCount.take}&skip=${apiCount.skip}`
			)
			.then((c) => c.json())
			.then((c) => {
				setavangVideos((prev) => {
					return [...prev, ...c];
				});
				setAllLoading(false);
			});
	}, [apiCount.take, apiCount.skip]);
	useEffect(() => {
		switch (leftType) {
			case "newReleased":
				new Api()
				.get(
					`/api/Musics/newReleasedVideos?take=${apiCount.take}&skip=${apiCount.skip}`
				)
				.then((c) => c.json())
				.then((c) => {
					setAllLoading(false);
				});
					break;
			case "avangMusic":
				new Api()
				.get(
					`/api/Musics/avangMusicVideos?take=${apiCount.take}&skip=${apiCount.skip}`
				)
				.then((c) => c.json())
				.then((c) => {
					setAllLoading(false);
				});
					break;

			default:
				break;
		}
	}, [apiCount.take, apiCount.skip] , [leftType]);
//console.log(allVideos);

	// useEffect(() => {
	//   console.log(allVideos.length)
	//  setNewVideos(allVideos.splice(0,20))
	//   setNewloading(false);
	// }, [allVideos]);

	// useEffect(() => {
	//  allVideos.splice(0,20).map(video=>setNewVideos(prvVideos=>[...prvVideos,video])   )
	// }, [count]);

	useEffect(() => {
		switch (rightType) {
			case "10":
				new Api()
					.get("/api/Musics/top10Videos")
					.then((c) => c.json())
					.then((c) => {
						settop10(c);
					});
				break;
			case "month":
				new Api()
					.get("/api/Musics/topMonthVideos")
					.then((c) => c.json())
					.then((c) => {
						setTopMonth(c);
					});
				break;

			default:
				break;
		}
	}, [rightType]);


	return (
		<div className="container" id="videos-page">
			{AllLoading ? (
				<Loading />
			) : (
				<div className="tops">
					{data.tops
						.filter((w, index) => index < 3)
						.map((c, index) => (
							<div key={index} className="top">
								<div onClick={() => history.push("/playVideo/" + c?.musicID)}>
									<img
										onError={(e) =>
											(e.target.src = `/api/file/images/video.jpg`)
										}
										src={`/api/file/images/music/${c.musicID}.jpg`}
										alt=""
									/>
									<div className="overloady">
										<i className="fas fa-play"></i>
										<span className="name">
											{(c.musicArtists ?? []).map((c, index) => (
												<Link key={index} to={"/artist/" + c.artistID}>
													{c.artist.name}
												</Link>
											))}
										</span>
										<span className="title">
											<span
												onClick={() =>
													history.push("/playVideo/" + c?.musicID)
												}>
												{c?.title?.substring(
													0,
													c?.title?.length > 26 ? 25 : c.title.length
												)}
												{c?.title?.length > 25 ? "..." : ""}
											</span>
										</span>
									</div>
								</div>
							</div>
						))}
				</div>
			)}

			{data.hits.length > 0 && <h1>Hot Videos</h1>}
			<ScrollContainer hideScrollbars horizontal className="scroll-container">
				<div className="hits">
					{data.hits.map((c, index) => (
						<div key={index} className="hit">
							<div >
							<Link key={index} to={"/playvideo/" + c?.musicID}>
								<i className="video-play-icon fas fa-play"></i>
								<img
									onClick={() => history.push("/playVideo/" + c?.musicID)}
									onError={(e) => (e.target.src = `/api/file/images/video.jpg`)}
									src={`/api/file/images/music/${c.musicID}.jpg`}
									alt=""
								/></Link>
								<div className="titles">
									<span className="name">
									<i className="fas fa-microphone"></i>
										{(c.musicArtists ?? []).map((c, index) => (
											<Link key={index} to={"/artist/" + c.artist.artistID}>
              								<Marquee>
											  {c.artist.name}
											</Marquee>
											</Link>
										))}
									</span>
									<span className="title">
									<i className="video-title-icon fas fa-play"></i>
										<span
											onClick={() => history.push("/playVideo/" + c?.musicID)}>
              								<Marquee>
												{c?.title}
											</Marquee>
										</span>
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</ScrollContainer>
			<div className="video-page-row">
				<div className="video-page-left">
					{allVideos.length > 0 && (
						<div className="left-types">
							<span
								onClick={() => {
									setLeftType("newReleased");
								}}
								className={leftType == "newReleased" && "active"}>
								New Released
							</span>
							<span
								onClick={() => {
									setLeftType("avangMusic");
								}}
								className={leftType == "avangMusic" && "active"}>
								Avang Music
							</span>
						</div>
					)}
					<div className="new-videos">
					{(leftType == "newReleased" ? allVideos : avangVideos).map((c, index) => (

							<div>
								<div className="new-video">
								<Link key={index} to={"/playvideo/" + c?.musicID}>
									<i className="video-play-icon fas fa-play"></i>
									<img
										onClick={() => history.push("/playvideo/" + c?.musicID)}
										onError={(e) =>
											(e.target.src = `/api/file/images/video.jpg`)
										}
										src={`/api/file/images/music/${c.musicID}.jpg`}
										alt=""
									/>
									</Link>
									<div className="titles">
										<span className="name">
										{/*<Marquee>
											<Link  to={"/artist/" + c.musicArtists?.artistID}>
												{c?.artists.join(" , ")}
											</Link>
										</Marquee>*/}
										<i className="fas fa-microphone"></i>
										<Marquee>
										{(c.musicArtists ?? []).map((d, index) => (
											<>
												<Link key={index} to={"/artist/" + d.artistID}>
													{d.artist.name}
												</Link>
												{index <= 0 && c.musicArtists.length > 1 ? " - " : <span></span> }
											</>
											))}
										</Marquee>
										</span>
										<span className="title"
											onClick={() => history.push("/playvideo/" + c?.musicID)}
										>
										<i className="video-title-icon fas fa-play"></i>
											<Marquee>
												{c?.title}
											</Marquee>
										</span>
									</div>
								</div>
							</div>
						))}
						<div
							onClick={(e) => {
								setApiCount((prev) => {
									return {
										skip: prev.skip + 20,
										take: 20,
									};
								});
							}}
							className="more-videos">
							{AllLoading ? <span>loaidng...</span> : <span>See More</span>}
						</div>
					</div>
				</div>
				<div className="video-page-right">
					<div className="right-types">
						<span
							onClick={() => {
								setRightType("10");
							}}
							className={rightType == "10" && "active"}>
							Top 10
						</span>
						<span
							onClick={() => {
								setRightType("month");
							}}
							className={rightType == "month" && "active"}>
							Top Month
						</span>
					</div>

					<div className="top-videos">
						{(rightType == "10" ? top10 : topMonth).map((c, index) => (
							<div
								className="top-video"
								key={index}
								onClick={() => history.push("/playVideo/" + c?.musicID)}>
								<i className="video-play-icon fas fa-play"></i>
								<img
									onError={(e) => (e.target.src = `/api/file/images/video.jpg`)}
									src={`/api/file/images/music/${c.musicID}.jpg`}
									alt=""
								/>{" "}
								<div className="info">
									<span className="name">
										<i className="fas fa-microphone"></i>{" "}
										<Marquee>
											{(c.musicArtists ?? []).map((c, index) => (
												<Link key={index} to={"/artist/" + c.artistID}>
													{c.artist.name}
												</Link>
											))}
										</Marquee>
									</span>
									<span className="title">
										<i className="fas fa-play"></i>{" "}
										<Marquee>
											<span
												onClick={() =>
													history.push("/playvideo/" + c?.musicID)
												}>
												{c?.title?.substring(
													0,
													c?.title?.length > 21 ? 20 : c.title.length
												)}
												{c?.title?.length > 20 ? "..." : ""}
											</span>
										</Marquee>
									</span>
									<span className="releaseDate">
										<i className="fas fa-calendar-check"></i>
										{new Date(c?.releaseDate).toDateString()}
									</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

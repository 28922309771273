import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import "./TermsOfService.scss";
import { Button } from "@material-ui/core";

const TermsOfService = () => {
	return (
		<div className="container">
			<div className="privacy-container">
				<h1>Terms Of Service</h1>
  <p class=""  ><a href="https://avang.live/"><strong>www.avang.live</strong></a><strong>&nbsp;- WEBSITE AND APP TERMS OF USE</strong></p>
  <p class=""  >Dated: November 2021</p>
  <p class=""  ><strong>PLEASE READ THIS POLICY CAREFULLY BEFORE USING OUR WEBSITE OR APP</strong></p>
  <p class=""  >These terms and conditions ("Terms") form a legal agreement between you ("you") and Avang Music, Inc., a company registered in Delaware (No.&nbsp;5934467) with its registered office address at 160 Greentree Dr Ste 101, Dover, 19904, United States (“Avang”, "us", "we" or "our") relating to your use of:</p>
  <p class=""  >1. the website (with sub-sites such as run.avang.live), including all content and services made available on that website (the "Website"); and&nbsp;</p>
  <p class=""  >2. the desktop and/or mobile software applications known as 'Avang Music' and 'Avang Muisc', including all content and services made available on these software applications (the "App(s)").&nbsp;</p>
  <p class=""  >By accessing the Website or downloading an App, you agree to these Terms. If you do not agree to these Terms, you should not proceed to use the Website, or download or use an App.</p>
  <p class=""  >These Terms refer to and hereby incorporate the following additional policies, which also apply to your use of our Website and the Apps:</p>
  <p class=""  >1. our <a href="https://avang.live/privacy-policy/">Privacy Policy</a> which sets out the terms on which we process any personal data we collect from you, or that you or others provide to us.&nbsp;</p>
  <p class=""  >2. our <a href="https://avang.live/cookie-policy/">Cookie Policy</a> which sets out information about the cookies and similar technologies used by us on our Website and the Apps.&nbsp;</p>
  <p class=""  >AGREED TERMS</p>
  <p class=""  ><strong>1. GENERAL ACKNOWLEDGEMENTS</strong></p>
  <p class=""  >App Store Terms: The ways in which you can use the Apps may also be controlled by the rules and policies of an app store from which you download an App. You can find these rules and policies here:</p>
  <p class=""  >1. <a href="https://www.apple.com/ca/legal/internet-services/itunes/ca/terms.html">https://www.apple.com/ca/legal/internet-services/itunes/ca/terms.html</a></p>
  <p class=""  >2. <a href="https://play.google.com/about/play-terms.html">https://play.google.com/about/play-terms.html</a></p>
  <p class=""  >These rules and policies will apply instead of these Terms where there are differences between the two.</p>
  <p class=""  ><strong>Updates</strong>: From time to time we may automatically update an App and change the features, content or services of an App or the Website to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively, we may ask you to download an update to an App for these reasons. If you choose not to install such updates, if you opt out of automatic updates or if you do not agree to any additional terms which apply to such update or new service, you may not be able to continue using the Apps or the Website.</p>
  <p class=""  ><strong>Changes to Terms: </strong>We may need to change these Terms to, by way of example, reflect changes in law or best practice or to deal with additional features, services, subscriptions or applications which we introduce. Where the changes increase your obligations or limit your rights (e.g. introducing further restrictions on your use of an App) we will give you notice in advance of such changes by sending you an email (if we have an email address on record) or by notifying you of a change when you next start the App as applicable. If you do not accept the notified changes, you must let us know immediately in writing by emailing us at the address set out in section 10 “Contact” of these Terms. In such case, you will not be permitted to continue to use the Website and/or any of the Apps. If you have paid for a subscription account, you may apply for a refund in respect of fees paid for such period after cancellation.</p>
  <p class=""  ><strong>Permission of device owner</strong>: If you use an App from a computer, phone or other device which is not owned by you, you must have the owner's permission to do so. You will be responsible for complying with these Terms, whether or not the device is owned by you.</p>
  <p class=""  ><strong>Websites you link to</strong>: The Website and the Apps may contain links to other independent third-party websites ("Third-party Sites"). Third-party Sites are not under our control, and we are not responsible for and do not endorse their content or their privacy policies (if any). You will need to make your own independent judgment regarding your interaction with any Third-party Sites, including the purchase and use of any products or services accessible through them.</p>
  <p class=""  ><strong>Music and content in the Apps</strong>: The Apps may include musical Content which can adapt and change automatically through the application of our proprietary software. When you use the Apps, we grant you a non-exclusive, revocable and limited licence to listen to the music Content for personal, non-commercial purposes. You agree that you will not copy, redistribute, publicly perform or otherwise exploit musical Content included in the Apps without our permission.</p>
  <p class=""  ><strong>2. YOUR APP ACCOUNT</strong></p>
  <p class=""  >In order to create an account to use our Apps and access the Content, you must be 18 or over or 13 or older with consent from a parent or guardian to your use of our Apps and Content and consent to these Terms and be resident in a country from which the Apps and/or Content are accessible to download.</p>
  <p class=""  >You may be required to create an account under your name in order to use an App or particular content or services available within an App. If so required, you must always provide accurate and up to date information. Please note that the email address you provide when you create your account will be used by us to contact you as required under these Terms. You must let us know as soon as possible if any such information becomes out of date.</p>
  <p class=""  >You must not disclose any user name, password or other piece of information used as part of our security procedures. Such information is to be treated as confidential. If you suspect that anyone other than you knows your user name or password, you must promptly notify us at <a href="mailto:privacy@avang.live">privacy@avang.live</a></p>
  <p class=""  >You are responsible for any use of an App which occurs under your account, whether by you or another person.</p>
  <p class=""  ><strong>3. YOUR PERMITTED USE OF THE WEBSITE AND APPS</strong></p>
  <p class=""  >In return for you agreeing to comply with these Terms, and the payment of any applicable fees you may: (a) use the Website; (b) download, use and display a copy of an App on compatible devices; and (c) access and use any content or material that is made available to you by us through an App or the Website ("Content”) within an App, in all cases, for your personal, non-commercial purposes only.</p>
  <p class=""  >Further, you agree that you will:</p>
  <p class=""  >1. not sell, rent, lease, sublicense, loan, provide, or otherwise make available, an App or any Content in any form to any person without our prior written consent;&nbsp;</p>
  <p class=""  >2. not copy or record an App or any Content, except as part of the normal use of an App or where it is necessary for the purpose of back-up or operational security of the App, or circumvent any technology used by us to protect the App or any Content;&nbsp;</p>
  <p class=""  >3. not edit, translate, adapt, merge or make alterations to, or modifications of, the whole or any part of the Website or an App or Content (other than as permitted as part of the App services), or permit the Website or an App or any part of it to be combined with, or become incorporated in, any other programs;&nbsp;</p>
  <p class=""  >4. not disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Website, an App or any Content or attempt to do any such things except to the extent that such actions are permitted by applicable law;</p>
  <p class=""  >5. comply with all technology control or export laws and regulations that apply to the technology used or supported by an App; and&nbsp;</p>
  <p class=""  >6. not use the Website, an App or any Content in a manner or for any purpose that is not expressly permitted under these Terms or applicable law or which otherwise infringes the intellectual property rights in the Website, an App or any Content.&nbsp;</p>
  <p class=""  >You also must not:</p>
  <p class=""  >1. use the Apps or the Website in any unlawful manner (including infringement of intellectual property rights), for any unlawful purpose, or in any manner inconsistent with these Terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the Apps or any operating system;&nbsp;</p>
  <p class=""  >2. copy, "rip", transfer, display, communicate to the public or perform the musical Content or any part of it;&nbsp;</p>
  <p class=""  >3. infringe our intellectual property rights or those of any third party in relation to your use of the Apps or the Website, including by the submission of any material (to the extent that such use is not licensed by these Terms);&nbsp;</p>
  <p class=""  >4. transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Apps or the Website;&nbsp;</p>
  <p class=""  >5. use the Apps or the Website in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and&nbsp;</p>
  <p class=""  >6. collect or harvest any information or data from the Apps, the Website or our systems (by automated means or otherwise) or attempt to decipher any transmissions to or from the servers running them.&nbsp;</p>
  <p class=""  >You acknowledge and agree that a breach of any of these terms may result in immediate termination or suspension of your account.</p>
  <p class=""  ><strong>4. PARTICULAR TERMS THAT APPLY TO THE APPS AND SUBSCRIPTION ACCOUNTS</strong></p>
  <p class=""  ><strong>Avang Music App</strong>: You must not access or use any Content, including any copies of cached Content, outside of this App.</p>
  <p class=""  ><strong>Subscription Account</strong>: From time to time, you may be given the option to purchase a subscription account which will allow you to access and use additional Content within an App. You can learn more about subscription accounts, including the price and period of the subscription and the additional Content you will have access to, by visiting run.avang.live; however, not all content or services may be available to you and we will notify you either at the time of sign up or from time to time to explain what is available to you. Your payment to us for a subscription account will automatically renew at the end of the relevant subscription period, unless you cancel your subscription account by either contacting us at the email address set out in section 10 "Contact" of these Terms or following the prompts in your account settings at least 48 hours before the end of the then-current subscription period. Subject to your rights in section 5 "Right to Cancel" and our liability to you at law, fees paid for a subscription account are non-refundable.</p>
  <p class=""  ><strong>Changes to Subscription Account Fee</strong>: We reserve the right to change the fee and payment plans from time to time. The process set out in Section 1 under the heading "Changes to Terms", including notice in advance and cancellation by you if you do not agree to the change, will similarly apply here. Unless we notify you otherwise, changes for paid subscriptions will take effect at the start of the next subscription period and your continued use of the App or related services after the effective date of the price change will be deemed acceptance. If you do not agree with the price changes you will have the right to unsubscribe by notifying us in writing prior to the price change taking effect.</p>
  <p class=""  ><strong>Immediate performance</strong>. Please note that by placing an order for (i.e. requesting to download) a copy of an App or a subscription account for use within an App, you are agreeing to the immediate delivery of that copy of the App or the immediate use of that subscription (including delivery of all content and supply of all services permitted by such subscription). Please note that this will result in you losing your right to cancel your order - see section 5 "Right to cancel" for further details.</p>
  <p class=""  ><strong>5. RIGHT TO CANCEL</strong></p>
  <p class=""  >This section 5 only applies to a person who resides in the European Economic Area (being a member state of the European Union, plus Iceland, Norway and Lichtenstein) and is a "consumer" of an App (i.e. uses that App for purposes wholly or mainly outside of his/ her trade, business, craft or profession).</p>
  <p class=""  ><strong>No right of cancellation: </strong>You cannot cancel your order:</p>
  <p class=""  >1. for a copy of an App, once you have started to download it; or&nbsp;</p>
  <p class=""  >2. for a subscription account for use within an App, once you have used Content under your subscription account.&nbsp;</p>
  <p class=""  ><strong>Right to cancel order for subscription account: </strong>Subject to the foregoing, if you want to cancel your purchase of a copy of an App or a subscription account for use within an App, and have the right to do so, you must let us know within 14 days of the date your purchase was accepted by us or a third party selling on our behalf. You may use the model cancellation form set out at the end of these Terms to inform us of your decision to cancel but you do not have to. Alternatively, you could email us at <a href="mailto:yourfriends@avang.live">yourfriends@avang.live</a>. As soon as is practicable, and in any event within 14 days of being informed about your decision to cancel, we will issue you with a full refund of all money paid for the cancelled order.</p>
  <p class=""  ><strong>Third party terms:</strong> If you have ordered an App or a subscription account through a third party, your order is also subject to the terms of your agreement with that third party (in addition to these Terms). To cancel your order, you must cancel directly with that third party.</p>
  <p class=""  ><strong>Not affect other rights:</strong> This section only relates to your rights to cancel an order without reason (for example, if you change your mind). It does not affect your statutory consumer rights for any defective App, Content or service.</p>
  <p class=""  ><strong>6. FREE TRIAL</strong></p>
  <p class=""  >From time to time, we may offer trials of paid subscription accounts for a specified period without payment or at a reduced rate. Some trials may require your payment details to commence the trial. We reserve the right, in our absolute discretion, to determine your eligibility for a trial, and, subject to applicable laws, to withdraw or to modify a trial at any time without prior notice and with no liability. At the end of a trial we may automatically start to charge you for the applicable paid subscription immediately following the end of the trial. By providing your payment details, you agree to this charge using said payment details. If you do not want to be charged you must cancel the subscription or terminate your account before the end of the trial. Subject to section 5 of these Terms, paid subscriptions cannot be terminated before the expiry of the relevant period for which you have subscribed for. We shall not issue any refunds for any fees which you have already paid.</p>
  <p class=""  ><strong>7. INTELLECTUAL PROPERTY RIGHTS</strong></p>
  <p class=""  >All intellectual property rights in the Website, the Apps and the Content anywhere in the world belong to us or our licensors. The rights in the Website, the Apps and the Content are licensed (not sold or gifted) to you, and you have no rights in, or to, the Website, the Apps or the Content other than the right to use each of them in accordance with these Terms.</p>
  <p class=""  >To the extent that any such rights subsist, you retain ownership of all intellectual property rights in interactive music which you have created using the Avang Music App. You hereby grant us a licence to use any such music created by you for the purpose of operating the Avang Music App and, unless agreed with you otherwise, we will not use or permit others to use this music for any other purpose. You agree not to use the Avang Music App to copy, sample, exploit or otherwise infringe music owned by a third party.</p>
  <p class=""  ><strong>8. LIMITATION OF LIABILITY</strong></p>
  <p class=""  >What we are responsible for: If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these Terms, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these Terms, both we and you knew it might happen.</p>
  <p class=""  >We will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
  <p class=""  >TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND UNLESS EXPRESSLY STATED OTHERWISE IN THESE TERMS, WE MAKE NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES (EXPRESSED OR IMPLIED) OR CONDITIONS OF ANY KIND.</p>
  <p class=""  ><strong>Aggregate liability and exclusions of liability</strong>: To the fullest extent permitted by law, in no event will Avang, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for: any indirect, special, incidental, punitive, exemplary, or consequential damages; loss of use, data, business, or profits (whether direct or indirect), in all cases arising out of the use or inability to use the Avang services (including the Website and Apps), third party applications, or third party application content (including the Content), regardless of legal theory. Avang’s total aggregate liability for all claims relating to the use of any Avang services (including the Website and Apps), third party applications, or third party application content (including the Content), shall be limited to the amounts paid by you to Avang in the 12 month period immediately preceding the event giving rise to the relevant claim, to the extent permissible by applicable law.</p>
  <p class=""  ><strong>No liability for business loss:</strong> The Apps and the Website are provided for personal use only. If you use an App or the Website for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. We are not liable to you for any payment, licence, royalty or other entitlement which you may claim in respect of any musical Content.</p>
  <p class=""  ><strong>No liability for events outside our control:</strong> If our provision of the Apps or the Website, including any update, is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any Apps or any products or services connected to via the Apps or Website you have paid for but not received.</p>
  <p class=""  ><strong>Availability of Website, App and Content: </strong>Whilst Avang shall provide all services with reasonable skill and care, we do not warrant that the Website, Apps or Content will always be available, uninterrupted or error-free, but only to the extent permitted by law.</p>
  <p class=""  ><strong>What we will always be responsible for:</strong> We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</p>
  <p class=""  ><strong>Suitability of an App and the Website: </strong>The Website and the Apps have not been developed to meet your individual requirements. Please check that the facilities and functions of the Website and the Apps meet your requirements.</p>
  <p class=""  ><strong>Please back-up content and data used with the App.</strong> We recommend that you back up any content and data which you use in connection with the Website or the Apps, to protect yourself in case of problems with the Website or the Apps.</p>
  <p class=""  ><strong>9. TERMINATION</strong></p>
  <p class=""  >We may terminate these Terms and suspend your access to the Website and/or each or all of the Apps immediately by written notice to you, including if you break these Terms in a serious way (as determined by us in our discretion) or we suspect that may be the case, or we consider it necessary to protect the integrity or security of the systems used by us at any time. If what you have done can be put right, we will give you a reasonable opportunity to do so.</p>
  <p class=""  >On termination for any reason:</p>
  <p class=""  >1. all rights granted to you under these Terms shall cease and you must immediately cease use of the Website and/ or the Apps and/or the Content;&nbsp;</p>
  <p class=""  >2. you must immediately delete your copy of the App(s) and all Content from all devices, or otherwise in your possession, custody or control (as applicable); and&nbsp;</p>
  <p class=""  >3. we may deactivate your accounts.&nbsp;</p>
  <p class=""  >You may terminate your account at any time either via the App or by writing to us at the email address provided in section 10 “Contact”. Subject to section 5, any amounts you have already paid to us for paid subscriptions shall be non-refundable, and if you terminate part way through a subscription period you will be liable to pay the full amount for that relevant subscription period.</p>
  <p class=""  ><strong>10. CONTACT</strong></p>
  <p class=""  >If you wish to contact us, or if any condition in these Terms requires you to give us notice in writing, you can send this to us by e-mail at <a href="mailto:yourfriends@avang.live">yourfriends@avang.live</a>. If we have to contact you or give you notice in writing, we will do so using the email address that you have provided to us.</p>
  <p class=""  ><strong>11. GENERAL TERMS</strong></p>
  <p class=""  ><strong>Transferring rights:</strong> We may transfer our rights and obligations under these Terms to a third party, but this will not affect your rights under these Terms. You may only transfer your rights or obligations under these Terms to another person if we agree in writing. If you sell any device on which an App is installed, you must remove an App from it.</p>
  <p class=""  ><strong>Failure or delay in enforcing rights:</strong> If we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you, nor will it prevent or restrict the further exercise of that or any other rights. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</p>
  <p class=""  ><strong>No rights for third parties: </strong>You acknowledge and agree that the owners of the Content and certain distributors (such as app store providers) are intended beneficiaries of the Terms and have the right to enforce the Terms directly against you; however, the rights to terminate, rescind, or vary these Terms are not subject to the consent of any other person. Subject to the foregoing, the Terms are not intended to grant rights to anyone except you andavang</p>
  <p class=""  ><strong>Severability of this contract:</strong> Each of the conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect and will be construed as far as possible to give effect to the parties' intentions as originally expressed in these Terms.</p>
  <p class=""  ><strong>Applicable law and place of legal proceedings: </strong>These Terms are governed by and construed in accordance with the laws of the State of New York without reference to its conflicts of law principles and all claims relating to or arising out of these Terms, regardless of legal theory, shall be subject to the exclusive jurisdiction of the courts of the State of New York.</p>
  <p class=""  >&nbsp;</p>
  <p class=""  ><strong>Model Cancellation Form</strong></p>
  <p class=""  ><em>(Complete and return this form only if you wish to withdraw from the contract)</em></p>
  <p class=""  >To <strong>Avang Music Inc.</strong> 141 Flushing Ave Building 77, unit 1212, Brooklyn, NY 11205, United States:</p>
  <p class=""  >I hereby give notice that I cancel my contract of sale of the following goods [*]/for the supply of the following service [*],</p>
  <p class=""  >Ordered on [*]/received on [*],</p>
  <p class=""  >Name of consumer(s),</p>
  <p class=""  >Address of consumer(s),</p>
  <p class=""  >Signature of consumer(s) (only if this form is notified on paper),</p>
  <p class=""  >Date</p>
  <p class=""  >[*] Delete as appropriate</p>
</div>
			</div>
	);
};

export default TermsOfService;

import * as React from "react";
import "./Events.scss";
import image from "Assets/Images/Event1.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export interface EventsProps {
  data: [];
}

const Events: React.SFC<EventsProps> = (props: EventsProps) => {
  const history = useHistory();

  return (
    <div className="events">
      <div className="rowsTitle">
          <p>Events</p>
          <span
            onClick={(e) => {
              window.scroll({ top: 0 });
              history.push("events");
            }}
          >
            See all
          </span>
        </div>
      <div className="event-items">
        {props.data.map((c,index) => (
          <EventItem key={index} {...c} />
        ))}
      </div>
    </div>
  );
};

export default Events;

export function EventItem(props: any) {
  return (
    <div className="event-item">
      <div className="image">
        <img onError={(e:any)=>{e.target.src="/api/file/images/event.jpg"}} src={"/api/file/images/event/" + props.eventID + ".jpg"} alt="" />
      </div>
      <div className="info">
        <div className="col">
          <h4>{props.eventName}</h4>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import "./UpdateInfo.scss";
import Api from "Api";
import { useHistory } from "react-router-dom";
import AuthService from "AuthService";
export default function UpdateInfo() {
  const [user, setuser] = useState({});
  const [info, setInfo] = useState({});
  const history = useHistory();

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem("temp")));
  }, []);
  useEffect(() => {
    setuser({ userID: info.userID, email: info.email });
  }, [info]);
  const ref = useRef();
  const [file, setfile] = useState();
  return (
    <div id="update-info">
      <input
        ref={ref}
        onChange={(e) => {
          var f = new FormData();
          f.append("file", e.target.files[0]);
          let options = {
            body: f,
            method: "post",
            headers: {
              Authorization: "Bearer " + info.data,
            },
          };
          fetch("/api/users/setAvatar", options)
            .then((c) => c.ok)
            .then((c) => {
              if (c) {
                alert("Cover Have Been Set");
              }
            });
        }}
        type="file"
        style={{ display: "none" }}
      />
      <div className="container">
        <div>
          <div className="left">
            <img
              onClick={(e) => ref.current.click()}
              src={`/api/main/getfile/user/${user.userID}`}
              alt=""
            />
          </div>
          <div className="right">
            <div className="personal">
              <input
                type="text"
                onChange={(e) => setuser({ ...user, name: e.target.value })}
                value={user.name}
                className="field"
                placeholder="Name"
              />

              <input
                type="text"
                value={user.email}
                className="field"
                placeholder="Email"
              />
            </div>
            <div className="password">
              <input
                type="password"
                value={user.password}
                onChange={(e) => setuser({ ...user, password: e.target.value })}
                className="field"
                placeholder="New Password"
              />
              <input
                value={user.confirmPassword}
                onChange={(e) =>
                  setuser({ ...user, confirmPassword: e.target.value })
                }
                type="password"
                className="field"
                placeholder="Confirm New Password"
              />
              <input
                style={{ visibility: "hidden" }}
                type="password"
                className="field"
                placeholder="New Password"
              />
              <div className="save">
                <button onClick={update}>Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function update() {
    if (user.password == user.confirmPassword)
      fetch("/api/users/updateProfile", {
        method: "post",
        body: JSON.stringify({ name: user.name, password: user.password }),

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + info.data,
        },
      })
        .then((c) => c.text())
        .then((c) => {
          if (c) {
            new AuthService().setToken(c);
            localStorage.removeItem("temp");

            history.replace("/");
          }
        });
  }
}

/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import "Assets/pep.scss";
import SelectLanguage from "Components/ComboBox/Language/SelectLanguage";
import { DateTimePicker } from "Components/DatePicker/DatePicker";
import Api from "Api";
import SelectArtist from "Components/ComboBox/Artist/SelectArtist";
import { Divider } from "@material-ui/core";
import SelectGenre from "Components/ComboBox/genre/SelectGenre";
import SelectType from "Components/ComboBox/Type/SelectType";
import SelectKeyword from "Components/ComboBox/Keyword/SelectKeyword";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import Notification from "Components/Notification/Notification";
export interface MusicProps {}

const EditMusic: React.SFC<MusicProps> = () => {
  let history = useHistory();
  const musicID  = useParams();
  const [notify, setNotify] = React.useState(false);

  const [music, setMusic] = React.useState<any>({
    languageID: null,
    releaseDate: new Date(),
  });
  React.useEffect(() => {
    if (musicID != undefined) {
      var s = JSON.parse(localStorage.getItem("a")!);
      new Api()
        .get("/api/musics/" + musicID)
        .then((c) => c.json())
        .then((c) => {
          if (c.musicArtists.some((w: any) => w.artistID == s.artistID)) {
            c.releaseDate = new Date(c.releaseDate);
            setMusic(c);
          } else {
            history.goBack();
          }
        });
    }
  }, [history, musicID]);

 // console.log(music)

  return (
    <div id="music">
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBack />
      </span>
      <Grid container direction="column" md={6}>
        <br />
        <TextField
          autoComplete="Off"
          label="title"
          name="title"
          value={music.title}
          onChange={(e) => setMusic({ ...music, title: e.target.value })}
        />
        <br />
        <TextField
          multiline
          label="lyrics"
          name="lyrics"
          value={music.lyrics}
          variant="outlined"
          onChange={(e) => setMusic({ ...music, lyrics: e.target.value })}
        />
        <br />
        <SelectLanguage
          value={music.languageID}
          onChange={(e: any) =>
            setMusic({ ...music, languageID: e.target.value })
          }
        />

        <br />
        <SelectGenre
          value={music.genreMusics}
          onChange={(c: any) => {
            let s: any = [];
            c.map((w: any) => s.push({ genreID: w.genreID, musicID: 0 }));
            setMusic({ ...music, genreMusics: s });
          }}
        />
        <br />
        <SelectType
          value={music.musicTypes}
          onChange={(c: any) => {
            let s: any = [];
            c.map((w: any) => s.push({ typeID: w.typeID, musicID: 0 }));
            setMusic({ ...music, musicTypes: s });
          }}
        />
        <br />
        <Divider />
        <DateTimePicker
          value={music.releaseDate}
          name="releaseDate"
          lable="Release Date"
          onChange={(e: any) =>
            setMusic({ ...music, releaseDate: new Date(e.target.value) })
          }
          KeyboardButtonProps="Select Date"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              name="isCommingSoon"
              onChange={(e) =>
                setMusic({ ...music, isCommingSoon: e.target.checked })
              }
              checked={music.isCommingSoon ?? false}
            />
          }
          label="Is Comming Soon??"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              name="isExlusive"
              onChange={(e) =>
                setMusic({ ...music, isExlusive: e.target.checked })
              }
              checked={music.isExlusive ?? false}
            />
          }
          label="Is Exclusive??"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              name="hasVideo"
              onChange={(e: any) =>
                setMusic({ ...music, hasVideo: e.target.checked })
              }
              checked={music.hasVideo ?? false}
            />
          }
          label="Is it music Video??"
        />
        <br />
        <Notification
          text="Error Occured"
          show={notify}
          closeNotification={() => {
            setNotify(false);
          }}
        />
        <SelectKeyword
          value={music.keywords}
          onChange={(e: any) => {
            let w: any = [];
            e.map((c: any) => {
              w.push({ keywordText: c });
            });

            setMusic({ ...music, keywords: w });
          }}
        />
        <br />
        <br />
        <Button
          style={{ maxWidth: 150 }}
          type="button"
          variant="contained"
          color="primary"
          onClick={editMusic}
        >
          Edit
        </Button>
      </Grid>
    </div>
  );
  function editMusic() {
    new Api()
      .put("/api/Musics/" + musicID, {
        body: JSON.stringify({
          ...music,
          releaseDate: music.releaseDate.toJSON(),
        }),
      })
      .then((c) => c.ok)
      .then((c) => {
        if (c) {
          history.goBack();
        } else {
          showErrorNotification();
        }
      });
  }
  function showErrorNotification() {
    setNotify(true);
    setTimeout(function () {
      setNotify(false);
    }, 1500);
  }
};

export default EditMusic;

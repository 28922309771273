import * as React from "react";
import logo from "Assets/Images/LogoWithName.png";
import "./Navbar.scss";
import { OpenMenuContainer } from "Context/Contexts";
import { useHistory, Link } from "react-router-dom";
import Api from "Api";
import AuthService from "AuthService";
import MusicRowItem from "Components/MusicRow/MusicRowItem";
import PodcastRowItem from "Components/PodcastRow/PodcastRowItem";
import tvicon from "Assets/Images/tv.png";
import Loading from "Components/Loading/Loading";


export interface NavbarProps {
  user:any
}
const Navbar: React.FC<NavbarProps> = ({user}) => {
  const openMenu = OpenMenuContainer.useContainer();
  const history = useHistory();

  const auth = new AuthService();
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [noResult, setNoResult] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<{
    musics: [];
    videos: [];
    podcasts: [];
    artists: [];
  }>({
    musics: [],
    videos: [],
    artists: [],
    podcasts: [],
  });
  const [text, setText] = React.useState<string>("");

  
  const [storage,setStorage]=React.useState<any>(false)



  const ref = React.useRef<any>(null);
  React.useEffect(() => {
    window.document.addEventListener("click", (e: any) => {
      if (!ref.current?.contains(e.target)) {
        setShowSearch(false);
        clearSearchHistory();
        //setText("");
      }
    });
  }, []);
  React.useEffect(() => {
    if (text.length < 3) {
      setShowSearch(false);
      clearSearchHistory();
    }
  }, [text]);

  function Load() {
    if (text.length > 2) {
      clearSearchHistory();
      new Api()
        .get("/api/main/search/" + text)
        .then((c) => c.json())
        .then((c) => {
         if(c.musics.length == 0 && c.artists.length == 0 && c.videos.length == 0 && c.podcasts.length == 0) {
          setNoResult(true);
           return;
         }
         setNoResult(false);
          
          setSearch(c);
          setShowSearch(true);

        });
    } else {
      setShowSearch(false);

    }
  }
 // console.log(search);
  function clearSearchHistory() {
    setSearch({
      musics: [],
      videos: [],
      artists: [],
      podcasts: [],
    });
  }

  function Loadinga() {
    setShowSearch(true);
        }

  return (
    <>
    <div>
      <nav className="s-nav">
        <div className="s-nav-div">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          <div className="search">
            <i className="fas fa-search s-bars search-icon-desktop"></i>
            <i 
              onClick={(e) =>  {
                (document.getElementById("mobile-search") as HTMLFormElement).style.display = "flex";
              } }

            className="fas fa-search s-bars search-icon-mobile"></i>
            
            <input
              value={text}
              onKeyUp={(e) => {
                if(e.key == 'Enter') {
                  Load();
                  Loadinga();
                }
              }}
              onClick={(e) =>  Loadinga() }
              onChange={(e) =>{setText(e.target.value);}}
              type="search"
              name="search"
              autoComplete="off"
              id="search"
              placeholder="Search"
            />
            {text.length > 0 ? (
              <span>
              <i
                onClick={(e) => setText("")}
                className="fas fa-window-close"
              ></i>
              <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
              </span>

            ) : (
              ""
            )}
            <div className="mobile-search" id="mobile-search">
            <input
              value={text}
              onKeyUp={(e) => {
                if(e.key == 'Enter') {
                  Load();
                  Loadinga();
                }
              }}
              onClick={(e) =>  Loadinga() }
              onChange={(e) =>{setText(e.target.value);}}
              type="search"
              name="search"
              autoComplete="off"
              id="search"
              placeholder="Search"
            />
              <span>
              <i
                onClick={(e) => {
                  setText("");
                  (document.getElementById("mobile-search") as HTMLFormElement).style.display = "none";
  
              }}
                className="fas fa-window-close"
              ></i>
              <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
              </span>
            </div>
          </div>
          <div className="icons-div">
            {auth.loggedIn() ? (
              <React.Fragment>
                <div
                  onClick={(e) => history.push("/profile")}
                  className="profile"
                >
                  <img
                    src={
                      "/api/file/images/user/" + new Api().getUserId() + ".jpg"
                    }
                    alt=""
                  />
                  <p>Hello {user.name}!</p>
                </div>
                <div className="operation">
                  <ul>
                    <li>
                      <span>
                        <Link to="/profile"> DashBoard</Link>
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <i className="fas fa-music"></i>
                        <Link to="/profile"> My Saved Musics</Link>
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <i className="fas fa-video"></i>
                        <Link to="/profile"> My Music Videos</Link>
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <i className="fas fa-list"></i>
                        <Link to="/myplaylists">My PlayLists</Link>
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                    <hr />

                    <li>
                      <span>Account Type</span>
                      <span className="limited">Unlimited</span>
                    </li>
                    <hr />
                    <li>
                      <span>
                        <i className="fas fa-star"></i>Upgrade Your Account
                      </span>
                      <i className="fas fa-question-circle"></i>
                    </li>
                    {(user.artists ?? []).length > 0 && (
                      <React.Fragment>
                        <hr />
                        <li>
                          <span>
                            <a href="/artistPanel">Artist Panel</a>
                          </span>
                          <i className="fas fa-chevron-right"></i>
                        </li>
                      </React.Fragment>
                    )}
                    {(user.djs ?? []).length > 0 && (
                      <React.Fragment>
                        <hr />
                        <li>
                          <span>
                            <a href="/artistPanel">Dj Panel</a>
                          </span>
                          <i className="fas fa-chevron-right"></i>
                        </li>
                      </React.Fragment>
                    )}
                    {(user.artists ?? []).length == 0 && (
                      <React.Fragment>
                        <hr />
                        <li>
                          <span>
                            <a href={"/requestartist/" + user.userID}>Request Artist Panel</a>
                          </span>
                          <i className="fas fa-chevron-right"></i>
                        </li>
                      </React.Fragment>
                    )}

                    <hr />
                    <li>
                      <span>
                        <Link to="/setting">Account Settings</Link>
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                    <hr />
                    <li
                      onClick={() => {
                        new AuthService().logout();
                        window.location.reload();
                      }}
                    >
                      <span>
                        <i className="fas fa-sign-out-alt"></i> Logout
                      </span>
                      <i className="fas fa-chevron-right"></i>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              <Link to="/register" className="s-login">
                <i className="fas fa-user"></i>
                <span>Signin Or Signup</span>
              </Link>

              // <button
              //   onClick={(e) => history.push("/register")}
              //   className="s-login"
              // >
              //   Signin / Signup
              // </button>
            )}
            <Link to="/live" className="s-live">
              <img className="live-tv" src={tvicon} alt=""></img>
            </Link>

            <i
              onClick={() => {
                openMenu.setShow(true);
              }}
              className="fas fa-bars s-bars fa-2x "
            ></i>
          </div>
        </div>
      </nav>

      {showSearch ? <Search /> : ""}
    </div>
    {/* {children} */}
    </>
  );
  function Search() {
    return (
      <div className="search-box-div">
        <div ref={ref} className={"search-box"}>
          {noResult == true && 
          <p> No Result</p>
  }
        <div className={"search-box-box"}>
          {search.artists.length > 0 ? (
            <React.Fragment>
              <h3>Artists</h3>
              <hr />
            </React.Fragment>
          ) : (
            null
          )}
          {search.musics.length == 0 &&
          // search.videos.length == 0 &&
          search.podcasts.length == 0 &&
          search.artists.length == 0 ? (
            <React.Fragment>
              <Loading />
              <h3 className="no-result">No Reault</h3>
            </React.Fragment>
          ) : (
            null
          )}
          <div className="artists">
            {search.artists.map((c: any) => (
              <div
                onClick={(e) => {
                  history.push("/artist/" + c.artistID);
                  setShowSearch(false);
                  setText("");
                }}
                className="artist"
              >
                <img
                  onError={(e: any) => {
                    e.target.src = "/api/file/images/placeholder.jpg";
                  }}
                  src={`/api/file/images/artist/${c.artistID}.jpg`}
                  alt=""
                />
                <span>{c.name}</span>
              </div>
            ))}
          </div>
          {search.musics.length > 0 ? (
            <React.Fragment>
              <h3>Musics</h3>
              <hr />
            </React.Fragment>
          ) : (
            null
          )}
          <div className="music-row-items ">
            <div className="musics">
              {search.musics.map((c: any, index: number) => (
                <MusicRowItem data={search.musics} index={index} />
              ))}
            </div>
          </div>
          {search.podcasts.length > 0 ? (
            <React.Fragment>
              <h3>Podcasts</h3>
              <hr />
            </React.Fragment>
          ) : (
            null
          )}
          <div className="podcast-row-items ">
            {search.podcasts.map((c: any, index: number) => (
              <PodcastRowItem data={search.podcasts} index={index} />
            ))}
          </div>
        </div>
      </div>
      </div>
    );
  }
};

export default Navbar;

import * as React from "react";
import { AddAlert } from "@material-ui/icons";
// import Snackbar from "Components/Snackbar/Snackbar";

export interface NotificationProps {
  text: string;
  show: boolean;
  closeNotification: () => void;
}

const Notification: React.FC<NotificationProps> = (
  props: NotificationProps
) => {
  return (
    null
    // <Snackbar
    //   place="br"
    //   color="info"
    //   icon={AddAlert}
    //   message={props.text}
    //   open={props.show}
    //   closeNotification={props.closeNotification}
    //   close
    // />
  );
};

export default Notification;

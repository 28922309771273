import React, { useState, useEffect } from "react";
import "./Album.scss";
import Api from "Api";
import { useParams } from "react-router-dom";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { PlayerContainer } from "Context/Contexts";
import Loading from "Components/Loading/Loading";
export default function Album() {
  const { albumID } = useParams();
  const [album, setalbum] = useState({ musics: [] });
  const [loading, setloading] = useState(true);

  const player = PlayerContainer.useContainer();
  useEffect(() => {
    new Api()
      .post(`/api/Albums/GetMusics/${albumID}`)
      .then((c) => c.json())
      .then((c) => {
        setloading(false);
        setalbum(c);
      });
  }, []);
 // console.log(album);
  return loading ? (
    <Loading />
  ) : (
    <div className="container">
      <div id="album">
        <div className="name">
          <h1>{album.album?.name}</h1>
          <button
            onClick={(e) => {
              player.setMusics([
                ...album.musics.sort(() => 0.5 - Math.random()),
              ]);
              player.setIndex(0);
            }}
          >
            <i className="fas fa-random"></i> Shuffle
          </button>
        </div>
        <div className="info">
          <span>
            <i className="fas fa-microphone"></i>{" "}
            {album.album?.artists ?? [].map((c) => c + " , ")}
          </span>

          <span className="count">
            <i className="fas fa-music"></i>
            {album.musics.length} tracks
          </span>
        </div>
        <hr />
        <h3>Album Tracks</h3>
        <div className="music-grid">
          <div className="tracks">
            {album.musics.map((c, index) => (
              <MusicGridItem width={25} data={album.musics} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

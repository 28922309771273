import * as React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import Queue from "Components/Queue/Queue";

const VideoPlayer: React.FC<ReactPlayerProps> = (props: ReactPlayerProps) => {
  const ref = React.useRef<HTMLVideoElement>(null);
  return (
    <div id="video">
      {/* <video
        {...props}
        muted
        controls
        width={"96%"}
        controlsList="nodownload"
        autoPlay
        ref={ref}
      ></video> */}
      <ReactPlayer
        playing
        onEnded={()=>props.playNextVideo()}
        controls
        
        style={{ marginLeft: "auto", marginRight: "auto" }}
        width={"96%"}
        config={{
          file: {
            attributes: {
              // style:{display:'sticky'},
              controlsList: "nodownload",
              onContextMenu: (e: any) => e.preventDefault(),
            },
          },
        }}
        {...props}
      />
    </div>
  );
};

export default VideoPlayer;

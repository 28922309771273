import React, { useEffect, useState } from "react";
import "./News.scss";
import "Assets/Yekan-Font/stylesheet.css";
import Loading from "Components/Loading/Loading";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

export default function News() {
  const [posts, setPosts] = useState([]);
  const [loading, setloading] = React.useState(true);

  useEffect(() => {
    async function loadPosts() {
      const response = await fetch(
        "https://news.avang.live/wp-json/wp/v2/posts?per_page=100"
      );
      if (!response.ok) {
        // oups! something went wrong
        return null;
      }

      const posts = await response.json();
      setPosts(posts);
      setloading(false);

    }

    loadPosts();

  }, []);
//  console.log(posts);
//  console.log(posts.id);

  return loading ? (
    <Loading />
  ) : (
    <div id="news" className="container news">
      <div className="title">
        <h1>Avang News</h1>
      </div>
      <div className="news-items">
        {posts.map((post, index) => (
          <div key={index} className="news-item">
            <div>
            <Link to={"/newspost/" + post.id}>
              <img src={`${post.featured_media_src_url}`} alt="" width="100%" />
              </Link>
              <div className="post-info">
                  <Link to={"/newspost/" + post.id}>
                 <span className="post-title"
              >{post.title.rendered}</span>
                  </Link>
              <p className="post-excerpt"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Api from "Api";
import "./Events.scss";
import { EventItem } from "Components/Events/Events";
export default function Events() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    new Api()
      .get("/api/events")
      .then((c) => c.json())
      .then((c) => setEvents(c));
  }, []);
  return (
    <div id="events">
      <div className="container">
        <h1>Event</h1>
        <div className="events">
          <div className="event-items">
            {events.map((c,index) => (
              <EventItem key={index} {...c} />
            ))}
            {events.map((c,index) => (
              <EventItem key={index} {...c} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

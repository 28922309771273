import React, { useEffect, useState, useRef } from "react";
import "./Profile.scss";
import Api from "Api";
import { Link, useHistory } from "react-router-dom";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { SingleMusicItem } from "Components/SingleMusic/SingleMusic";
import MusicRowItem from "Components/MusicRow/MusicRowItem";
import { HorizontalVideoItem } from "Components/HorizontalVideo/HorizontalVideo";
import { MusicListItem } from "Components/MusicList/MusicList";
export default function Profile(props) {
  const [user, setuser] = useState({});
  const [saved, setSaved] = useState([]);
  const [recent, setrecent] = useState([]);
  const playlist = new Api().getSettings().musics ?? [];
  const musics = saved.filter((c) => (c?.hasVideo ?? false) == false);
  const ref = useRef();
  useEffect(() => {
    new Api()
      .get("/api/users/" + new Api().getUserId())
      .then((c) => c.json())
      .then((c) => setuser(c));
  }, []);
  useEffect(() => {
    new Api()
      .get("/api/savedMusics/musics")
      .then((c) => c.json())
      .then((c) => setSaved(c.filter((c) => c != null)));
  }, []);
  useEffect(() => {
    new Api()
      .get("/api/PlayLists/recent")
      .then((c) => c.json())
      .then((c) => {
        setrecent(c.slice(0, 25));
       // setrecent(c);

      })
   }, []);
console.log("recent :", recent);

  return (
    <div id="profile">
      <Left />
      <Center />
      <Right />
    </div>
  );
  function Musics() {
    return saved.filter((c) => (c?.hasVideo ?? false) == false).length > 0 ? (
      <div className="music-row-items">
        <div className="title">
          <h2>My Saved Musics </h2>
          <Link to="/myMusics">See All</Link>
        </div>
        <div className="musics">
          {musics.map((c, index) => (
            <MusicRowItem data={musics} index={index} />
          ))}
        </div>
      </div>
    ) : (
      null
    );
  }
  function Videos() {
    return saved.filter((c) => (c?.hasVideo ?? false) == true).length > 0 ? (
      <div id="horizontal-video">
        <div className="title">
          <h2>My Videos</h2>
          <a href="#">See All</a>
        </div>
        {
          <div className="row profile-video-row">
            {saved
              .filter((c) => (c?.hasVideo ?? false) == true)
              .map((c) => (
                <HorizontalVideoItem {...c} />
              ))}
          </div>
        }
      </div>
    ) : (
      null
    );
  }
  function Center() {
    return (
      <div className="center">
        <div>
          <Musics />
          <Videos />
        </div>
      </div>
    );
  }
  function Right() {
    return (
      <div className="right">
        <h2>My recently played list</h2>
        <div className="music-grid">
          <div className="tracks">
            {recent.map((c, index) => (
              <MusicGridItem
                key={"m" + index}
                width={100}
                index={index}
                data={recent}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  function Left() {
    const history = useHistory();

    return (
      <div className="left">
        <input
          type="file"
          style={{ display: "none" }}
          ref={ref}
          onChange={(e) => {
            const dt = { file: e.target.files[0] };
            new Api()
              .postWithFile("/api/users/setAvatar", dt)
              .then((c) => c.ok)
              .then((c) => {
                if (c) {
                  alert("Cover Have Been Set");
                }
              });
          }}
        />
        <div className="left-top" style={{position : "relative"}}>
          <i
            onClick={(e) => history.push("/setting")}
            className="fas fa-cog"
          ></i>
            <i
                onClick={() => {
                  ref.current.click();
                }}
                className="fas fa-photo avatar-upload-icon"></i>
          <img
            onError={(e) => {
              e.target.src = "/api/file/images/user.jpg";
            }}
            onClick={(e) => {
              ref.current.click();
            }}
            src={`/api/main/getfile/user/${user.userID}`}
            alt=""
          />
          {/* <a href="">Change profile photo</a> */}
          <h3>{user.name}</h3>
          <p>{user.email}</p>
        </div>
        <div className="left-bottom">
          <ul>
            <li>
              <Link to="/mymusics">
                <i className="fas fa-music"></i> My Musics
              </Link>
            </li>
            <li>
              <Link to="newmusics">
                <i className="fas fa-video"></i>My Music Videos
              </Link>
            </li>
            {/* <li>
              <Link to="newmusics">
                <i className="fas fa-play"></i> My Albums
              </Link>
            </li> */}
            <li>
              <Link to="/myplaylists">
                <i className="fas fa-list"></i>My Playlist
              </Link>
            </li>

            <li>
              <Link to="/allartists">
                <i className="fas fa-microphone-alt"></i>My Followed Artists
              </Link>
            </li>

            <li>
              <i className="fas fa-edit"></i>
              <Link to="/events">Events</Link>
            </li>
          </ul>
        </div>
        <div className="ad">
          <i className="fas fa-question"></i>
          <div className="main">
            <div className="cover"></div>
            <div className="elements">
              <h3>Your AVANG Account is</h3>
              <button>UnLimited</button>
              <h4>
                <i className="fas fa-upload"></i> You do'nt need UPGRADE
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
